import React from 'react';
import { TabelaPeriodoMatricula } from '../../components/TabelaPeriodoMatricula';
import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';


export default function PeriodoMatriculaPage() {

  return (
    <div className="p-pt-0">
      <MenuMain >
        <TabelaPeriodoMatricula />
      </MenuMain>
      <AppFooter />
    </div>
  );
}