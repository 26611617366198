import React, { useRef, useEffect, useState, useContext, useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import api from '../api/api';
import { DialogInscricoes } from './DialogInscricoes';
import { DialogCsv } from './DialogCsv';
import { FormInscricao } from './FormInscricao';
import {FilterContext} from '../contexts/FilterContext';
import '../layout/sass/_responsiveTable.scss'
import { BrCalendar } from './BrCalendar';

export const TabelaInscricao = () => {

    const toast = useRef(null);
    const [first, setFirst] = useState(0);
    const [inscricaoTotal, setInscricaoTotal] = useState([]);
    const [inscricao, setInscricao] = useState([]);
    const [inscricoes, setInscricoes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [campanha, setCampanha] = useState("");
    const [perfil, setPerfil] = useState("");
    const confirmationToast = useRef(null)
    const [dt, setDt] = useState(null);
    const [sortable, setSortable] = useState('ASC');
    const [sortableCampo, setSortableCampo] = useState('id');
    const [showListaInscricoes, setShowListaInscricoes] = useState(false);
    const [showListaCsv, setShowListaCsv] = useState(false);
    const [isEditInscricao, setIsEditInscricao] = useState(false);
    const [campanhas, setCampanhas] = useState([]);
    const [isEditing, setIsEditing] = useState(false)
    const [selectedCampanha, setSelectedCampanha] = useState(null);
    const filterCampanha = renderFilterCampanha();
    const [totalRecords, setTotalRecords] = useState(0);
    const filterStatus = renderFilterStatus();
    const { filter, setFilter } = useContext(FilterContext);
    const [selectedDataNascimento, setSelectedDataNascimento] = useState(null);
    const [selectedDataInscricao, setSelectedDataInscricao] = useState(null);

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Inscrições</h2>
            </div>
            <div className="p-my-0 p-grid p-dir-rev p-col">
                <div className="p-m-2">
                    <Button
                        label="Adicionar"
                        className="p-mb-2 p-button-primary"
                        icon="pi pi-plus"
                        iconPos="left"
                        type="button"
                        onClick={() => handleAdicionarClick()}

                    />
                </div>
                <div className="p-m-2">
                    <Button
                        label="Limpar Filtros"
                        className="p-mb-2 p-button-primary"
                        iconPos="left"
                        type="button"
                        onClick={() => handleLimparClick()}
                    />
                </div>
            </div>
        </div>

    );

    const handleLimparClick = () => {
        setFilter({
            first: 0,
            rows: 20,
            sortField: null,
            sortOrder: null,
            filters: {
            },
            
        });
        setSelectedStatus(null);
        setSelectedCampanha(null);
        setSelectedDataNascimento(null);
        setSelectedDataInscricao(null);
    }

    const getInscricoes = useCallback(async (event) => {
        setIsLoading(true)
        setInscricao([])

        if (event !== undefined) {
            setTimeout(() => {
                const startIndex = event.first;

                setFirst(startIndex);
            }, 250);
        }

        try {

            if (filter.filters === undefined) {
                if (event === undefined) {
                    const response = await api.get(`inscricoes?sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(inscricao => {
                        setInscricao(inscricoes => [...inscricoes, inscricao])
                    })
                } else {
                    const response = await api.get(`inscricoes?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(inscricao => {
                        setInscricao(inscricoes => [...inscricoes, inscricao])
                    })

                }

                const respElements = await api.get(`inscricoes?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);

            }
            else {
                const pesquisa = {
                    "perfilNome": filter.filters['perfilNome'] !== undefined ? filter.filters['perfilNome'].value : "",
                    "perfilCelular": filter.filters['perfilCelular'] !== undefined ? filter.filters['perfilCelular'].value : "",
                    "sorteado": filter.filters['sorteado'] !== undefined ? filter.filters['sorteado'].value : null,
                    "perfilEmail": filter.filters['perfilEmail'] !== undefined ? filter.filters['perfilEmail'].value : "",
                    "id": filter.filters['id'] !== undefined ? filter.filters['id'].value : null,
                    "campanhaId": filter.filters['campanhaId'] !== undefined ? filter.filters['campanhaId'].value : null,
                    "perfilDataNascimento": filter.filters['perfilDataNascimento'] !== undefined ? filter.filters['perfilDataNascimento'].value : null,
                    "dataInscricao": filter.filters['dataInscricao'] !== undefined ? filter.filters['dataInscricao'].value : null,
                }

                if (event === undefined) {
                    const respElements = await api.post(`inscricoes/searchInscricoes/${sortable}/${sortableCampo}?`, pesquisa);
                    respElements.data.content.forEach(inscricao => {
                        setInscricao(inscricoes => [...inscricoes, inscricao])
                    })
                    setTotalRecords(respElements.data.totalElements);
                } else {
                    const respElements = await api.post(`inscricoes/searchInscricoes/${sortable}/${sortableCampo}?page=${event.page}`, pesquisa);
                    respElements.data.content.forEach(inscricao => {
                        setInscricao(inscricoes => [...inscricoes, inscricao])
                    })
                    setTotalRecords(respElements.data.totalElements);
                }
            }


        }
        catch (error) {

            if (filter !== undefined) {
                if (event === undefined) {
                    const response = await api.get(`inscricoes?sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(inscricao => {
                        setInscricao(inscricoes => [...inscricoes, inscricao])
                    })
                } else {
                    const response = await api.get(`inscricoes?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(inscricao => {
                        setInscricao(inscricoes => [...inscricoes, inscricao])
                    })
                }

                const respElements = await api.get(`inscricoes?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);
            } else {
                toast.current.show({ severity: 'error', summary: 'Erro ao obter inscricoes', detail: error.toString() });
            }

        }

        setIsLoading(false)
    }, [filter,sortable,sortableCampo]);

    useEffect(() => {
        const fetchInscricoes = async () => {
            try {
                await getInscricoes();

            }
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchInscricoes();

    }, [getInscricoes])

    const onSort = (event) => {
        setSortableCampo(event.sortField === "perfilEmail" ? "perfil.email" : 
        event.sortField === "perfilCelular" ? "perfil.celular" : 
        event.sortField === "campanhaId" ? "campanha.id" : 
        event.sortField === "perfilNome" ? "perfil.nome" :
        event.sortField
        );
        if (sortable === 'ASC') {
            setSortable('DESC')
        } else if (sortable === 'DESC') {
            setSortable('ASC')
        }
    }

    const onFilter = (event) => {
        event['first'] = 0;
        // setLazyParams(event);
        setFilter(event);
    }

    function renderFilterCampanha() {
        return (
            <Dropdown
                value={selectedCampanha}
                options={campanhas.map((e) => e)}
                onChange={(e) => onCampanhaSearchChange(e)}
                placeholder="Selecione uma campanha"
                className="p-column-filter"
                showClear={true} />
        );
    }

    const onCampanhaSearchChange = (event) => {
        if (event.target.value !== null) {
            dt.filter(event.target.value, 'campanhaId', 'equals');
            setSelectedCampanha(event.target.value);
        }
        else {
            setSelectedCampanha(null);
            dt.filter(null, 'campanhaId', 'equals');
        }
    }

    const handleAdicionarClick = () => {

        setInscricoes({
            'dataInscricao': '',
            'campanhaId': '',
            'perfilId': '',
            'sorteado': ''
        })
        setIsEditInscricao(true)
        setIsEditing(false)

    }

    async function handleAdicionarInscricaoSubmit(data) {
        try {
            const responseId = await api.post("inscricoes", data);

            const responsePerfil = await api.get(`perfil/${data.perfilId}`)

            data.id = responseId.data.id;

            data.perfil = responsePerfil.data;

            data.campanha = campanha[0];

            setInscricao(inscricao => [...inscricao, data]);

            toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `Inscrição adicionada com sucesso` });

        }
        catch (error) {
            toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao adicionar inscricão!', detail: error.toString() });
        }
        setIsEditInscricao(false);
    }

    const handleEditSorteado = async (data) => {

        const response = await api.get(`inscricoes/${data.id}`);

        const tempData = {
            'id': response.data.id,
            'dataInscricao': response.data.dataInscricao,
            'campanhaId': response.data.campanhaId,
            'perfilId': response.data.perfilId,
            'sorteado': !response.data.sorteado
        }


        await api.put("inscricoes", tempData);

        const perfil = await api.get(`perfil/${response.data.perfilId}`);

        await api.put("perfil", {
            ...perfil.data,
            'tipo': !response.data.sorteado === true ? "ALUNO" : "INSCRITO"
        })

        setInscricao(inscricao.map(item => item.id === data.id ? { ...item, "sorteado": tempData.sorteado } : item))

    }

    const handleEditClick = async (data) => {

        const response = await api.get(`inscricoes/${data.id}`)

        setInscricoes({
            'id': response.data.id,
            'dataInscricao': response.data.dataInscricao,
            'campanhaId': response.data.campanhaId,
            'perfilId': response.data.perfilId,
            'sorteado': response.data.sorteado,
        })
        setIsEditInscricao(true)
        setIsEditing(true)

    }

    async function handleEditarInscricaoSubmit(data) {

        try {
            await api.put("inscricoes", data)
            toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `Inscrição atualizada com sucesso` });

            setInscricao(inscricao.map(item => item.id === data.id ? { ...item, "sorteado": data.sorteado } : item))

        }
        catch (error) {
            toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar inscricão!', detail: error.toString() });
        }
        setIsEditInscricao(false);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2 edit-btn" onClick={() => handleEditClick(rowData)} />

            </div>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Email</span>
                    <span className="table-data">{rowData.perfil.email}</span>
                </p>
            </React.Fragment>
        );
    }

    const celularBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Celular</span>
                    <span className="table-data">{rowData.perfil.celular}</span>
                </p>
            </React.Fragment>
        );
    }

    const codBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Código</span>
                <p className="table-data" style={{ textAlign: "center" }}>
                    {rowData.id}<br></br>
                </p>
            </React.Fragment>
        );
    }

    const nomeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Nome</span>
                <p className="table-data" style={{ textAlign: "center" }}>
                    {rowData.perfil.nome}<br></br>
                </p>
            </React.Fragment>
        );
    }

    const nascimentoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Nascimento</span>
                <p className="table-data" style={{ textAlign: "center" }}>
                    {new Date(rowData.perfil.dataNascimento).toLocaleDateString("pt-BR")}<br></br>
                </p>
            </React.Fragment>
        );
    }

    const dataBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Data Inscrição</span>
                <span className="table-data">{new Date(rowData.dataInscricao).toLocaleDateString("pt-BR")}</span>
            </React.Fragment>
        );
    }

    const sorteadoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sorteado</span>
                <Button onClick={() => handleEditSorteado(rowData)} className="p-button-rounded" style={{ color: 'white', backgroundColor: rowData.sorteado === true ? '#228B22' : '#FFA500' }} label={rowData.sorteado === true ? 'Sorteado' : 'Não Sorteado '} />
            </React.Fragment>
        );
    }

    const campanhaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Campanha</span>
                <p className="table-data" style={{ textAlign: 'center' }}>{rowData.campanha.nome}</p>
            </React.Fragment>
        );
    }

    function renderFilterStatus() {
        return (
            <Dropdown
                value={selectedStatus}
                options={['SIM', 'NÃO']}
                onChange={(e) => onStatusSearchChange(e)}
                placeholder="Selecione um status"
                className="p-column-filter"
                showClear={true} />
        );
    }

    const onStatusSearchChange = (event) => {
        if (event.value !== null) {
            dt.filter(event.value === 'SIM' ? true : event.value === 'NÃO' ? false : null, 'sorteado', 'equals');
            setSelectedStatus(event.value);
        }
        else
            dt.filter(null, 'sorteado', 'equals');
    }

    const handlePrintClick = async () => {
        setShowListaInscricoes(true);

    }

    const handleCsv = async () => {
        setShowListaCsv(true);

    }

    const acceptAprovar = async () => {

        try {
            const response = await api.get(`inscricoes/campanhaAtiva`);

            const resp = response.data.content.map(aprovar => {
                var aprovacao = { id: '', dataInscricao: '', campanha: '', perfil: '', sorteado: '' };
                aprovacao.id = aprovar.id
                aprovacao.dataInscricao = aprovar.dataInscricao
                aprovacao.campanha = aprovar.campanha
                aprovacao.perfil = aprovar.perfil
                aprovacao.sorteado = true
                return aprovacao
            })

            for (let i = 0; i < resp.length; i++) {
                await api.put("inscricoes/aprovar", resp[i]);
            }

            setInscricao(resp);

            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Aprovação de inscrições foi realizada!', life: 3000 });
            setTimeout(() => {

            }, 2000)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString(), life: 3000 });
        }
    }

    const rejectAprovar = async () => {

        try {
            toast.current.show({ severity: 'error', summary: 'Cancelado', detail: 'Aprovação de inscrições foi cancelado!', life: 3000 });
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
        }
    }

    const handleAprovarClick = () => {
        confirmDialog({
            message: 'Deseja aprovar todos os inscritos da campanha atual?',
            header: 'Aprovar inscritos',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            acceptClassName: 'p-mb-2  p-button-primary',
            rejectLabel: 'Não',
            rejectClassName: 'p-mb-2  p-button-primary',
            reject: () => rejectAprovar(),
            accept: () => acceptAprovar(),
        });

    }

    const getCampanhas = useCallback(async () => {
        try {
            const response = await api.get(`campanha`);

            setCampanhas(response.data.content.map(campanha => {
                var camp = { label: '', value: '' };
                camp.label = campanha.nome
                camp.value = campanha.id
                return camp
            }))
            setCampanha(response.data.content)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter campanhas', detail: error.toString() });
        }
    }, []);

    const getPerfis = useCallback(async () => {
        try {
            const response = await api.get(`perfil/perfilAll`);
            setPerfil(response.data.map(perfil => {
                var perf = { label: '', value: '' };
                perfil.cpf === ""
                    ? perf.label = perfil.nome + " - NÃO INFORMADO"
                    : perf.label = perfil.nome + " - " + perfil.cpf
                
                perf.value = perfil.id
                return perf
            }))
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter perfis', detail: error.toString() });
        }
    }, []);

    const getInscricoesAll = useCallback(async () => {
        try {
            const response = await api.get(`inscricoes/campanhaAtivaAll`);
            setInscricaoTotal(response.data.map(inscricao => {
                var insc = { id: '', perfilNome: '', perfilEmail: '', perfilCelular: '', campanhaNome: '', dataInscricao: '', dataNascimento: '', perfilCpf: '' };
                insc.id = inscricao.id
                insc.perfilNome = inscricao.perfilNome
                insc.perfilEmail = inscricao.perfilEmail
                insc.perfilCelular = inscricao.perfilCelular
                insc.campanhaNome = inscricao.campanhaNome
                insc.dataInscricao = new Date(inscricao.dataInscricao).toLocaleDateString('pt-BR')
                insc.dataNascimento = new Date(inscricao.perfilDataNascimento).toLocaleDateString('pt-BR')
                insc.perfilCpf = inscricao.perfilCpf
                return insc
            }))
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter inscricoes', detail: error.toString() });
        }
    }, []);

    useEffect(() => {
        const inscricoes = async () => {
            try {
                await getCampanhas();
                await getPerfis();
                await getInscricoesAll();

            }
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        inscricoes();

    }, [getCampanhas,getPerfis,getInscricoesAll])

    const onDataNascimentoSearchChange = (event) => {
        if (event.value !== null) {
            let data = event.value.toISOString();
            dt.filter(data, 'perfilDataNascimento', 'equals');
            setSelectedDataNascimento(event.value);
        }
        else
            dt.filter(null, 'perfilDataNascimento', 'equals');
    }

    const onDataNascimentoInputChange = (event) => {
        if (event.target.value !== null) {
            let data = new Date(event.target.value.split('/').reverse().join('/')).toISOString();
            dt.filter(data, 'perfilDataNascimento', 'equals');
            setSelectedDataNascimento(new Date(event.target.value));
        }
        else
            dt.filter(null, 'perfilDataNascimento', 'equals');
    }
    
    const onDataInscricaoSearchChange = (event) => {
        if (event.value !== null) {
            let data = event.value.toISOString();
            dt.filter(data, 'dataInscricao', 'equals');
            setSelectedDataInscricao(event.value);
        } else {
            dt.filter(null, 'dataInscricao', 'equals');
        }
    }
    
    const onDataInscricaoInputChange = (event) => {
        if (event.target.value !== null) {
            let data = new Date(event.target.value.split('/').reverse().join('/')).toISOString();
            dt.filter(data, 'dataInscricao', 'equals');
            setSelectedDataInscricao(new Date(event.target.value));
        } else {
            dt.filter(null, 'dataInscricao', 'equals');
        }
    }

    return (
        <>

            <FormInscricao
                defaultValues={inscricoes}
                isEditing={isEditing}
                isLoading={isLoading}
                submitAlterar={handleEditarInscricaoSubmit}
                submitAdicionar={handleAdicionarInscricaoSubmit}
                FormPerfil={perfil}
                FormCampanha={campanha}
                visible={isEditInscricao}
                setVisible={setIsEditInscricao}
            />

            <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">
                <DialogInscricoes
                    visible={showListaInscricoes}
                    setVisible={setShowListaInscricoes}
                    inscricao={inscricaoTotal}
                />
                <DialogCsv
                    visible={showListaCsv}
                    setVisible={setShowListaCsv}
                    inscricao={inscricaoTotal}
                />
                <div className="p-my-0 p-dir-rev p-col">
                    <div className="p-my-0">
                        <Button
                            textalign="center"
                            icon="pi pi-print"
                            className="p-button-secondary"
                            style={{ width: '35px' }}
                            onClick={handlePrintClick}
                            tooltip="Gerar relatório"
                            tooltipOptions={{ position: 'top' }}
                        />
                        <Button
                            textalign="center"
                            icon="pi pi-file"
                            className="p-button-secondary"
                            style={{ width: '35px', marginLeft: '5px' }}
                            onClick={handleCsv}
                            tooltip="Gerar CSV"
                            tooltipOptions={{ position: 'top' }}

                        />
                    </div>
                </div>
                <div className="p-my-0 p-grid p-dir-rev p-col">
                    <div className="p-mx-3 p-mt-2">
                        <Button
                            label="Aprovar Inscrições"
                            className="p-mb-2  p-button-success"
                            type="button"
                            onClick={handleAprovarClick}
                        />

                    </div>
                </div>
                <Toast ref={toast} position="bottom-right" />

                <Toast ref={confirmationToast} position="bottom-center" />

                <div className="p-col-12 datatable-responsive-demo">
                    <div className="p-fluid">
                        <DataTable style={{ width: '100%' }}
                            totalRecords={totalRecords}
                            lazy="true"
                            first={first}
                            onPage={getInscricoes}
                            ref={(element) => setDt(element)}
                            header={header}
                            value={inscricao}
                            filterDisplay="row"
                            className="p-datatable-responsive-demo"
                            emptyMessage="Nenhuma inscrição encontrada"
                            loading={isLoading}
                            dataKey="id"
                            paginator
                            rows={20}
                            currentPageReportTemplate={`Mostrando de {first} a {last} de ${totalRecords} inscrições`}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                            onSort={onSort}
                            onFilter={onFilter}
                            filters={filter.filters}
                        >
                            <Column style={{ width: '5%' }}
                                field="id"
                                header="Código"
                                sortable={sortable}
                                text
                                body={codBodyTemplate}
                                filter
                                filterMatchMode="equals"
                                filterPlaceholder={filter.filters !== undefined && (filter.filters.id !== undefined && filter.filters.id.value != null) ? filter.filters.id.value : "Pesquisar pelo Código"}

                            />

                            <Column style={{ width: '15%' }}
                                field="perfilNome"
                                header="Nome"
                                sortable
                                text
                                body={nomeBodyTemplate}
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder={filter.filters !== undefined && (filter.filters.perfilNome !== undefined && filter.filters.perfilNome.value !== "") ? filter.filters.perfilNome.value : "Pesquisar pelo nome"}
                            />

                            <Column style={{ width: '15%' }}
                                field="perfil.dataNascimento"
                                header="Nascimento"
                                sortable
                                text
                                body={nascimentoBodyTemplate}
                                filter
                                filterElement={
                                    <BrCalendar
                                      key={selectedDataNascimento}
                                      id="data"
                                      name="data"
                                      value={selectedDataNascimento}
                                      onSelect={(e) => onDataNascimentoSearchChange(e)}
                                      onInput={(e) => onDataNascimentoInputChange(e)}
                                      yearRange={`2000:${new Date().getFullYear()}`}
                                      placeholder="Selecione uma data"
                                      onClearButtonClick={() => handleLimparClick()}
                                    />
                                  }
                            />

                            <Column style={{ width: '20%' }}
                                field="perfilEmail"
                                header="Email"
                                sortable={sortable}
                                body={emailBodyTemplate}
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder={filter.filters !== undefined && (filter.filters.perfilEmail !== undefined && filter.filters.perfilEmail.value !== "") ? filter.filters.perfilEmail.value : "Pesquisar pelo email"}

                            />

                            <Column style={{ width: '15%' }}
                                field="perfilCelular"
                                header="Celular"
                                sortable={sortable}
                                body={celularBodyTemplate}
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder={filter.filters !== undefined && (filter.filters.perfilCelular !== undefined && filter.filters.perfilCelular.value !== "") ? filter.filters.perfilCelular.value : "Pesquisar pelo celular"}

                            />

                            <Column style={{ width: '15%' }}
                                field="campanhaId"
                                header="Campanha"
                                sortable
                                body={campanhaBodyTemplate}
                                filter
                                filterMatchMode="custom"
                                filterElement={filterCampanha}

                            />

                            <Column style={{ width: '10%' }}
                                field="dataInscricao"
                                header="Data"
                                sortable
                                body={dataBodyTemplate}
                                filter
                                filterElement={
                                        <BrCalendar
                                            key={selectedDataInscricao}
                                            id="data"
                                            name="data"
                                            value={selectedDataInscricao}
                                            onSelect={(e) => onDataInscricaoSearchChange(e)}
                                            onInput={(e) => onDataInscricaoInputChange(e)}
                                            yearRange={`2000:${new Date().getFullYear()}`}
                                            placeholder="Selecione uma data"
                                            onClearButtonClick={() => handleLimparClick()}
                                        />
                                }
                            />

                            <Column style={{ width: '10%' }}
                                field="sorteado"
                                header="Sorteado"
                                sortable
                                body={sorteadoBodyTemplate}
                                filter
                                filterMatchMode="custom"
                                filterElement={filterStatus}

                            />

                            <Column style={{ width: '10%' }}
                                header="Ações"
                                body={actionBodyTemplate}

                            />

                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
