import React, { useRef, useEffect, useState, useContext, useCallback } from 'react';

import { useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'

import api from '../api/api';

import {FilterContext} from '../contexts/FilterContext';
import {AuthContext} from '../contexts/AuthContext';
import { FormResponderMensagens } from './FormResponderMensagens';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import {MensagemContext} from '../contexts/MensagemContext';

export const TabelaMensagens = () => {
    const toast = useRef(null);
    const [mensagens, setMensagens] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const confirmationToast = useRef(null)
    const [dt, setDt] = useState(null);
    const history = useHistory();  
    const { user } = useContext(AuthContext);

    const [sortable, setSortable] = useState('DESC');
    const [sortableCampo, setSortableCampo] = useState('id');
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [loadingMensagem, setLoadingMensagem] = useState(false);
    const [showAdicionarMensagem, setShowAdicionarMensagem] = useState(false);
    const [mensagem, setMensagem] = useState([]);
    const [visible, setVisible] = useState(false);
    const [conversaVisible, setConversaVisible] = useState(false);
    const [info, setInfo] = useState("");
    const { filter, setFilter } = useContext(FilterContext);
    const [atualizarMensagem, setAtualiarMensagem] = useState({});
    const [conversa, setConversa] = useState({});
    const { setMensagemLida } = useContext(MensagemContext);
    let mensagensConversa = [];

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Mensagens</h2>
            </div>
            <div className="p-my-0 p-grid p-dir-rev p-col">
                <div className="p-m-2">
                <Button
                    label="Adicionar"
                    className="p-mb-2 p-button-primary"
                    icon="pi pi-plus"
                    iconPos="left"
                    type="button"

                    onClick={() => history.push('/mensagenscreate')}

                />
                </div>
                <div className="p-m-2">
                    <Button
                        label="Limpar Filtros"
                        className="p-mb-2 p-button-primary"
                        iconPos="left"
                        type="button"
                        onClick={() => handleLimparClick()}
                    />
                </div>
            </div>
        </div>
        
    );

    const handleLimparClick = () => {
        setFilter({
            first: 0,
            rows: 20,
            sortField: null,
            sortOrder: null,
            filters: {
            },
            
        });
        setSelectedStatus(null);
    }

    const getMensagens = useCallback(async (event) => {
        setIsLoading(true)
        setMensagens([])

        // console.log(event)   

        if(event !== undefined) {
            setTimeout(() => {
                const startIndex = event.first;

                setFirst(startIndex);
                // setMensagens(dataSource.slice(startIndex, endIndex));            
            }, 250);
        }
       
        try {

            if(filter.filters === undefined) 
            {

                if(event === undefined) {
                    const response = await api.get(`mensagens/porInstrutor/${user.email}?sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(mensagem => {
                        setMensagens(mensagens => [...mensagens, mensagem])
                    })                
                } else {
                    const response = await api.get(`mensagens/porInstrutor/${user.email}?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(mensagem => {
                        setMensagens(mensagens => [...mensagens, mensagem])
                    })  
                    
                }

                const respElements = await api.get(`mensagens/porInstrutor/${user.email}?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);

            } 
            else 
            {     
                const pesquisa = {
                    "id": filter.filters['id'] !== undefined ? filter.filters['id'].value : null,
                    "emissorNome": filter.filters['emissorNome'] !== undefined ? filter.filters['emissorNome'].value : "",
                    "receptorNome": filter.filters['receptorNome'] !== undefined ? filter.filters['receptorNome'].value : "",
                    "mensagem": filter.filters['mensagem'] !== undefined ? filter.filters['mensagem'].value : "",
                    "email": user.email,
                }

                if (event === undefined) {   
                    const respElements = await api.post(`mensagens/searchMensagens/${sortable}/${sortableCampo}?`, pesquisa);
                    respElements.data.content.forEach(mensagem => {
                        setMensagens(mensagens => [...mensagens, mensagem])
                    })
                    setTotalRecords(respElements.data.totalElements);
                } else {
                    const respElements = await api.post(`mensagens/searchMensagens/${sortable}/${sortableCampo}?page=${event.page}`, pesquisa);
                    respElements.data.content.forEach(mensagem => {
                        setMensagens(mensagens => [...mensagens, mensagem])
                    })
                    setTotalRecords(respElements.data.totalElements);
                }
            }
            
            
            
        }
        catch (error) {  

            if(filter !== undefined){
                if(event === undefined) {
                    const response = await api.get(`mensagens/porInstrutor/${user.email}?sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(mensagem => {
                        setMensagens(mensagens => [...mensagens, mensagem])
                    })                  
                } else {
                    const response = await api.get(`mensagens/porInstrutor/${user.email}?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(mensagem => {
                        setMensagens(mensagens => [...mensagens, mensagem])
                    }) 
                    
                }

                const respElements = await api.get(`mensagens/porInstrutor/${user.email}?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);
            } else {
                toast.current.show({ severity: 'error', summary: 'Erro ao obter mensagens', detail: error.toString() });    
            }
              
        }

        setIsLoading(false)
    }, [filter,sortable,sortableCampo]);

    useEffect(() => {
        const fetchMensagens = async () => {
            try {
                await getMensagens();

            }
            catch (error){
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchMensagens();

    }, [getMensagens])

    const actionBodyTemplate = (rowData) => {

        return (
            <div className="actions">
                {rowData.receptor.email === user.email && !rowData.mensagemRespondida ?
                    <Button icon="pi pi-comment" tooltip="Responder Mensagem" className="p-button-rounded p-mr-2 edit-btn" onClick={() => handleResponderClick(rowData)}/>
                :
                <></>
                }
                {rowData.mensagemAnterior === null && rowData.mensagemRespondida ?
                    <Button icon="pi pi-comments" tooltip="Vizualizar Conversa" className="p-button-rounded p-mr-2 edit-btn" onClick={() => handleConversaClick(rowData)}/>
                :
                <></>
                }
                {rowData.mensagemLida || rowData.emissor.email === user.email ?
                    <Button icon="pi pi-window-maximize" tooltip="Detalhar Mensagem" className="p-button-rounded p-mr-2 edit-btn" onClick={() => handleInfoClick(rowData)}/>
                :
                    <Button icon="pi pi-window-maximize" tooltip="Detalhar Mensagem" className="p-button-rounded p-mr-2 p-button-danger" onClick={() => handleInfoClick(rowData)}/>
                }
                {rowData.emissor.email === user.email && !rowData.mensagemLida ?
                    <Button icon="pi pi-trash" className="p-button-rounded p-mr-2 p-button-danger" loading={isDeleting} onClick={() => handleDeleteClick(rowData)}/>
                :
                <></>
                }
                
            
            </div>
        );
    }

    const codBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const emissorBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Emissor</span>
                    <span className="table-data">{rowData.emissor.nome}</span>
                </p>
            </React.Fragment>
        );
    }

    const receptorBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Receptor</span>
                    <span className="table-data">{rowData.receptor.nome}</span>
                </p>
            </React.Fragment>
        );
    }

    const mensagemBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Mensagem</span>
                    <span className="table-data">{rowData.mensagem.length > 50 ? rowData.mensagem.slice(0, 50) + '...' : rowData.mensagem}</span>
                </p>
            </React.Fragment>
        );
    }

    const onSort = (event) => {
        setSortableCampo(event.sortField);
        if(sortable === 'ASC'){
            setSortable('DESC')
        } else if (sortable === 'DESC') {
            setSortable('ASC')
        }
    }

    const onFilter = (event) => {
        event['first'] = 0;
        // setLazyParams(event);
        setFilter(event);   
    }

    const handleResponderClick = async (data) => {
        setLoadingMensagem(true);
        setShowAdicionarMensagem(true);
        setMensagem({
            'emissorId': data.receptor.id,
            'receptorId': data.emissor.id,
            'mensagem': '',
            'mensagemLida': false,
            'mensagemRespondida': false,
            'mensagemAnterior': data.id,
          })
        setAtualiarMensagem({
            'id': data.id,
            'emissorId': data.emissor.id,
            'receptorId': data.receptor.id,
            'mensagem': data.mensagem,
            'mensagemLida': true,
            'mensagemRespondida': true,
            'mensagemAnterior': data.mensagemAnterior,
        })
        setLoadingMensagem(false);
    }
    
    const handleConversaClick = async (mensagem) => {
        const mensagemInicial = await api.get(`mensagens/${mensagem.id}`);
        const respostas = await api.get(`mensagens/conversa/${mensagem.id}`);
        let conversa = [mensagemInicial.data]; 
        respostas.data.forEach(resposta => {
            conversa.push(resposta)
        });   
        setConversa(conversa);
        setConversaVisible(true);
        
    }

    const handleInfoClick = async (data) => {
        setVisible(true);
        setInfo(data.mensagem);
        if (!data.mensagemLida && data.receptor.email === user.email)
        {
            let mensagem1 = {
                "id": data.id,
                "emissorId": data.emissor.id,
                "receptorId": data.receptor.id,
                "mensagem": data.mensagem,
                "mensagemLida": true,
                "mensagemRespondida": data.mensagemRespondida,
                'mensagemAnterior': data.mensagemAnterior,
            }
            await api.put("mensagens", mensagem1)
            setFilter({
                first: 0,
                rows: 20,
                sortField: null,
                sortOrder: null,
                filters: {
                },
                
            });
            setMensagemLida(Math.random());
        }
    }

    const handleDeleteClick = (data) => {

        data['userEmail'] = user.email

        confirmDialog({
            message: `Você tem certeza que quer remover a mensagem?`,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: async () => {
                setIsDeleting(true);

                try {

                    await api.delete(`mensagens/${data.id}`);       
                    toast.current.show({ life: 5000, severity: 'success', summary: 'Remoção concluída!', detail: `Mensagem removida com sucesso` });
                    getMensagens()
                }
                catch (error) {
                    toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover o mensagens', detail: error.response.data });
                }

                setIsDeleting(false);
            },
            reject: () => { return }
        });
    }

    return (
        <>
        <FormResponderMensagens
            defaultValues={mensagem}
            mensagem={atualizarMensagem}
            visible={showAdicionarMensagem}
            setVisible={setShowAdicionarMensagem}
            isLoading={loadingMensagem}
            setFilter={setFilter}
        />
        <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">
            <Dialog
                className='p-scrollpanel-bar-y'
                header='Mensagem'
                onHide={() => { setVisible(false)}}
                visible={visible}
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ width: '50vw', heigth: '5vw' }}
            >
                <div className="p-field p-col-12 p-sm-12">
                    <InputTextarea
                        style={{width:"100%"}} 
                        id="mensagem"
                        name="mensagem"
                        autoResize
                        value={info}
                        readOnly
                    />
                </div>
            </Dialog>

            <Dialog
                className='p-scrollpanel-bar-y'
                header='Conversa'
                onHide={() => { setConversaVisible(false)}}
                visible={conversaVisible}
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ width: '50vw', heigth: '5vw' }}
            >
                {conversa.length !== undefined && (
                    conversa.forEach(conversa => {
                        mensagensConversa.push(
                        <div className="p-field p-col-12 p-sm-12" >
                        
                        {conversa.emissor != undefined ? 
                            conversa.emissor.email === user.email ?
                            <>
                            <p style={{marginLeft: "50%", fontSize: "12px"}} >{conversa.emissor != undefined ? conversa.emissor.nome : conversa.emissorNome} </p>
                                <InputTextarea
                                    style={{backgroundColor: "#DCF8C6", marginTop: "-12px", marginBottom: "-23px", marginLeft: "50%", width: "50%", borderRadius:"15px 0 15px 15px"}} 
                                    id="mensagem"
                                    name="mensagem"
                                    autoResize
                                    value={conversa.mensagem}
                                    readOnly
                                />
                            </>
                            :
                            <>
                            <p style={{fontSize: "12px"}}>{conversa.emissor != undefined ? conversa.emissor.nome : conversa.emissorNome} </p>
                                <InputTextarea
                                    style={{backgroundColor: "#EDF8F5", marginBottom: "-23px", marginTop: "-12px", width: "50%", borderRadius:"0 15px 15px 15px"}} 
                                    id="mensagem"
                                    name="mensagem"
                                    autoResize
                                    value={conversa.mensagem}
                                    readOnly
                                />
                            </>
                          :
                          conversa.emissorEmail === user.email ?
                            <>
                            <p style={{marginLeft: "50%", fontSize: "12px"}} >{conversa.emissor != undefined ? conversa.emissor.nome : conversa.emissorNome} </p>
                                <InputTextarea
                                    style={{backgroundColor: "#DCF8C6", marginTop: "-12px", marginBottom: "-23px", marginLeft: "50%", width: "50%", borderRadius:"15px 0 15px 15px"}} 
                                    id="mensagem"
                                    name="mensagem"
                                    autoResize
                                    value={conversa.mensagem}
                                    readOnly
                                />
                            </>
                            :
                            <>
                            <p style={{fontSize: "12px"}}>{conversa.emissor != undefined ? conversa.emissor.nome : conversa.emissorNome} </p>
                                <InputTextarea
                                    style={{backgroundColor: "#EDF8F5", marginBottom: "-23px", marginTop: "-12px", width: "50%", borderRadius:"0 15px 15px 15px"}} 
                                    id="mensagem"
                                    name="mensagem"
                                    autoResize
                                    value={conversa.mensagem}
                                    readOnly
                                />
                            </>
                        }
                        
                    </div>)
                        
                    })
                )}
                {mensagensConversa}
            </Dialog>

            <Toast ref={toast} position="bottom-right" />
            
            <Toast ref={confirmationToast} position="bottom-center" />
            
            <div className="card p-col-12 datatable-responsive-demo">
                
                <div className="p-fluid">
                    
                    <DataTable      
                        totalRecords={totalRecords}  
                        lazy="true"
                        first={first} 
                        onPage={getMensagens}       
                        ref={(element) => setDt(element)}
                        header={header}
                        value={mensagens}
                        filterDisplay="row"
                        className="p-datatable-responsive-demo"
                        emptyMessage="Nenhuma mensagem encontrada"
                        loading={isLoading}
                        dataKey="id"
                        paginator
                        rows={20}
                        currentPageReportTemplate={`Mostrando de {first} a {last} de ${totalRecords} mensagens`}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                        // rowsPerPageOptions={[10, 20, 30]} 
                        onSort={onSort}                       
                        onFilter={onFilter}
                       filters={filter.filters}
                    >

                        <Column
                            field="id"
                            header="Código"
                            sortable={sortable}
                            body={codBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.id !== undefined && filter.filters.id.value != null) ? filter.filters.id.value : "Pesquisar pelo Código"}
                            
                        />
                        
                        <Column
                            field="emissorNome"
                            header="De"
                            sortable={sortable}
                            body={emissorBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.emissorNome !== undefined && filter.filters.emissorNome.value !== "") ? filter.filters.emissorNome.value : "Pesquisar pelo Emissor"}
                            
                        />

                        <Column
                            field="receptorNome"
                            header="Para"
                            sortable={sortable}
                            body={receptorBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.receptorNome !== undefined && filter.filters.receptorNome.value !== "") ? filter.filters.receptorNome.value : "Pesquisar pela observação"}
                            
                        />

                        <Column
                            field="mensagem"
                            header="Mensagem"
                            sortable={sortable}
                            body={mensagemBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.mensagem !== undefined && filter.filters.mensagem.value !== "") ? filter.filters.mensagem.value : "Pesquisar pela observação"}
                            
                        />

                        <Column
                            header="Ações"
                            body={actionBodyTemplate}
                            
                        />
    
                    </DataTable>
                    
                </div>
            </div>
        </div>
       
        </>
    );
}