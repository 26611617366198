import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Dialog } from 'primereact/dialog';
import { SelectButton } from 'primereact/selectbutton';

import '../layout/sass/_dialogPermissao.scss';

export const PermissaoUsuario = ({ visible, setVisible, permissao }) => {

    const permissions = ['Administrador', 'Usuário'];
    const [selectedPermission, setSelectedPermission] = useState(permissao);
    const history = useHistory();

    const handleSelectClick = (e) => {
        if (e.value === 'Usuário') {
            setSelectedPermission(e.value)
            history.replace('/home')
        }
        else if ((e.value === 'Administrador')) {
            setSelectedPermission(e.value)
            history.replace('/login')
        }
        else
            return
    }

    return (
        <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            className="permission-dialog"
        >
            <div className="permission-dialog-content">
                <h3>Você está logado como</h3>
                <SelectButton 
                value={selectedPermission}
                options={permissions}
                onChange={(e) => handleSelectClick(e)}
                className="select-permission"
                />
            </div>
        </Dialog>
    );
}

