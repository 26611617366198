import React, { useEffect, useState, useRef, useCallback } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { FormMatricula } from './FormMatricula';
import { FormPerfil } from './FormPerfil';
import { FormIndisponivel } from './FormIndisponivel';
import { ErrorRetrievingData } from './ErrorRetrievingData';
import { useUserMenu } from '../hooks/useUserMenu';
import '../layout/sass/_tabMenu.scss';
import api from '../api/api';
import { useHistory } from 'react-router-dom';

export const UserMenu = () => {

    const {
        getUserData,
        errorGettingData,
        errorMessage,
        isLoading
    } = useUserMenu();
    const toast = useRef(null);
    const [periodoMatricula, setPeriodoMatricula] = useState(null);
    const [periodoRematricula, setPeriodoRematricula] = useState(null);
    const [periodoMatriculaOuRematricula, setPeriodoMatriculaOuRematricula] = useState(null);
    const [periodoInscricoes, setPeriodoInscricoes] = useState(null);
    const [foraDoPeriodo, setForaDoPeriodo] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const history = useHistory();
    const [defaultValues] = useState({
        "nome": "",
        "cpf": "",
        "email": "",
        "dataNascimento": "",
        "responsavel": "",
        "celular": "",
        "telefone": "",
        "lgpdAceite": false,
        "tipo": "",
        "inscricao": "",
      });

    let items;
    items = [
        periodoRematricula ?
        { label: 'Rematricula', icon: 'pi pi-id-card' }
        : periodoMatricula ?
        { label: 'Matricula', icon: 'pi pi-id-card' }
        : periodoInscricoes ?
        { label: 'Inscrição', icon: 'pi pi-id-card' }
        : foraDoPeriodo ?
        { label: 'Indisponível', icon: 'pi pi-id-card' }
        : <></>,        
        { label: 'Admin', icon: 'pi pi-cog' },
        { label: 'Alunos', icon: 'pi pi-users' },

        

    ]

    const handleTabChange = (index) => {
        setActiveIndex(index)
        if (index === 1) {
            history.push('/login');
        }
        if (index === 2) {
            history.push('/acessoalunos');
        }
    }

    const getPeriodoMatricula = useCallback(async () => {
        try {
            const response = await api.get(`periodoMatricula/periodo`);
            setPeriodoMatricula(response.data.periodoMatricula);
            setPeriodoRematricula(response.data.periodoRematricula);
            setPeriodoMatriculaOuRematricula(response.data.periodoMatriculaOuRematricula);
            setPeriodoInscricoes(response.data.periodoInscricoes);
            setForaDoPeriodo(response.data.foraDoPeriodo);
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter periodo da matricula', detail: error.toString() });
        }
    }, []);

    useEffect(() => {
        async function menu() {
            await getPeriodoMatricula();
        };
        menu();

    }, [getPeriodoMatricula])
    
    return (
        <div style={{ height: '100vh', backgroundColor: '#fff', overflow: 'auto' }}>
            <TabMenu
                model={items}
                activeIndex={activeIndex}
                onTabChange={(e) => handleTabChange(e.index)}
                className="custom-tab-menu"
            />
            {
                errorGettingData ?
                    <ErrorRetrievingData
                        errorMessage={errorMessage}
                        retrieveFunction={() => getUserData()}
                        isLoading={isLoading}
                    />
                    :
                    periodoMatriculaOuRematricula ?
                        <div className="card p-grid p-col-12 p-jc-center p-mx-0 p-p-0">
                            <div className="p-col-12 p-md-10 p-lg-8 p-xl-6">
                                <FormMatricula />
                            </div>
                        </div>
                        : periodoInscricoes
                            ? <div className="card p-grid p-col-12 p-jc-center p-mx-0 p-p-0">
                                <div className="p-col-12 p-md-10 p-lg-8 p-xl-6">
                                    <FormPerfil defaultValues={defaultValues} />
                                </div>
                              </div>
                            : foraDoPeriodo ?
                            <div className="card p-grid p-col-12 p-jc-center p-mx-0 p-p-0">
                                <div className="p-col-12 p-md-10 p-lg-8 p-xl-6">
                                    <FormIndisponivel />
                                </div>
                              </div>
                            : <></>
            }
        </div>
    );
}
