import React, {useState } from 'react';
import { Dialog } from 'primereact/dialog';

export const AppFooter = () => {
    
    const [isShowInfo, setIsShowInfo] = useState(false);
    const [msgInfo, setMsgInfo] = useState([]);
   


    const handleInfoClick = () => {
        
        setMsgInfo([''])
        setIsShowInfo(true)
    }
    

    return (
        <>
        <Dialog className='p-scrollpanel-bar-y' header="Sobre este Site" onHide={() => setIsShowInfo(false)} visible={isShowInfo} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '35vw', heigth: '5vw'}} >
            {msgInfo.map(() => {
                return (<p><p>O Sistema Conservatório Municipal de Patos de Minas - MG foi desenvolvido pela fábrica de software.</p>
                <p><b>Desenvolvido por:</b></p>
                <center>Gustavo Martins</center>
                <center>Heitor Gonçalves</center>                
                <center>Nixon da Silva</center>
                </p>)
            })}
        </Dialog>
        <div className="layout-footer">
            <span className="footer-text" style={{ 'marginRight': '5px', 'display': 'block' }}>
                Prefeitura Municipal de Patos de Minas - Copyright © - Todos os direitos reservados
            </span>
            <span className="footer-text" style={{ 'marginLeft': '5px', 'display': 'block'}}>
                Desenvolvido por: <button onClick={() => handleInfoClick()}>Fábrica de Software</button>
                
            </span>
        </div>
        </>
    );
}


