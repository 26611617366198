import { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  const { signed, loading, user, verificaToken } = useContext(AuthContext);

  useEffect(() => {
    if (!signed) {
      const userTemp = JSON.parse(localStorage.getItem("user"));
      if (userTemp != null) {      
        verificaToken(userTemp);
      }
    }
  }, [signed, verificaToken]);

  if (loading) {
    return (
      <div> Carregando..... </div>
    )
  }

  if (!signed && isPrivate) {
    return <Redirect to="/" />
  }

  if (signed && !isPrivate) {
    if (user.perfilUser.includes('user-admin') || user.perfilUser.includes('user-instrutor')) {
      localStorage.setItem("user", JSON.stringify(user));
      return <Redirect to="/admin" /> // "/admin"
    }
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Component {...props} />
      )}
    />
  )
}