import React, { useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import { BrCalendar } from './BrCalendar';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ErrorValidationMessage } from './ErrorValidationMessage';
import api from '../api/api';
import '../layout/sass/_form.scss';

export const FormPerfisMensagens = ({ isLoading, defaultValues, visible, setVisible }) => {

    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)

    const schema = Yup.object().shape({
        nome: Yup
            .string()
            .required("O nome é obrigatório")
            .max(200, "O nome dete ter no máximo 200 caracteres"),
        cpf: Yup
            .string()
            .required("O CPF é obrigatório"),
        email: Yup
            .string()
            .max(198, "O e-mail deve ter no máximo 198 caracteres")
            .required("O e-mail é obrigatório")
            .email("O e-mail inserido é inválido"),
        dataNascimento: Yup
            .date()
            .max(new Date(), "Data de nascimento inválida")
            .typeError("Uma data deve ser inserida")
            .required("A data de nascimento é obrigatória"),
        celular: Yup
            .string()
            .required("O celular é obrigatório"),

    })

    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)

            try {
                await api.post("perfil", data)

                formik.resetForm()
                toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `${data.nome} adicionado(a) com sucesso` });
                setTimeout(() => {
                    setVisible(false);

                }, 2000)

            }
            catch (error) {
                toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao cadastrar perfil!', detail: error.toString() });
            }

            setIsSubmiting(false)
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const monName = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];
    const now = new Date();
    let day = now.getDate();
    let month = now.getMonth();
    let year = now.getFullYear();

    return (
        <>
            <Toast ref={toast} position="bottom-right" />
                <Dialog
                className='p-scrollpanel-bar-y'
                header='Adicionar Perfil'
                onHide={() => { setVisible(false); formik.resetForm() }}
                visible={visible}
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ width: '50vw', heigth: '5vw' }}
            >
            <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
                <Toast ref={toast} position="bottom-right" />
                <div className="p-col-12">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-formgrid p-grid p-fluid p-mx-0">
                            <div className="p-field p-col-12 p-sm-4">
                                <label
                                    htmlFor="nome"
                                    className={classNames({ 'p-error': isFormFieldValid('nome') })}
                                >
                                    Nome
                                </label>
                                {
                                    !isLoading ?
                                        <InputText
                                            id="nome"
                                            name="nome"
                                            className={classNames({ 'p-invalid': isFormFieldValid('nome') })}
                                            value={formik.values.nome}
                                            onChange={formik.handleChange}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.nome && formik.touched.nome &&
                                    <ErrorValidationMessage message={formik.errors.nome} />
                                }
                            </div>
                            <div className="p-field p-col-12 p-sm-4">
                                <label
                                    htmlFor="cpf"
                                    className={classNames({ 'p-error': isFormFieldValid('cpf') })}
                                >
                                    CPF
                                </label>
                                {
                                    !isLoading ?
                                        <InputMask
                                            id="cpf"
                                            name="cpf"
                                            mask="999.999.999-99"
                                            unmask={true}
                                            value={formik.values.cpf}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('cpf') })}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.cpf && formik.touched.cpf &&
                                    <ErrorValidationMessage message={formik.errors.cpf} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-4">
                                <label
                                    htmlFor="email"
                                    className={classNames({ 'p-error': isFormFieldValid('email') })}
                                >
                                    E-mail
                                </label>
                                {
                                    !isLoading ?
                                        <InputText
                                            id="email"
                                            name="email"
                                            className={classNames({ 'p-invalid': isFormFieldValid('email') })}
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            readOnly
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.email && formik.touched.email &&
                                    <ErrorValidationMessage message={formik.errors.email} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-4 p-md-4">
                                <label
                                    htmlFor="dataNascimento"
                                    className={classNames({ 'p-error': isFormFieldValid('dataNascimento') })}
                                >
                                    Data de nascimento
                                </label>
                                <BrCalendar
                                    id="dataNascimento"
                                    name="dataNascimento"
                                    value={formik.values.dataNascimento}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dataNascimento') })}
                                    yearRange={`1922:${new Date().getFullYear()}`}
                                />
                                {formik.errors.dataNascimento && formik.touched.dataNascimento &&
                                    <ErrorValidationMessage message={formik.errors.dataNascimento} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-4">
                                <label
                                    htmlFor="celular"
                                    className={classNames({ 'p-error': isFormFieldValid('celular') })}
                                >
                                    Telefone Celular
                                </label>
                                {
                                    !isLoading ?
                                        <InputMask
                                            id="celular"
                                            name="celular"
                                            unmask={true}
                                            value={formik.values.celular}
                                            mask={formik.values.celular !== undefined && formik.values.celular.length <= 10 ? "(99) 9999-9999?9" : "(99) 99999-9999"}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('celular') })}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.celular && formik.touched.celular &&
                                    <ErrorValidationMessage message={formik.errors.celular} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-4">
                                <label
                                    htmlFor="tipo"
                                    className={classNames({ 'p-error': isFormFieldValid('tipo') })}
                                    style={{ textAlign: 'justify', textJustify: 'distribute' }}
                                >
                                    Tipo de Perfil
                                </label>
                                {
                                    !isLoading ?
                                        <Dropdown
                                            id="tipo"
                                            name="tipo"
                                            value={formik.values.tipo}
                                            options={formik.values.tipoPerfis}
                                            emptyMessage="Nenhum tipo encontrado"
                                            emptyFilterMessage={"Nenhum tipo encontrado"}
                                            filter={true}
                                            onChange={(e) => formik.setFieldValue("tipo", e.value)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('tipo') })}
                                            placeholder="Selecione o tipo de perfil"
                                            showClear={true}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }

                                {formik.errors.tipo && formik.touched.tipo &&
                                    <ErrorValidationMessage message={formik.errors.tipo} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-4">
                                <label
                                    htmlFor="deficiencia"
                                    className={classNames({ 'p-error': isFormFieldValid('deficiencia') })}
                                    style={{ textAlign: 'justify', textJustify: 'distribute' }}
                                >
                                    Tipo de deficiência
                                </label>
                                {
                                    !isLoading ?
                                        <Dropdown
                                            id="deficiencia"
                                            name="deficiencia"
                                            value={formik.values.deficiencia  === "deficienciaAuditiva" ? "DEFICIÊNCIA AUDITIVA"
                                                    : formik.values.deficiencia === "deficienciaVisual" ? "DEFICIÊNCIA VISUAL"
                                                    : formik.values.deficiencia === "deficienciaIntelectual" ? "DEFICIÊNCIA INTELECTUAL"
                                                    : formik.values.deficiencia === "deficienciaMotora" ? "DEFICIÊNCIA MOTORA"
                                                    : formik.values.deficiencia === "sindromeDeDown" ? "SINDROME DE DOWN"
                                                    : formik.values.deficiencia  }
                                            options={formik.values.deficienciaPerfis}
                                            emptyMessage="Nenhuma deficiência encontrada"
                                            emptyFilterMessage={"Nenhuma deficiência encontrada"}
                                            filter={true}
                                            onChange={(e) => formik.setFieldValue("deficiencia", 
                                                        e.value === "DEFICIÊNCIA AUDITIVA" ? "deficienciaAuditiva"
                                                        : e.value === "DEFICIÊNCIA VISUAL" ? "deficienciaVisual"
                                                        : e.value === "DEFICIÊNCIA INTELECTUAL" ? "deficienciaIntelectual"
                                                        : e.value === "DEFICIÊNCIA MOTORA" ? "deficienciaMotora"
                                                        : e.value === "SINDROME DE DOWN" ? "sindromeDeDown"
                                                        : e.value )}
                                            className={classNames({ 'p-invalid': isFormFieldValid('deficiencia') })}
                                            placeholder="Selecione a deficiência"
                                            showClear={true}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }

                                {formik.errors.deficiencia && formik.touched.deficiencia &&
                                    <ErrorValidationMessage message={formik.errors.deficiencia} />
                                }
                            </div>

                        </div>
                        <div className="p-d-flex p-jc-end p-mr-1">
                            {
                                !isLoading ?
                                    <Button
                                        label='Salvar'
                                        type="submit"
                                        iconPos="right"
                                        loading={isSubmiting}
                                    />
                                    :
                                    <Skeleton width="82.16px" height="35px" />

                            }
                        </div>
                    </form>
                </div>
            </div>
            </Dialog>
        </>
    );
}