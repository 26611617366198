import React from 'react';
import MenuMain from '../../MenuMain';
import { FormCampanhas } from '../../components/FormCampanhas';
import { AppFooter } from '../../AppFooter';


export default function CadastrarCampanhasPage() {

  const defaultValues = {
    'nome': '',
    'dataInicio': '',
    'dataFim': '',
    'ativo': false


  }

  return (
    <div>
      <MenuMain>
         <FormCampanhas
          defaultValues={defaultValues}
        />
      </MenuMain>
      <AppFooter />
    </div>
  );
}
