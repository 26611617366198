import React, { useRef } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useHistory } from "react-router-dom";
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorValidationMessage } from './ErrorValidationMessage';

import api from '../api/api';

import '../layout/sass/_form.scss';

export const FormLogs = ({ isEditing, isLoading, defaultValues }) => {

    const history = useHistory();
    const toast = useRef(null);

    const schema = Yup.object().shape({
        nome: Yup
            .string()
            .required("O nome é obrigatório")
            .max(200, "O nome dete ter no máximo 200 caracteres"),
        embalagem: Yup
            .string()
            .required("A embalagem é obrigatório")
            .max(200, "A embalagem dete ter no máximo 200 caracteres"),
        pesoEmKg: Yup
            .number()
            .required("O peso da embalágem é obrigatório")
            .positive("O peso da embalágem deve ser maior ou igual a 1"),            
        
    })

    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {                  
           
            try {
                if (!isEditing) {

                    await api.post("logs", data)

                    formik.resetForm()
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `${data.nome} adicionado(a) com sucesso` });
                    setTimeout(() => {
                        history.push('/logs')

                    }, 2000)

                }
                else {

                    await api.put("logs", data)
                    
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `${data.nome} atualizado(a) com sucesso` });
                    setTimeout(() => {
                        
                        history.push('/logs')

                    }, 2000)
                }
            }
            catch (error) {
                if (!isEditing)
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao cadastrar logs!', detail: error.toString() });
                else
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar logs!', detail: error.toString() });

            }

        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    return (
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <div className="p-col-12">
                <h2 className="p-mt-0">Logs</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-fluid p-mx-0">

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="usuario"
                                className={classNames({ 'p-error': isFormFieldValid('usuario') })}
                            >
                                Usuário
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="usuario"
                                        name="usuario"
                                        className={classNames({ 'p-invalid': isFormFieldValid('usuario') })}
                                        value={formik.values.usuario}
                                        onChange={formik.handleChange}
                                        readOnly
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.usuario && formik.touched.usuario &&
                                <ErrorValidationMessage message={formik.errors.usuario} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="updated"
                                className={classNames({ 'p-error': isFormFieldValid('updated') })}
                            >
                                Updated
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="updated"
                                        name="updated"
                                        className={classNames({ 'p-invalid': isFormFieldValid('updated') })}
                                        value={new Date(formik.values.updated).toLocaleString("pt-BR")}
                                        onChange={formik.handleChange}
                                        readOnly
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.updated && formik.touched.updated &&
                                <ErrorValidationMessage message={formik.errors.updated} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="cadastroId"
                                className={classNames({ 'p-error': isFormFieldValid('cadastroId') })}
                            >
                                Cód Cadastro
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="cadastroId"
                                        name="cadastroId"
                                        type="number"                                        
                                        className={classNames({ 'p-invalid': isFormFieldValid('cadastroId') })}
                                        value={formik.values.cadastroId}
                                        onChange={formik.handleChange}
                                        readOnly
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.cadastroId && formik.touched.cadastroId &&
                                <ErrorValidationMessage message={formik.errors.cadastroId} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="cadastro"
                                className={classNames({ 'p-error': isFormFieldValid('cadastro') })}
                            >
                                Cadastro
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="cadastro"
                                        name="cadastro"
                                        className={classNames({ 'p-invalid': isFormFieldValid('cadastro') })}
                                        value={formik.values.cadastro}
                                        onChange={formik.handleChange}
                                        readOnly
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.cadastro && formik.touched.cadastro &&
                                <ErrorValidationMessage message={formik.errors.cadastro} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="acao"
                                className={classNames({ 'p-error': isFormFieldValid('acao') })}
                            >
                                Ação
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="acao"
                                        name="acao"
                                        className={classNames({ 'p-invalid': isFormFieldValid('acao') })}
                                        value={formik.values.acao}
                                        onChange={formik.handleChange}
                                        readOnly
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.acao && formik.touched.acao &&
                                <ErrorValidationMessage message={formik.errors.acao} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="conteudo"
                                className={classNames({ 'p-error': isFormFieldValid('conteudo') })}
                            >
                                Conteúdo
                            </label>
                            {
                                !isLoading ?
                                    <InputTextarea 
                                        rows={10} 
                                        cols={30} 
                                        id="conteudo"
                                        name="conteudo"
                                        className={classNames({ 'p-invalid': isFormFieldValid('conteudo') })}
                                        value={formik.values.conteudo}
                                        onChange={formik.handleChange}
                                        readOnly
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.conteudo && formik.touched.conteudo &&
                                <ErrorValidationMessage message={formik.errors.conteudo} />
                            }
                        </div>

                    </div>
                </form>
            </div>
        </div>

    );
}