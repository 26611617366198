import React, { useState } from 'react';
import MenuMain from '../../MenuMain';
import { TabelaNotasAluno } from '../../components/TabelaNotasAluno';
import { AppFooter } from '../../AppFooter';

export default function LancarNotasPage(props) {
    const [idMatricula] = useState(props.match.params.id);

    return (
        <>
            <MenuMain >
                {
                        <TabelaNotasAluno
                            matricula={idMatricula}
                        />
                }
            </MenuMain>
            <AppFooter />
        </>
    );
}