import React from 'react';

import { TabelaInscricao } from '../../components/TabelaInscricao';
import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';

export default function InscricoesPage() {

  return (
    <>
      <MenuMain >
        <TabelaInscricao />
      </MenuMain>
      <AppFooter />
    </>
  );
}
