import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Logo } from '../../components/Logo';
import { ErrorValidationMessage } from '../../components/ErrorValidationMessage';
import { Toast } from 'primereact/toast';
// import '../../layout/sass/_login.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { BrCalendar } from '../../components/BrCalendar';
import { InputMask } from 'primereact/inputmask';
import { DialogAcessoAlunos } from '../../components/DialogAcessoAlunos';
import api from '../../api/api';

function AcessoAlunos() {

  const myToast = useRef();

  const showToast = (severityValue, summaryValue, detailValue) => {
    myToast.current.show({ severity: severityValue, summary: summaryValue, detail: detailValue });
  }

  
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const [visible, setVisible] = useState(false);  
  const [isSubmiting, setIsSubmiting] = useState(false)  
  const [notas, setNotas] = useState([]);
  const [faltas, setFaltas] = useState([]);
  const [codigo, setCodigo] = useState('');

  const schema = Yup.object().shape({
    cpf: Yup
      .string()
      .required("O CPF é obrigatório"),
    dataNascimento: Yup
      .date()
      .typeError("Uma data de nascimento deve ser inserida")
      .required("A data de nascimento é obrigatória"),
    recaptcha: Yup
      .string()
      .required("O Código é obrigatório")
      .when([],{
          is: () => formik.values.recaptcha == codigo,
          then: Yup.string().required("O Código é obrigatório"),
          otherwise: Yup.string().notRequired(),
      }),
  })

  const formik = useFormik({
    
    initialValues: {
      cpf: '',
      dataNascimento: '',
      recaptcha: ''
    },

    validationSchema: schema,

    onSubmit: async (data) => {
        setIsSubmiting(true)  


        try {  
          if(formik.values.recaptcha == codigo) {
            setVisible(true);
            const dataNota = await api.post("nota/searchNotaAlunos/DESC/id", data);
            const dataFalta = await api.post("falta/searchFaltaAlunos/DESC/id", data);
            setNotas(dataNota.data.content);
            setFaltas(dataFalta.data.content);
          } else {
            setVisible(false);
            myToast.current.show({ life: 2000, severity: 'error', summary: 'Código inválido!' });
          }
            
        }
        catch (error) {           
            myToast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao buscar aluno!', detail: error.toString() });
        }
       
    }
  })



 
  useEffect(() => {   
      var stringAleatoria = '';
      var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (var i = 0; i < 6; i++) {
          stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
      }
      
      setCodigo(stringAleatoria);
    
  }, [])

  // console.log(codigo)

  return (
    <Dialog
      visible={true}
      maximized={true}
      closable={false}
      showHeader={false}
      onHide={() => null}
    >
      <Toast ref={myToast} />

      <div id="login-container" className="p-d-flex p-jc-center p-ai-center p-flex-column">
        <Logo /> 
        <form className="p-fluid p-grid p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-3 p-justify-center p-mx-0 p-px-0" onSubmit={formik.handleSubmit}>
          <div className="p-formgrid p-grid p-col-12 p-mt-5 p-mb-4 p-justify-center p-mx-0 p-px-0">
            <div className="p-field p-col-12">
              <label htmlFor="cpf">CPF do aluno</label>
              <InputMask
                mask="99999999999"
                id="cpf"
                name="cpf"
                value={formik.values.cpf}
                className={classNames({ 'p-invalid': isFormFieldValid('cpf') })}
                onChange={formik.handleChange}
              />
              {formik.errors.cpf && formik.touched.cpf &&
                <ErrorValidationMessage message={formik.errors.cpf} />
              }
            </div>    

            <div className="p-field p-col-12">
              <label htmlFor="cpf">Data de Nascimento</label>
                <BrCalendar
                    id="dataNascimento"
                    name="dataNascimento"
                    value={formik.values.dataNascimento}
                    onChange={formik.handleChange}
                    className={classNames({ 'p-invalid': isFormFieldValid('dataNascimento') })}
                    yearRange={`1923:${new Date().getFullYear()}`}
                />
                {formik.errors.dataNascimento && formik.touched.dataNascimento &&
                    <ErrorValidationMessage message={formik.errors.dataNascimento} />
                }
            </div>  

            <div className="p-field p-col-12">
              {codigo && (<InputText
                      value={codigo}
                      disabled
                      style={{backgroundColor: "#EEE", textAlign: "center", color: "#000", fontWeight: "bold"}}
                    />)}
                  
            </div>    

            <div className="p-field p-col-12">
              <label
                htmlFor="recaptcha"
                className={classNames({ 'p-error': isFormFieldValid('recaptcha') })}
              >
                Digite o código acima
              </label>             
                    <InputText
                      id="recaptcha"
                      name="recaptcha"
                      className={classNames({ 'p-invalid': isFormFieldValid('recaptcha') })}
                      value={formik.values.recaptcha}
                      onChange={formik.handleChange}
                    />
                 
                  {formik.errors.recaptcha && formik.touched.recaptcha &&
                    <ErrorValidationMessage message={formik.errors.recaptcha} />
                  }           
              </div>

            



          </div>
          <div className="p-d-flex p-jc-center p-ai-center p-p-2">
            <Button type="submit" id="login-btn" label="Consultar" />
          </div>
        </form>


        <DialogAcessoAlunos
            visible={visible}
            setVisible={setVisible}
            nota={notas}
            falta={faltas}
        />
        

        <div className="p-mb-2 p-mt-5">
            <Link to="/">Clique aqui para voltar</Link>
        </div>
      </div>
    </Dialog>
  );
}
export default AcessoAlunos