import React, { useCallback, useEffect, useState } from 'react';

import { FormTurmas } from '../../components/FormTurmas';
import { ErrorRetrievingData } from '../../components/ErrorRetrievingData';
import MenuMain from '../../MenuMain';

import { AppFooter } from '../../AppFooter';

import api from '../../api/api';

export default function EditarTurmasPage(props) {

    const [turmas, setTurmas] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorGettingTurmas, setErrorGettingTurmas] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [idTurmas] = useState(props.match.params.id)

    const getTurmas = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await api.get(`turma/${idTurmas}`)
            let hora = response.data.hora.toString();
            let hora2 = hora.substring(0, hora.length - 3)
            setTurmas({
                'id': response.data.id,
                'ano': response.data.ano,
                'diaSemana': response.data.diaSemana,
                'diaSemanas': ['DOMINGO', 'SEGUNDA', 'TERÇA', 'QUARTA', 'QUINTA', 'SEXTA', 'SÁBADO'],
                'hora': hora2,
                'qtdeVagas': response.data.qtdeVagas,
                'sala': response.data.sala,
                'cursoId': response.data.cursoId,
                'perfilId': response.data.perfilId,
            })
            setErrorGettingTurmas(false)
        }
        catch (error) {
            setErrorMessage(error.toString())
            setErrorGettingTurmas(true)
        }

        setIsLoading(false);
    }, [idTurmas]);

    useEffect(() => {

        async function turmas() {
            await getTurmas();
        };
        turmas();

    }, [getTurmas])

    return (
        <>
            <MenuMain>
                {
                    errorGettingTurmas ?
                        <ErrorRetrievingData
                            errorMessage={errorMessage}
                            retrieveFunction={() => getTurmas(idTurmas)}
                            isLoading={isLoading}
                        />
                        :
                        <FormTurmas
                            defaultValues={turmas}
                            isEditing={true}
                            isLoading={isLoading}
                        />
                }
            </MenuMain>
            <AppFooter />
        </>
    );
}