import React, { useCallback, useEffect, useState } from 'react';

import { FormCursos } from '../../components/FormCursos';
import { ErrorRetrievingData } from '../../components/ErrorRetrievingData';
import MenuMain from '../../MenuMain';

import { AppFooter } from '../../AppFooter';

import api from '../../api/api';

export default function EditarCursosPage(props) {

    const [cursos, setCursos] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorGettingCursos, setErrorGettingCursos] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [idCursos] = useState(props.match.params.id)

    const getCursos = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await api.get(`curso/${idCursos}`)
            
            setCursos({
                'id': response.data.id,
                'nome': response.data.nome,
                'obs': response.data.obs,
                'sigla': response.data.sigla,
                'tipo': response.data.tipo,
                'tipos': ['TEORIA', 'INSTRUMENTO']
            })

            setErrorGettingCursos(false)
        }
        catch (error) {
            setErrorMessage(error.toString())
            setErrorGettingCursos(true)
        }

        setIsLoading(false);
    }, [idCursos]);

    useEffect(() => {

        async function cursos() {
            await getCursos();
        };
        cursos();

    }, [getCursos])

    return (
        <>
            <MenuMain >
                {
                    errorGettingCursos ?
                        <ErrorRetrievingData
                            errorMessage={errorMessage}
                            retrieveFunction={() => getCursos(idCursos)}
                            isLoading={isLoading}
                        />
                        :
                        <FormCursos
                            defaultValues={cursos}
                            isEditing={true}
                            isLoading={isLoading}
                        />
                }
            </MenuMain>
            <AppFooter />
        </>
    );
}