import React, { useState, createContext, useEffect } from 'react';

export const MensagemContext = createContext({
  mensagemLida: '',
  setMensagemLida: () => {}
});

const MensagemProvider = ({ children }) => {
  const [mensagemLida, setMensagemLida] = useState(localStorage.getItem('mensagemLida'));

  useEffect(() => {
    localStorage.setItem('mensagemLida', mensagemLida);
  }, [mensagemLida]);

  return (
    <MensagemContext.Provider value={{ mensagemLida, setMensagemLida }}>
      {children}
    </MensagemContext.Provider>
  );
};

export default MensagemProvider;