import React from 'react';

import UserMenuProvider from '../../contexts/UserMenuContext';
import PrimeiroAcessoProvider from '../../contexts/PrimeiroAcessoContext';

import { UserMenu } from '../../components/UserMenu';

import { PrimeiroAcesso } from '../../components/PrimeiroAcesso';

export default function HomeUserPage() {

    return (
        <PrimeiroAcessoProvider>
            <UserMenuProvider>
                <UserMenu />
                <PrimeiroAcesso />
            </UserMenuProvider>
        </PrimeiroAcessoProvider>
    );
}

