import React, { useCallback, useEffect, useState } from 'react';

import { FormFalta } from '../../components/FormFalta';
import { ErrorRetrievingData } from '../../components/ErrorRetrievingData';
import MenuMain from '../../MenuMain';

import { AppFooter } from '../../AppFooter';

import api from '../../api/api';

export default function EditarFaltaPage(props) {

    const [faltas, setFaltas] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorGettingFaltas, setErrorGettingFaltas] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [idFalta] = useState(props.match.params.id)

    const getFaltas = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await api.get(`falta/${idFalta}`)
            
            setFaltas({
                'id': response.data.id,
                'matriculaId': response.data.matriculaId,
                'alunoNome': response.data.matriculaAlunoNome,
                'obs': response.data.obs,
                'dataOcorrencia': response.data.dataOcorrencia
            })

            setErrorGettingFaltas(false)
        }
        catch (error) {
            setErrorMessage(error.toString())
            setErrorGettingFaltas(true)
        }

        setIsLoading(false);
    }, [idFalta]);

    useEffect(() => {

        async function faltas() {
            await getFaltas();
        };
        faltas();

    }, [getFaltas])

    return (
        <>
            <MenuMain >
                {
                    errorGettingFaltas ?
                        <ErrorRetrievingData
                            errorMessage={errorMessage}
                            retrieveFunction={() => getFaltas(idFalta)}
                            isLoading={isLoading}
                        />
                        :
                        <FormFalta
                            defaultValues={faltas}
                            isEditing={true}
                            isLoading={isLoading}
                        />
                }
            </MenuMain>
            <AppFooter />
        </>
    );
}