import React, { useState, createContext, useEffect } from 'react';

export const FilterContext = createContext({
  filter: '',
  setFilter: () => {}
});

const FilterProvider = ({ children }) => {
  const [filter, setFilter] = useState(localStorage.getItem('filter'));

  useEffect(() => {
    localStorage.setItem('filter', filter);
  }, [filter]);

  return (
    <FilterContext.Provider value={{ filter, setFilter }}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;