import React, { useCallback, useEffect, useState } from 'react';

import { FormLicenca } from '../../components/FormLicenca';
import { ErrorRetrievingData } from '../../components/ErrorRetrievingData';
import MenuMain from '../../MenuMain';

import { AppFooter } from '../../AppFooter';

import api from '../../api/api';

export default function EditarLicencaPage(props) {

    const [licencas, setLicencas] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorGettingLicencas, setErrorGettingLicencas] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [idLicenca] = useState(props.match.params.id)


    const getLicencas = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await api.get(`licenca/${idLicenca}`)
            
            setLicencas({
                'id': response.data.id,
                'matriculaId': response.data.matriculaId,
                'obs': response.data.obs,
                'dataInicio': response.data.dataInicio,
                'dataFim': response.data.dataFim,
                'tipo': response.data.tipo,
                'tipos': ['MEDICA','ACOMPANHANTE','VIAGEM','OUTRAS']
            })

            setErrorGettingLicencas(false)
        }
        catch (error) {
            setErrorMessage(error.toString())
            setErrorGettingLicencas(true)
        }

        setIsLoading(false);
    }, [idLicenca]);

    useEffect(() => {

        async function licencas() {
            await getLicencas();
        };
        licencas();

    }, [getLicencas])

    return (
        <>
            <MenuMain >
                {
                    errorGettingLicencas ?
                        <ErrorRetrievingData
                            errorMessage={errorMessage}
                            retrieveFunction={() => getLicencas(idLicenca)}
                            isLoading={isLoading}
                        />
                        :
                        <FormLicenca
                            defaultValues={licencas}
                            isEditing={true}
                            isLoading={isLoading}
                        />
                }
            </MenuMain>
            <AppFooter />
        </>
    );
}