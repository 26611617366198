import React, { useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'

export const TabelaAdicionarNotas = ({ alunos, setAlunos, avaliacao }) => {
    const toast = useRef(null);
    const confirmationToast = useRef(null)

    const codigoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const matriculaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Nome do Aluno</span>
                    <span className="table-data">{rowData.matriculaNome}</span>
                </p>
            </React.Fragment>
        );
    }

    useEffect(() => {

    }, [alunos]);

    const priceEditor = (options) => {

        if(avaliacao < options.rowData.notaAluno){
            return <InputNumber value={avaliacao}  mode="decimal" minFractionDigits={2} onValueChange={((e) => {handleEdit(options.rowData, 0.00)})} currency="BRL" locale="pt-BR" />
        } else {
            return <InputNumber value={options.rowData.notaAluno}  mode="decimal" minFractionDigits={2} onValueChange={((e) => {handleEdit(options.rowData, e.value)})} currency="BRL" locale="pt-BR" />
        }
        
    }

    const notaAlunoBodyTemplate = (rowData) => {
        
        if(avaliacao < rowData.notaAluno) {
            return new Intl.NumberFormat('pt-BR', { currency: 'BRL' }, { minimumFractionDigits: 2}).format(rowData.notaAluno === undefined ? 0.00 : 0.00);
        } else {
            return new Intl.NumberFormat('pt-BR', { currency: 'BRL' }, { minimumFractionDigits: 2}).format(rowData.notaAluno === undefined ? 0.00 : rowData.notaAluno);
        }
     }


    const handleEdit = (data, value) => {

        data.notaAluno = value;
            
        try {

            if(avaliacao < value) {
                toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao alterar!', detail: "Valor total menor que o valor da nota do aluno..." });                
            } 

        }
        catch (error) {

            toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao alterar!', detail: "Erro ao alterar..." });

        }

    }

    useEffect(() => {

    }, [alunos]);

    const onRowEditComplete1 = (e) => {
        let notas2 = [...alunos];
        let { newData, index } = e;

        notas2[index] = newData;

        
        setAlunos(notas2);
    }

    return (

        <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />

            <Toast ref={confirmationToast} position="bottom-center" />

            <div className="card p-col-12 datatable-responsive-demo">

                <div className="p-fluid">

                    <DataTable
                        editMode="row"
                        onRowEditComplete={onRowEditComplete1}
                        responsiveLayout="scroll"
                        value={alunos}
                        className="p-datatable-responsive-demo"
                        emptyMessage="Nenhuma nota encontrada"
                        dataKey="id"
                        paginator
                        rows={40}
                        currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} nota"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[20,30,40]}
                        sortField="nome"
                        sortOrder={1}
                        removableSort
                    >

                        <Column style={{ width: '12%' }}
                            field="matriculaId"
                            header="Matrícula"
                            sortable
                            body={codigoBodyTemplate}

                        />

                        <Column
                            field="matriculaNome"
                            header="Nome do Aluno"
                            sortable
                            body={matriculaBodyTemplate}

                        />

                        <Column
                            field="notaAluno"
                            header="Nota Aluno"
                            body={notaAlunoBodyTemplate}
                            editor={(options) => priceEditor(options)}
                            style={{ width: '20%' }}>

                        </Column>

                        <Column
                            rowEditor
                            headerStyle={{ width: '10%', minWidth: '8rem' }}
                            bodyStyle={{ textAlign: 'center' }}>
                        </Column>

                    </DataTable>
                </div>
            </div>
        </div>
    );
}
