import React, { useRef, useEffect, useState, useCallback } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';
import { Button } from 'primereact/button';
import { TabelaFaltasMatricula } from './TabelaFaltasMatricula';
import { TabelaNotasMatricula } from './TabelaNotasMatricula';

export const TabelaMatriculasPerfil = ({ matriculasPerfil }) => {
    const toast = useRef(null);
    const [matriculas, setMatriculas] = useState([]);
    const confirmationToast = useRef(null)
    const [visible, setVisible] = useState(false);
    const [faltas, setFaltas] = useState([]);
    const [visibleNotas, setVisibleNotas] = useState(false);
    const [notas, setNotas] = useState([]);

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Matriculas</h2>
            </div>
        </div>

    );

    const codBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const nomeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Nome</span>
                    <span className="table-data">{rowData.alunoNome}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataMatriculaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Data Matrícula</span>
                    <span className="table-data">{new Date(rowData.dataMatricula).toLocaleDateString()}</span>
                </p>
            </React.Fragment>
        );
    }

    const conclusaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Conclusão</span>
                    {rowData.conclusao == null
                        ? <span className="table-data">NÃO CONCLUÍDO</span>
                        : <span className="table-data">{new Date(rowData.conclusao).toLocaleDateString()}</span>}
                </p>
            </React.Fragment>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Status</span>
                    <span className="table-data">{rowData.status}</span>
                </p>
            </React.Fragment>
        );
    }

    const salaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Sala</span>
                    <span className="table-data">{rowData.turmaSala}</span>
                </p>
            </React.Fragment>
        );
    }

    const turmaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Sala</span>
                    <span className="table-data">{"Turma: " + rowData.turmaAno + "  " + rowData.turmaCursoNome + " - " + rowData.turmaDiaSemana + "  " + rowData.turmaHora}</span>
                </p>
            </React.Fragment>
        );
    }

    const notaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Nota</span>
                    {rowData.notaFinal == null
                        ? <span className="table-data">NÃO INFORMADO</span>
                        : <span className="table-data">{<Button
                            label={rowData.notaFinal.toLocaleString('pt-BR')}
                            onClick={() => handleAvaliacaoClick(rowData)}
                        />}</span>}
                </p>
            </React.Fragment>
        );
    }

    const faltasBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Quantidade Faltas</span>
                    <Button
                        label={rowData.faltas.length.toString()}
                        onClick={() => handleFaltaClick(rowData)}
                    />
                </p>
            </React.Fragment>
        );
    }

    const handleFaltaClick = (rowData) => {
        if (rowData.faltas.length > 0) {
            setVisible(true);
            setFaltas(rowData.faltas);
        }
        else {
            toast.current.show({ severity: 'error', summary: 'Matrícula não contém faltas!' });

        }
    };

    const handleAvaliacaoClick = (rowData) => {
        if (rowData.notas.length > 0) {
            setVisibleNotas(true);
            setNotas(rowData.notas);
        }
        else {
            toast.current.show({ severity: 'error', summary: 'Matrícula não contém avaliações!' });

        }
    };

    const getMatriculas = useCallback(async () => {
        try {
            if (matriculasPerfil !== undefined) {
                const response = await api.get(`matricula/perfil/${matriculasPerfil}`);
                setMatriculas(response.data)
            }
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter matriculas no perfil', detail: error.toString() });
        }
    }, [matriculasPerfil]);

    useEffect(() => {

        async function matriculas() {
            await getMatriculas();
        };
        matriculas();
    }, [getMatriculas])

    return (
        <>

            <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

                <Toast ref={toast} position="bottom-right" />

                <Toast ref={confirmationToast} position="bottom-center" />

                <div className="card p-col-12 datatable-responsive-demo">

                    <div className="p-fluid">

                        <DataTable style={{ width: '100%' }}
                            lazy="true"
                            onPage={getMatriculas}
                            header={header}
                            value={matriculas}
                            filterDisplay="row"
                            className="p-datatable-responsive-demo"
                            emptyMessage="Nenhuma matrícula encontrada"
                            dataKey="id"
                            rows={50}
                        >

                            <Column
                                field="id"
                                header="Código"
                                body={codBodyTemplate}

                            />

                            <Column
                                field="aluno.nome"
                                header="Nome"
                                body={nomeBodyTemplate}

                            />

                            <Column
                                field="dataMatricula"
                                header="Data Matrícula"
                                body={dataMatriculaBodyTemplate}

                            />

                            <Column
                                field="conclusao"
                                header="Conclusão"
                                body={conclusaoBodyTemplate}

                            />
                            <Column
                                field="status"
                                header="Status"
                                body={statusBodyTemplate}
                            />

                            <Column
                                field="sala"
                                header="Sala"
                                body={salaBodyTemplate}

                            />

                            <Column
                                field="turma"
                                header="Turma"
                                body={turmaBodyTemplate}

                            />

                            <Column
                                field="nota"
                                header="Nota"
                                body={notaBodyTemplate}

                            />
                            <Column
                                field="faltas"
                                header="Quantidade Faltas"
                                body={faltasBodyTemplate}

                            />
                        </DataTable>
                        {visible ?

                            <TabelaFaltasMatricula
                                faltas={faltas}
                                visible={visible}
                                setVisible={setVisible}

                            />
                            :
                            <></>
                        }
                        {visibleNotas ?

                            <TabelaNotasMatricula
                                notas={notas}
                                visible={visibleNotas}
                                setVisible={setVisibleNotas}

                            />
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>

        </>
    );
}