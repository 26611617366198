import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';
import { DashBoard } from '../../components/DashBoard';

export default function HomeAdminPage() {

    return (<div>
        <MenuMain >
        <DashBoard />
        
         {/* "Authorization: Bearer eyJrIjoiRnpsc2Vja1U3WFgwMkFTakNaMkFTR1MyREY4aTJGVGYiLCJuIjoiY2FkZXNjb2xhIiwiaWQiOjF9"
        <div className="esconde">
            <iframe className="p-col-12 p-md-12" src="http://localhost:3000/d/QYW84DGnk/dashboard?orgId=1" width="1200" height="1200" frameborder="0"></iframe>
        </div> */}
        </MenuMain>
        <AppFooter />
    </div>
    );
}
