import React, { useRef, useEffect, useState, useCallback } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';

export const TabelaFilaInstrumentos = ({ }) => {
    const toast = useRef(null);
    const confirmationToast = useRef(null)
    const [cursoTeoria, setCursoTeoria] = useState(null);
    const [cursosTeoria, setCursosTeoria] = useState([]);
    const [curso, setCurso] = useState(null);
    const [cursos, setCursos] = useState([]);
    const [filaInstrumentos, setFilaInstrumentos] = useState([]);
    const [ano, setAno] = useState(new Date().getFullYear());
    const [perfil, setPerfil] = useState([]);
    const [showPerfil, setShowPerfil] = useState(false);

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Fila Instrumentos</h2>
                <h2 className="p-my-0">Total: {filaInstrumentos.length}</h2>
            </div>
        </div>

    );

    const instrumentoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="table-data">{rowData.instrumentoNome}</span>
                </p>
            </React.Fragment>
        );
    }

    const alunoBodyTemplate = (rowData) => {

        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="table-data">{rowData.alunoNome}</span>
                </p>
            </React.Fragment>
        );
    }

    const cursoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="table-data">{rowData.cursoNome}</span>
                </p>
            </React.Fragment>
        );
    }

    const turmaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="table-data">{rowData.cursoNome + " - " + rowData.diaSemana + " - " + rowData.hora}</span>
                </p>
            </React.Fragment>
        );
    }

    const perfilBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <Button icon="pi pi-info" className="p-button-rounded p-button-secondary" onClick={() => handlePerfilClick(rowData.perfil)}
                        style={{ "left": "5px", "bottom": "5px", "height": "20px", "width": "20px" }} />
                </p>
            </React.Fragment>
        );
    }

    const getCursos = useCallback(async () => {
        try {
            const response = await api.get(`curso/instrumento`);
            setCursos(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter cursos', detail: error.toString() });
        }
    }, []);

    const getCursosTeoria = useCallback(async () => {
        try {
            const response = await api.get(`curso/teoria`);
            setCursosTeoria(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter cursos', detail: error.toString() });
        }
    }, []);

    const getFila = async () => {
        try {
            const response = await api.get(`matricula/filaInstrumento/${ano}`);
            setFilaInstrumentos(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter alunos fila de alunos aguardando instrumento', detail: error.toString() });
        }
    };

    useEffect(() => {

        async function filaInstrumento() {
            await getCursos();
            await getCursosTeoria();
            await getFila();
        };
        filaInstrumento();

    }, [])

    useEffect(() => {

        async function filaInstrumento() {
            const pesquisa = {
                "cursoId": cursoTeoria,
                "instrumentoId": curso,
                "ano": ano
            }
            const response = await api.post(`matricula/filaInstrumento`, pesquisa);
            setFilaInstrumentos(response.data);
        };
        filaInstrumento();

    }, [curso, cursoTeoria, ano])

    const handlePerfilClick = (perfil) => {
        setShowPerfil(true);
        setPerfil(perfil);
    }

    function formatCpf(data) {

        const cpf = data != null ? data.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4") : data;

        return cpf;
    }

    return (
        <>
            <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">
                <Dialog className='p-scrollpanel-bar-y' header="" onHide={() => setShowPerfil(false)} visible={showPerfil} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '50vw', heigth: '5vw' }}
                >
                    <div>
                        <div className="p-d-flex p-ai-center p-flex-column header">
                            <h2>Perfil</h2>
                        </div>
                        <br></br>
                        <ol>
                            <div className="p-grid"  >
                                <div className="p-col-12"><b>Nome:</b> {perfil.nome}</div>
                                <div className="p-col-12"><b>CPF:</b> {formatCpf(perfil.cpf)}</div>
                                <div className="p-col-12"><b>Email:</b> {perfil.email}</div>
                                <div className="p-col-12"><b>Data de Nascimento:</b> {new Date(perfil.dataNascimento).toLocaleDateString()}</div>
                                <div className="p-col-12"><b>Deficiência:</b> {perfil.deficiencia}</div>
                                <div className="p-col-12"><b>Responsavel:</b> {perfil.responsavel}</div>
                                <div className="p-col-12"><b>Telefone:</b> {perfil.telefone}</div>
                                <div className="p-col-12"><b>Celular:</b> {perfil.celular}</div>
                            </div>
                        </ol>
                    </div>
                </Dialog>
                <Toast ref={toast} position="bottom-right" />

                <Toast ref={confirmationToast} position="bottom-center" />

                <div className="card p-col-12 datatable-responsive-demo">

                    <div className="p-fluid">

                        <DataTable style={{ width: '100%' }}
                            lazy="true"
                            header={header}
                            value={filaInstrumentos}
                            filterDisplay="row"
                            className="p-datatable-responsive-demo"
                            emptyMessage="Nenhum instrumento encontrado"
                            dataKey="id"
                            rows={50}
                        >

                            <Column
                                field="instrumentoNome"
                                header="Instrumento"
                                body={instrumentoBodyTemplate}
                                filter
                                filterElement={
                                    <Dropdown
                                        value={curso}
                                        options={cursos}
                                        optionLabel="nome"
                                        optionValue="id"
                                        emptyMessage="Nenhum curso encontrado"
                                        emptyFilterMessage={"Nenhum curso encontrado"}
                                        filter={true}
                                        onChange={(e) => setCurso(e.value)}
                                        placeholder="Selecione o curso"
                                        showClear={true}
                                        style={{ marginTop: "16px" }}
                                    />
                                }
                            />

                            <Column
                                field="alunoNome"
                                header="Nome"
                                body={alunoBodyTemplate}

                            />

                            <Column
                                field="curso"
                                header="Curso"
                                body={cursoBodyTemplate}
                                filter
                                filterElement={
                                    <Dropdown
                                        value={cursoTeoria}
                                        options={cursosTeoria}
                                        optionLabel="nome"
                                        optionValue="id"
                                        emptyMessage="Nenhum curso encontrado"
                                        emptyFilterMessage={"Nenhum curso encontrado"}
                                        filter={true}
                                        onChange={(e) => setCursoTeoria(e.value)}
                                        placeholder="Selecione o curso"
                                        showClear={true}
                                        style={{ marginTop: "16px" }}
                                    />
                                }
                            />

                            <Column
                                field="turma"
                                header="Turma"
                                body={turmaBodyTemplate}

                            />

                            <Column
                                field="perfil"
                                header="Perfil"
                                body={perfilBodyTemplate}
                                filter
                                filterElement={
                                    <InputNumber inputId="minmax-buttons" value={ano} onValueChange={(e) => setAno(e.value)} mode="decimal" showButtons min={2022} max={2050} useGrouping={false}/>
                                }
                            />

                        </DataTable>

                    </div>
                </div>
            </div>
        </>
    );
}