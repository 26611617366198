import React from 'react';
import MenuMain from '../../MenuMain';
import { FormMensagens } from '../../components/FormMensagens';
import { AppFooter } from '../../AppFooter';


export default function CadastrarMensagensPage() {

  const defaultValues = {
    'emissorId': '',
    'receptorId': '',
    'mensagem': '',
    'mensagemLida': false,
    'mensagemRespondida': false,
    'mensagemAnterior': '',
  }

  return (
    <div>
      <MenuMain>
         <FormMensagens
          defaultValues={defaultValues}
        />
      </MenuMain>
      <AppFooter />
    </div>
  );
}
