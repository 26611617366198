import React from 'react';
import MenuMain from '../../MenuMain';
import { FormCursos } from '../../components/FormCursos';
import { AppFooter } from '../../AppFooter';


export default function CadastrarCursosPage() {

  const defaultValues = {
    'nome': '',
    'obs': '',
    'sigla': '',
    'tipo': '',
    'tipos': ['TEORIA', 'INSTRUMENTO']
  }

  return (
    <div>
      <MenuMain>
         <FormCursos
          defaultValues={defaultValues}
        />
      </MenuMain>
      <AppFooter />
    </div>
  );
}
