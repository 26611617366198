import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { AppMenu } from './AppMenu';
import { AppProfile } from './AppProfile';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './layout/flags/flags.css';
import './layout/layout.scss';
import { AppTopbar } from './AppTopbar';
import api from './api/api';
import {MensagemContext} from './contexts/MensagemContext';
import { AuthContext } from './contexts/AuthContext';

const MenuMain = ({children}) => {
    const toast = useRef(null);
    const [layoutMode] = useState('static');
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const sidebar = useRef();
    const { user } = useContext(AuthContext);
    const history = useHistory();
    const [mensagemNaoLida, setMensagemNaoLida] = useState(0);
    const [menu, setMenu] = useState(
        user.perfilUser[0] !== 'user-instrutor' ?
        [{ label: 'Avaliação', icon: 'pi pi-id-card', to: '/avaliacao'},
        { label: 'Faltas', icon: 'pi pi-calendar', to: '/falta' },
        { label: 'Licenças', icon: 'pi pi-folder-open', to: '/licenca' },
        { label: 'Campanhas', icon: 'pi pi-calendar', to: '/campanhas' },
        { label: 'Inscrições', icon: 'pi pi-folder-open', to: '/inscricoes' },
        { label: 'Perfis', icon: 'pi pi-id-card', to: '/perfis'},
        { label: 'Cursos', icon: 'pi pi-folder-open', to: '/cursos' },
        { label: 'Turmas', icon: 'pi pi-folder-open', to: '/turmas' },
        { label: 'Matrículas', icon: 'pi pi-id-card', to: '/matriculas'},
        { label: 'Periodo Matricula', icon: 'pi pi-calendar', to: '/periodoMatricula' },
        { label: 'Mensagens', icon: 'pi pi-id-card', icon2: 'pi pi-envelope', contador: `${mensagemNaoLida}`, to: '/mensagens' },
        { label: 'Logs', icon: 'pi pi-id-card', to: '/logs'},]
        :
        [{ label: 'Avaliação', icon: 'pi pi-id-card', to: '/avaliacao'},
        { label: 'Faltas', icon: 'pi pi-calendar', to: '/falta' },
        { label: 'Licenças', icon: 'pi pi-folder-open', to: '/licenca' },
        { label: 'Mensagens', icon: 'pi pi-id-card', icon2: 'pi pi-envelope', contador: `${mensagemNaoLida}`, to: '/mensagens' },]
        );
    const { mensagemLida } = useContext(MensagemContext);
    let menuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, 'body-overflow-hidden');
        }
        else {
            removeClass(document.body, 'body-overflow-hidden');
        }
    }, [mobileMenuActive]);

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
        menuClick = false;
    }

    const onToggleMenu = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                setOverlayMenuActive(prevState => !prevState);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive(prevState => !prevState);
            }
        }
        else {
            setMobileMenuActive(prevState => !prevState);
        }
        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }

    const getMensagemNaoLida = useCallback(async () => {
        try {
            const response = await api.get(`mensagens/mensagemNaoLida/${user.email}`);
            setMensagemNaoLida(response.data.length);
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter mensagens', detail: error.toString() });
        }
    }, [mensagemLida]);

    const atualizaMenu = useCallback(() => {
        try {
            if (user.perfilUser[0] !== 'user-instrutor')
            {
                setMenu([                 
                    { label: 'Avaliação', icon: 'pi pi-id-card', to: '/avaliacao'},
                    { label: 'Faltas', icon: 'pi pi-calendar', to: '/falta' },
                    { label: 'Licenças', icon: 'pi pi-folder-open', to: '/licenca' },
                    { label: 'Campanhas', icon: 'pi pi-calendar', to: '/campanhas' },
                    { label: 'Inscrições', icon: 'pi pi-folder-open', to: '/inscricoes' },
                    { label: 'Perfis', icon: 'pi pi-id-card', to: '/perfis'},
                    { label: 'Histórico Alunos', icon: 'pi pi-id-card', to: '/historicoPerfil'},
                    { label: 'Fila Instrumentos', icon: 'pi pi-id-card', to: '/filaInstrumentos'},
                    { label: 'Cursos', icon: 'pi pi-folder-open', to: '/cursos' },
                    { label: 'Turmas', icon: 'pi pi-folder-open', to: '/turmas' },
                    { label: 'Matrículas', icon: 'pi pi-id-card', to: '/matriculas'},
                    { label: 'Periodo Matricula', icon: 'pi pi-calendar', to: '/periodoMatricula' },
                    { label: 'Mensagens', icon: 'pi pi-id-card', icon2: 'pi pi-envelope', contador: `${mensagemNaoLida}`, to: '/mensagens' },
                    { label: 'Logs', icon: 'pi pi-id-card', to: '/logs'},]);
            }
            else
            {
                setMenu([                 
                    { label: 'Avaliação', icon: 'pi pi-id-card', to: '/avaliacao'},
                    { label: 'Faltas', icon: 'pi pi-calendar', to: '/falta' },
                    { label: 'Licenças', icon: 'pi pi-folder-open', to: '/licenca' },
                    { label: 'Mensagens', icon: 'pi pi-id-card', icon2: 'pi pi-envelope', contador: `${mensagemNaoLida}`, to: '/mensagens' },]);
            }
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao atualizar menu', detail: error.toString() });
        }
    }, [mensagemNaoLida]);

    useEffect(() => {
        
        async function menu() {
            await getMensagemNaoLida();
            atualizaMenu();
        };
        menu();

    }, [getMensagemNaoLida,atualizaMenu])

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const isDesktop = () => {
        return window.innerWidth > 1024;
    }

    const isSidebarVisible = () => {
        if (isDesktop()) {
            if (layoutMode === 'static')
                return !staticMenuInactive;
            else if (layoutMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        }

        return true;
    }


    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
    });

    const sidebarClassName = classNames('layout-sidebar', 'layout-sidebar-light');

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <AppTopbar onToggleMenu={onToggleMenu} />
            <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={isSidebarVisible()} unmountOnExit>
                <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick}>
                    <div className="layout-Logo" style={{ cursor: 'pointer', padding: '10px 10px'}} onClick={() => history.push('/')}>
                        <img alt="Logo" src="https://conservatorio.patosdeminas.mg.gov.br/static/media/logo_conservatorio.b0757b22.jpg" width="100%" height="auto" style={{ maxWidth: '250px' }}/>
                        
                    </div>
                    <AppProfile />
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
                    
                    
                </div>
                
            </CSSTransition>

            <div className="layout-main">
                <div className="p-col-12"  >                    
                    {children}
                </div>
            </div>

        </div>
    );

}

export default MenuMain;
