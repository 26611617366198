import React, { useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorValidationMessage } from './ErrorValidationMessage';

import '../layout/sass/_form.scss';

export const FormInscricao = ({ FormCampanha,FormPerfil,submitAlterar,submitAdicionar,defaultValues,isEditing,isLoading,visible,setVisible }) => {
    
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)
    const schema = Yup.object().shape({
        campanhaId: Yup
            .number()
            .required("A campanha é obrigatória"),
        perfilId: Yup
            .number()
            .required("O perfil é obrigatório"),
        sorteado: Yup
            .boolean(),      
    })

    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)      
            try {
                if (!isEditing) {
                    data.dataInscricao = new Date();
                    
                    await submitAdicionar(data);
                    formik.resetForm();
                    setTimeout(() => {                       
        
                    }, 2000)
                    
                }
                else {
                    data.dataInscricao = new Date();
                    await submitAlterar(data);
                    formik.resetForm();
                    setTimeout(() => {
        
                    }, 2000)
                    
                }
            }
            catch (error) {
                return
            }

            setIsSubmiting(false)
        }
        
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    return (
        
        <Dialog
            className='p-scrollpanel-bar-y'
            header={isEditing ? 'Alterar Inscrição' : 'Adicionar Inscrição'}
            onHide={() => { setVisible(false); formik.resetForm() }}
            visible={visible}
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            style={{ width: '50vw', heigth: '5vw' }}
        >

        <Toast ref={toast} position="bottom-right" />
       
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <div className="p-col-12">
                <form onSubmit={formik.handleSubmit}>

                    <div className="p-formgrid p-grid p-fluid p-mx-0">
                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="campanhaId"
                                className={classNames({ 'p-invalid': isFormFieldValid('campanhaId') })}
                            >
                                Campanha
                            </label>
                            {
                                !isLoading ?
                                <Dropdown
                                    id="campanhaId"
                                    nome="campanhaId"
                                    value={formik.values.campanhaId}
                                    options={FormCampanha}
                                    optionLabel="nome"
                                    optionValue="id"
                                    onChange={(e) => formik.setFieldValue("campanhaId",e.value)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('campanhaId') })}
                                    placeholder="Selecione uma campanha"
                                    showClear={true} 
                                />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.campanhaId && formik.touched.campanhaId &&
                                <ErrorValidationMessage message={formik.errors.campanhaId} />
                            }
                        </div>
                    </div>

                    <div className="p-formgrid p-grid p-fluid p-mx-0">
                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="perfilId"
                                className={classNames({ 'p-invalid': isFormFieldValid('perfilId') })}
                            >
                                Perfil
                            </label>
                            {
                                !isLoading ?
                                <Dropdown
                                    id="perfilId"
                                    nome="perfilId"
                                    value={formik.values.perfilId}
                                    options={FormPerfil}
                                    emptyMessage="Nenhum perfil encontrado"
                                    emptyFilterMessage={"Nenhum perfil encontrado"}
                                    filter={true}
                                    onChange={(e) => formik.setFieldValue("perfilId", e.value)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('perfilId') })}
                                    placeholder="Selecione o CPF do Perfil"
                                    showClear={true} 
                                />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.perfilId && formik.touched.perfilId &&
                                <ErrorValidationMessage message={formik.errors.perfilId} />
                            }
                        </div>
                    </div>

                    <div className="p-field p-col-12">
                        <label
                            htmlFor="sorteado"
                            className={classNames({ 'p-error': isFormFieldValid('sorteado') })}
                            style={{ textAlign: 'justify', textJustify: 'distribute' }}
                        >
                            <Checkbox
                                id="sorteado"
                                name="sorteado"
                                checked={formik.values.sorteado}
                                onChange={() => formik.setFieldValue("sorteado", !formik.values.sorteado)}
                                className={classNames({ 'p-invalid': isFormFieldValid('sorteado') })}
                                style={{ marginRight: '5px' }}
                            />
                                Sorteado
                        </label>
                        {formik.errors.sorteado && formik.touched.sorteado &&
                            <ErrorValidationMessage message={formik.errors.sorteado} />
                        }
                    </div>

                    <div className="p-d-flex p-jc-end p-mr-1">
                        {
                            !isLoading ?
                                <Button
                                    label={isEditing ? 'Atualizar' : 'Salvar'}
                                    type="submit"
                                    iconPos="right"
                                    loading={isSubmiting}
                                />
                                :
                                <Skeleton width="82.16px" height="35px" />

                        }
                    </div>
                </form>
            </div>
        </div>
        </Dialog>
    
    );
}