import React, { useRef, useState, useEffect, useCallback } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { InputMask } from 'primereact/inputmask';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ErrorValidationMessage } from './ErrorValidationMessage';
import api from '../api/api';
import '../layout/sass/_form.scss';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';

export const FormTurmaAgenda = ({ isEditing, isLoading, defaultValues, visible, setVisible, setAtualizarAgenda }) => {
    
    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [curso, setCurso] = useState("");
    const [instrutor, setInstrutor] = useState("");
    const [value, setValue] = useState();
    const schema = Yup.object().shape({
        ano: Yup
            .number()
            .required("O ano é obrigatório"),
        diaSemana: Yup
            .string()
            .required("o dia da semana é obrigatório"),
        qtdeVagas: Yup
            .number()
            .required("As vagas são obrigatórias"),  
        sala: Yup
            .string()
            .required("A sala é obrigatória"),
        cursoId: Yup
            .number()
            .required("O curso é obrigatório"),
        perfilId: Yup
            .number()
            .required("O instrutor é obrigatório")
    })

    const formik = useFormik({
        
        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)                        
           
            try {
                    await handleEditar(data);
                    formik.resetForm()
                    setTimeout(() => {
                        setVisible(false);
                        setAtualizarAgenda(Math.random())

                    }, 2000)
            }
            catch (error) {
                if (!isEditing)
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao cadastrar turma!', detail: error.toString() });
                else
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar turma!', detail: error.toString() });

            }

            setIsSubmiting(false)
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getCursos = useCallback(async () => {
        try {
            const response = await api.get(`curso/cursoAll`);
            setCurso(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter cursos', detail: error.toString() });
        }
    }, []);

    const getInstrutores = useCallback(async () => {
        try {
            const response = await api.get(`perfil/instrutor`);
            setInstrutor(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter instrutores', detail: error.toString() });
        }
    }, []);

    useEffect(() => {

        async function turmas() {
            await getCursos();
            await getInstrutores();
        };
        turmas();

    }, [getCursos,getInstrutores]);

    async function handleEditar(data) {
        try {
            await api.put("turma", data)
            toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `Turma atualizada com sucesso` });
        }
        catch (error) {
            toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar turma!', detail: error.toString() });
        }
    }

    return (
        <>  
        <Toast ref={toast} position="bottom-right" />
        <Dialog
                className='p-scrollpanel-bar-y'
                header='Editar Turma'
                onHide={() => { setVisible(false); formik.resetForm() }}
                visible={visible}
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ width: '50vw', heigth: '5vw' }}
        >
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            
            <div className="p-col-12">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-fluid p-mx-0">
                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="ano"
                                className={classNames({ 'p-error': isFormFieldValid('ano') })}
                            >
                                Ano
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="ano"
                                        name="ano"
                                        className={classNames({ 'p-invalid': isFormFieldValid('ano') })}
                                        value={formik.values.ano}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.ano && formik.touched.ano &&
                                <ErrorValidationMessage message={formik.errors.ano} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="diaSemana"
                                className={classNames({ 'p-error': isFormFieldValid('diaSemana') })}
                            >
                                Dia da Semana
                            </label>
                            {
                                !isLoading ?
                                    <Dropdown
                                        id="diaSemana"
                                        name="diaSemana"
                                        value={formik.values.diaSemana}
                                        options={formik.values.diaSemanas}
                                        emptyMessage="Nenhum dia encontrado"
                                        emptyFilterMessage={"Nenhum dia encontrado"}
                                        filter={true}
                                        onChange={(e) => formik.setFieldValue("diaSemana", e.value)}
                                        className={classNames({ 'p-invalid': isFormFieldValid('diaSemana') })}
                                        placeholder="Selecione o dia da Semana"
                                        showClear={true} 
                                    />  
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.diaSemana && formik.touched.diaSemana &&
                                <ErrorValidationMessage message={formik.errors.diaSemana} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4 p-md-4">
                                <label
                                    htmlFor="hora"
                                    className={classNames({ 'p-error': isFormFieldValid('hora') })}
                                >
                                    Hora
                                </label>
                                <InputMask 
                                    mask="99:99" 
                                    value={value} 
                                    onChange={(e) => setValue(e.value)} 
                                    placeholder={formik.values.hora}
                                    onComplete={(e) => formik.setFieldValue("hora", e.value)}>
                                </InputMask>
                                {formik.errors.hora && formik.touched.hora &&
                                    <ErrorValidationMessage message={formik.errors.hora} />
                                }
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="qtdeVagas"
                                className={classNames({ 'p-error': isFormFieldValid('qtdeVagas') })}
                            >
                                Quantidade de Vagas
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="qtdeVagas"
                                        name="qtdeVagas"
                                        className={classNames({ 'p-invalid': isFormFieldValid('qtdeVagas') })}
                                        value={formik.values.qtdeVagas}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.qtdeVagas && formik.touched.qtdeVagas &&
                                <ErrorValidationMessage message={formik.errors.qtdeVagas} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="sala"
                                className={classNames({ 'p-error': isFormFieldValid('sala') })}
                            >
                                Sala
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="sala"
                                        name="sala"
                                        className={classNames({ 'p-invalid': isFormFieldValid('sala') })}
                                        value={formik.values.sala}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.sala && formik.touched.sala &&
                                <ErrorValidationMessage message={formik.errors.sala} />
                            }
                        </div>
                        
                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="cursoId"
                                className={classNames({ 'p-error': isFormFieldValid('cursoId') })}
                            >
                                Curso
                            </label>
                            {
                                !isLoading ?
                                    <Dropdown
                                        id="cursoId"
                                        name="cursoId"
                                        value={formik.values.cursoId}
                                        options={curso}
                                        optionLabel="nome"
                                        optionValue="id"
                                        emptyMessage="Nenhum curso encontrado"
                                        emptyFilterMessage={"Nenhum curso encontrado"}
                                        filter={true}
                                        onChange={(e) => formik.setFieldValue("cursoId", e.value)}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cursoId') })}
                                        placeholder="Selecione o nome do curso"
                                        showClear={true} 
                                    />
                                    
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.cursoId && formik.touched.cursoId &&
                                <ErrorValidationMessage message={formik.errors.cursoId} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="perfilId"
                                className={classNames({ 'p-error': isFormFieldValid('perfilId') })}
                            >
                                Instrutor
                            </label>
                            {
                                !isLoading ?
                                    <Dropdown
                                        id="perfilId"
                                        name="perfilId"
                                        value={formik.values.perfilId}
                                        options={instrutor}
                                        optionLabel="nome"
                                        optionValue="id"
                                        emptyMessage="Nenhum instrutor encontrado"
                                        emptyFilterMessage={"Nenhum instrutor encontrado"}
                                        filter={true}
                                        onChange={(e) => formik.setFieldValue("perfilId", e.value)}
                                        className={classNames({ 'p-invalid': isFormFieldValid('perfilId') })}
                                        placeholder="Selecione o nome do instrutor"
                                        showClear={true} 
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.perfilId && formik.touched.perfilId &&
                                <ErrorValidationMessage message={formik.errors.perfilId} />
                            }
                        </div>

                    </div>
                    <div className="p-d-flex p-jc-end p-mr-1">
                        {
                            !isLoading ?
                                <Button
                                    label={'Atualizar'}
                                    type="submit"
                                    iconPos="right"
                                    loading={isSubmiting}
                                />
                                :
                                <Skeleton width="82.16px" height="35px" />

                        }
                    </div>
                </form>
            </div>
        </div>
        </Dialog>
        </>
    );
}