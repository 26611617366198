import React from 'react';
import MenuMain from '../../MenuMain';
import { FormPerfis } from '../../components/FormPerfis';
import { AppFooter } from '../../AppFooter';


export default function CadastrarPerfisPage() {

  const defaultValues = {
    'nome': '',
    'cpf': '',
    'email': '',
    'dataNascimento': '',
    'responsavel': '',
    'celular': '',
    'telefone': '',
    'testeCanto': '',
    'lgpdAceite': false,
    'tipo': '',
    'tipoPerfis': ['INSCRITO', 'ALUNO', 'INSTRUTOR', 'ADMIN'],
    'deficiencia': '',
    'deficienciaPerfis': ['NENHUM', 'DEFICIÊNCIA AUDITIVA', 'DEFICIÊNCIA VISUAL', 'DEFICIÊNCIA INTELECTUAL', 'DEFICIÊNCIA MOTORA', 'AUTISMO', 'TDAH', 'SINDROME DE DOWN', 'OUTROS'],

  }

  return (
    <div>
      <MenuMain>
         <FormPerfis
          defaultValues={defaultValues}
        />
      </MenuMain>
      <AppFooter />
    </div>
  );
}
