import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { useHistory } from "react-router-dom";
import '../layout/sass/_pickListSugestoes.scss'
import { InputNumber } from 'primereact/inputnumber';
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';

export const TabelaNotasAluno = ({ matricula }) => {
    const history = useHistory();
    const toast = useRef(null);
    const confirmationToast = useRef(null)
    const [notas, setNotas] = useState([]);
    const [notasAtualizar, setNotasAtualizar] = useState([]);

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Notas</h2>
            </div>
        </div>

    );

    const setNotaFinal = async (matriculaId) => {
        try {
            const response = await api.get(`nota/soma/${matriculaId}`);
            let tempMatricula = {
                'id': response.data.matricula.id,
                'alunoId': response.data.matricula.aluno.id,
                'conclusao': response.data.matricula.conclusao,
                'cpf': response.data.matricula.aluno.cpf,
                'dataMatricula': response.data.matricula.dataMatricula,
                'turmaId': response.data.matricula.turma.id,
                'instrumentoId': response.data.matricula.instrumento.id,
                'notaFinal': response.data.notaTotal,
                'status': response.data.matricula.status,
                'periodoId': response.data.matricula.periodoId
            }
            await api.put("matricula", tempMatricula)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao atualizar nota', detail: error.toString() });
        }
    }

    const handleSaveNotas = async () => {
        try {
            if (notasAtualizar?.length)
            {
                api.put("nota/updateMultiplasNotas", notasAtualizar)
                
                setTimeout(async () => {
                    await setNotaFinal(matricula);
                }, 2000)
                toast.current.show({ life: 2000, severity: 'success', summary: 'Atualização Concluída!', detail: `Notas atualizadas com sucesso!` });
                setTimeout(() => {
                        
                    history.push('/matriculas')

                }, 2000)
            }
            else
            {
                toast.current.show({ life: 2000, severity: 'error', summary: 'Valor(es) inválido(s)!', detail: `Erro ao alterar as notas! `});
            }

        }
        catch (error) {
            toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao alterar!', detail: "Erro ao alterar..." });
        }

    }


    const handleEditNota = (data,e) => {
            if (e.value <= data.avaliacaoNotaTotal && e.value != null)
            {
                setNotasAtualizar([
                    ...notasAtualizar,
                    {
                        "id": data.id,
                        "notaAluno": e.value,
                    }
                ])
            } else {
                toast.current.show({ life: 2000, severity: 'error', summary: 'Erro', detail: "A nota do aluno deve ser menor ou igual a nota total!" });
            }
    }

    const avaliacaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textAlign: 'left' }}>
                    <span className="p-column-title">Avaliação</span>
                    <span className="table-data">{rowData.avaliacaoTipo}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataAvaliacaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textAlign: 'left' }}>
                    <span className="p-column-title">Data Avaliação</span>
                    <span className="table-data">{new Date(rowData.avaliacaoData).toLocaleDateString()}</span>
                </p>
            </React.Fragment>
        );
    }

    const instrutorBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textAlign: 'left' }}>
                    <span className="p-column-title">Instrutor</span>
                    <span className="table-data">{rowData.matriculaTurmaPerfilNome}</span>
                </p>
            </React.Fragment>
        );
    }

    const cursoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textAlign: 'left' }}>
                    <span className="p-column-title">Curso</span>
                    <span className="table-data">{rowData.matriculaTurmaCursoNome}</span>
                </p>
            </React.Fragment>
        )
    }

    const notaTotalBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textAlign: 'left' }}>
                    <span className="p-column-title">Nota da Avaliação</span>
                    <span className="table-data">{rowData.avaliacaoNotaTotal}</span>
                </p>
            </React.Fragment>
        )
    }


    const notaAlunoBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textAlign: 'left'}}>
                    <span className="p-column-title">Nota do Aluno</span>
                    <InputNumber onValueChange={(e) => handleEditNota(rowData,e)} mode="decimal" minFractionDigits={2} value={rowData.notaAluno} inputStyle={
            rowData?.notaAluno > rowData?.avaliacaoNotaTotal
              ? { color: 'red' }
              : rowData?.notaAluno === 0
              ? { color: 'orange' }
              : null
          }/>

                </p>
            </React.Fragment>
        );
    }

    const getNotas = useCallback(async () => {
        try {
            if (matricula !== undefined) {
                const response = await api.get(`nota/porMatricula/${matricula}`);
                setNotas(response.data);
            }
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter as notas deste aluno', detail: error.toString() });
        }
    }, [matricula]);

    useEffect(() => {
        async function notas() {
            await getNotas();
        };
        notas();
    }, [getNotas])

    return (
        <>

            <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

                <Toast ref={toast} position="bottom-right" />

                <Toast ref={confirmationToast} position="bottom-center" />

                <div className="card p-col-12 datatable-responsive-demo">

                    <div className="p-fluid">

                    <DataTable style={{ width: '100%' }}
                            lazy={true}
                            onPage={getNotas}
                            header={header}
                            value={notas}
                            filterDisplay="row"
                            className="p-datatable-responsive-demo"
                            emptyMessage="Nenhuma nota encontrada"
                            dataKey="id"
                            rows={50}
                        >

                            <Column
                                field="avalicaoTipo"
                                header="Avaliação"
                                body={avaliacaoBodyTemplate}

                            />

                            <Column
                                field="avaliacaoData"
                                header="Data Avaliação"
                                body={dataAvaliacaoBodyTemplate}

                            />

                            <Column
                                field="matriculaTurmaPerfilNome"
                                header="Instrutor"
                                body={instrutorBodyTemplate}

                            />
                            <Column
                                field="matriculaTurmaCursoNome"
                                header="Curso"
                                body={cursoBodyTemplate}
                            />

                            <Column
                                field="avaliacaoNotaTotal"
                                header="Nota Total"
                                body={notaTotalBodyTemplate}

                            />

                            <Column
                                field="notaAluno"
                                header="Nota do Aluno"
                                body={notaAlunoBodyTemplate}

                            />
                        </DataTable>
                        <div className="p-d-flex p-mt-2">
                                <Button
                                    label="Atualizar"
                                    type="button"
                                    iconPos="right"
                                    onClick={() => handleSaveNotas()}
                                    className="p-mr-6"
                                    disabled={!notasAtualizar?.length}
                                />
                                <Button
                                    label="Voltar"
                                    type="button"
                                    iconPos="right"
                                    onClick={() => history.push('/matriculas')}
                                    className="p-mr-2"
                                />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}