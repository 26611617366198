import React, { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Steps } from 'primereact/steps';

import { PrimeiroAcessoPerfil } from './PrimeiroAcessoPerfil';

import { usePrimeiroAcesso } from '../hooks/usePrimeiroAcesso';

export const PrimeiroAcesso = () => {

    const { activeIndex, setActiveIndex, visible, getUsefullsData } = usePrimeiroAcesso();

    const steps = [
        { label: 'Perfil' },
    ]

    useEffect(() => {
        getUsefullsData();
    }, [getUsefullsData])

    return (
        <Dialog
            visible={visible}
            maximized={true}
            closable={false}
            showHeader={false}
            onHide={() => null}
        >
            <div className="p-grid">
                <div className="p-col-12 p-mb-0 p-mt-2">
                    <Steps
                        id="stepsMenu"
                        activeIndex={activeIndex}
                        onSelect={(e) => activeIndex > e.index ? setActiveIndex(e.index) : null}
                        model={steps}
                        readOnly={false}
                    />
                </div>

                <div className="p-col-12">
                    {activeIndex === 0 ?
                        <PrimeiroAcessoPerfil />
                        : ''
                    }
                </div>
            </div>
        </Dialog>
    );
}

