import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';
import { FormPerfil } from './FormPerfil';
import { usePrimeiroAcesso } from '../hooks/usePrimeiroAcesso';

export const PrimeiroAcessoPerfil = () => {

    const { isLoading} = usePrimeiroAcesso();
    const toast = useRef(null);

    return (
        <>
            <Toast ref={toast} position="bottom-right" />
            <div className="card p-grid p-col-12 p-jc-center p-mx-0 p-p-0">
                <div className="p-col-12 p-md-10 p-lg-8 p-xl-6">
                    <FormPerfil
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </>
    );
}
