import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorValidationMessage } from './ErrorValidationMessage';
import { confirmDialog } from 'primereact/confirmdialog';
import api from '../api/api';
import { InputTextarea } from 'primereact/inputtextarea';
import '../layout/sass/_form.scss';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import {AuthContext} from '../contexts/AuthContext';
import { FormPerfisMensagens } from './FormPerfisMensagens';
import { Dialog } from 'primereact/dialog';

export const FormMensagens = ({ isEditing, isLoading, defaultValues }) => {

    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [instrutor, setInstrutor] = useState([]);
    const { user } = useContext(AuthContext);
    const [showAdicionarPerfil, setShowAdicionarPerfil] = useState(false)
    const [perfil, setPerfil] = useState([]);
    const [loadingPerfil, setLoadingPerfil] = useState(false);
    const [visible, setVisible] = useState(false);
    const schema = Yup.object().shape({
        receptorId: Yup
            .number()
            .required("O receptor é obrigatório"),
        mensagem: Yup
            .string()
            .required("A mensagem é obrigatória")
    })

    const handleCriarPerfil = () => {
        setLoadingPerfil(true);
        setShowAdicionarPerfil(true);
        setPerfil({
            'nome': '',
            'cpf': '',
            'email': user.email,
            'dataNascimento': '',
            'celular': '',
            'lgpdAceite': true,
            'tipo': "ADMIN",
            'deficiencia': 'NENHUM',
            'deficienciaPerfis': ['NENHUM', 'DEFICIÊNCIA AUDITIVA', 'DEFICIÊNCIA VISUAL', 'DEFICIÊNCIA INTELECTUAL', 'DEFICIÊNCIA MOTORA', 'AUTISMO', 'TDAH', 'SINDROME DE DOWN', 'OUTROS'],
            'tipoPerfis': ['INSCRITO', 'ALUNO', 'INSTRUTOR', 'ADMIN'],
          })
        setLoadingPerfil(false);
    }

    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)                
          
            try {
                if (!isEditing) {
                    const emissor = await api.get(`perfil/instrutor/admin/${user.email}`);
                    let emissorId = emissor.data.id != undefined ? emissor.data.id : null;
                    data.emissorId = emissorId;
                    if (data.emissorId == null)
                    {
                        if (user.perfilUser[0] !== 'user-instrutor')
                        {
                            confirmDialog({
                                message: `O usuário atual não possui perfil, deseja adicionar?`,
                                header: 'Não foi possível enviar a mensagem',
                                icon: 'pi pi-exclamation-triangle',
                                acceptLabel: 'Sim',
                                rejectLabel: 'Não',
                                accept: async () => {
                                    handleCriarPerfil();
                                },
                                reject: () => { return }
                            });
                        }
                        else
                        {
                            setVisible(true);
                        }
                    }
                    else
                    {
                        if (data.receptorId.length === 1)
                        {
                            data.receptorId = data.receptorId[0];
                            // console.log(data)
                            await api.post("mensagens", data)
                        }
                        else
                        {
                            data.receptorId.forEach(async receptor => {
                                data.receptorId = receptor;
                                await api.post("mensagens", data)
                            });
                        }
                        formik.resetForm()
                        toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `Mensagem enviada com sucesso` });
                        setTimeout(() => {
                            history.push('/mensagens')
    
                        }, 2000)
                    }


                }
                else {
                    await api.put("mensagens", data)
                    
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `Mensagem atualizada com sucesso` });
                    setTimeout(() => {
                        
                        history.push('/mensagens')

                    }, 2000)
                }
            }
            catch (error) {
                if (!isEditing)
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao cadastrar mensagem!', detail: error.toString() });
                else
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar mensagem!', detail: error.toString() });

            }

            setIsSubmiting(false)
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    
    const getInstrutor = useCallback(async () => {
        try {
            const response = await api.get(`perfil/instrutor/admin`);
            let instrutores = response.data.filter(item => item.email !== user.email);
            setInstrutor(instrutores)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter instrutores', detail: error.toString() });
        }
    }, []);

    useEffect(() => {
        
        async function dashboard() {
                await getInstrutor();
        };
        dashboard();

    }, [getInstrutor])

    return (
        <>
        <FormPerfisMensagens
            defaultValues={perfil}
            visible={showAdicionarPerfil}
            setVisible={setShowAdicionarPerfil}
            isLoading={loadingPerfil}
        />
            <Dialog
                className='p-scrollpanel-bar-y'
                header='Atenção!'
                onHide={() => { setVisible(false)}}
                visible={visible}
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ width: '50vw', heigth: '5vw' }}
            >
                <div className="p-field p-col-12 p-sm-12">
                    O usuário atual não possui perfil. Por gentileza, entrar em contato com a administração do conservatório.
                </div>
            </Dialog>
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <div className="p-col-12">
                <h2 className="p-mt-0">Mensagens</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-fluid p-mx-0">
                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="receptorId"
                                className={classNames({ 'p-error': isFormFieldValid('receptorId') })}
                            >
                                Receptor
                            </label>
                            {
                                !isLoading ?
                                    <MultiSelect
                                    value={formik.values.receptorId}
                                    options={instrutor}
                                    optionLabel="nome"
                                    optionValue="id"
                                    emptyMessage="Nenhum receptor encontrado"
                                    emptyFilterMessage="Nenhum receptor encontrado"
                                    filter={true}
                                    onChange={(e) => formik.setFieldValue("receptorId", e.value)}
                                    placeholder="Selecione o(s) receptor(es)"
                                    display="chip"
                                />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.receptorId && formik.touched.receptorId &&
                                <ErrorValidationMessage message={formik.errors.receptorId} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-6">
                            <label
                                htmlFor="mensagem"
                                className={classNames({ 'p-error': isFormFieldValid('mensagem') })}
                            >
                                Mensagem
                            </label>
                            {
                                !isLoading ?
                                <InputTextarea 
                                    id="mensagem"
                                    name="mensagem"
                                    rows={5} 
                                    cols={60}  
                                    className={classNames({ 'p-invalid': isFormFieldValid('mensagem') })}
                                    value={formik.values.mensagem}
                                    onChange={formik.handleChange}
                                />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.mensagem && formik.touched.mensagem &&
                                <ErrorValidationMessage message={formik.errors.mensagem} />
                            }
                        </div>

                    </div>
                    <div className="p-d-flex p-jc-end p-mr-1">
                        {
                            !isLoading ?
                                <Button
                                    label={isEditing ? 'Atualizar' : 'Enviar'}
                                    type="submit"
                                    iconPos="right"
                                    loading={isSubmiting}
                                />
                                :
                                <Skeleton width="82.16px" height="35px" />

                        }
                    </div>
                </form>
            </div>
        </div>
        
    </>
    );
}