import React, { useRef, useEffect, useState, useContext, useCallback } from 'react';

import { useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import '../layout/sass/_pickListSugestoes.scss'
import { InputNumber } from 'primereact/inputnumber';
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';
import {FilterContext} from '../contexts/FilterContext';
import {AuthContext} from '../contexts/AuthContext';

export const TabelaAvaliacaoInstrutor = () => {
    const toast = useRef(null);
    const [notas, setNotas] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const confirmationToast = useRef(null)
    const [dt, setDt] = useState(null);
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [sortable, setSortable] = useState('ASC');
    const [sortableCampo, setSortableCampo] = useState('avaliacao.id');
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tipos, setTipos] = useState([]);
    const filterStatus = renderFilterStatus();
    const { filter, setFilter } = useContext(FilterContext);

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Avaliação</h2>
            </div>
            <div className="p-my-0 p-grid p-dir-rev p-col">
            <div className="p-m-2">
                <Button
                    label="Adicionar"
                    className="p-mb-2 p-button-primary"
                    icon="pi pi-plus"
                    iconPos="left"
                    type="button"

                    onClick={() => history.push('/avaliacaocreate')}

                />
                </div>
                <div className="p-m-2">
                    <Button
                        label="Limpar Filtros"
                        className="p-mb-2 p-button-primary"
                        iconPos="left"
                        type="button"
                        onClick={() => handleLimparClick()}
                    />
                </div>
            </div>
        </div>
        
    );

    const handleLimparClick = () => {
        setFilter({
            first: 0,
            rows: 20,
            sortField: null,
            sortOrder: null,
            filters: {
            },
            
        });
        setSelectedStatus(null);
    }

    const getNotas = useCallback(async (event) => {
        setIsLoading(true)
        setNotas([])

        // console.log(event)   

        if(event !== undefined) {
            setTimeout(() => {
                const startIndex = event.first;

                setFirst(startIndex);
            }, 250);
        }
       
        try {

            if(filter.filters === undefined) 
            {
                if(event === undefined) {
                    const response = await api.get(`nota/porInstrutor/${user.email}?sort=${sortableCampo},${sortable}`);
                    // console.log(response.data.content);
                    response.data.content.forEach(nota => {                        
                        setNotas(notas => [...notas, nota])
                    })                
                } else {
                    const response = await api.get(`nota/porInstrutor/${user.email}?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    // console.log(response.data.content);
                    response.data.content.forEach(nota => {
                        setNotas(notas => [...notas, nota])
                    })  
                    
                }

                const respElements = await api.get(`nota/porInstrutor/${user.email}?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);

            } 
            else 
            { 
                const pesquisa = {
                    "avaliacaoId": filter.filters['avaliacaoId'] !== undefined ? filter.filters['avaliacaoId'].value : null,
                    "nomeAluno": filter.filters['matriculaAlunoNome'] !== undefined ? filter.filters['matriculaAlunoNome'].value : "",
                    "notaAluno": filter.filters['notaAluno'] !== undefined ? filter.filters['notaAluno'].value : null,
                    "nomeInstrutor": filter.filters['matricula.turma.perfil.nome'] !== undefined ? filter.filters['matricula.turma.perfil.nome'].value : "",
                    "turma": filter.filters['matriculaTurmaCursoNome'] !== undefined ? filter.filters['matriculaTurmaCursoNome'].value : "",
                    "notaTotal": filter.filters['avaliacaoNotaTotal'] !== undefined ? filter.filters['avaliacaoNotaTotal'].value : null,
                    "tipoAvaliacao": filter.filters['avaliacaoTipo'] !== undefined ? filter.filters['avaliacaoTipo'].value : null,   
                    "email": user.email,                     
                }

                if (event === undefined) {   
                    const respElements = await api.post(`nota/searchNotaInstrutor/${sortable}/${sortableCampo}?`, pesquisa);
                    // console.log(respElements)
                    respElements.data.content.forEach(nota => {
                        setNotas(notas => [...notas, nota])
                    })
                    setTotalRecords(respElements.data.totalElements);
                } else {
                    const respElements = await api.post(`nota/searchNotaInstrutor/${sortable}/${sortableCampo}?page=${event.page}`, pesquisa);
                    respElements.data.content.forEach(nota => {
                        setNotas(notas => [...notas, nota])
                    })
                    setTotalRecords(respElements.data.totalElements);
                }
            } 
            
        }
        catch (error) {  

            if(filter !== undefined){
                if(event === undefined) {
                    const response = await api.get(`nota/porInstrutor/${user.email}?},${sortable}`);
                    response.data.content.forEach(nota => {
                        setNotas(notas => [...notas, nota])
                    })                  
                } else {
                    const response = await api.get(`nota/porInstrutor/${user.email}?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(nota => {
                        setNotas(notas => [...notas, nota])
                    })  
                    
                }

                const respElements = await api.get(`nota/porInstrutor/${user.email}?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);
            } else {
                toast.current.show({ severity: 'error', summary: 'Erro ao obter avaliações', detail: error.toString() });    
            }
              
        }

        setIsLoading(false)
    }, [filter,sortable,sortableCampo,user.email]);

    useEffect(() => {
        const fetchNotas = async () => {
            try {
                await getNotas();

            }
            catch (error){
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchNotas();

    }, [getNotas])

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2 edit-btn" onClick={() => handleEditClick(rowData)}/>                
            
            </div>
        );
    }

    function renderFilterStatus() {
        return (
            <Dropdown
                value={selectedStatus}
                options={tipos}
                onChange={(e) => onStatusSearchChange(e)}
                placeholder="Selecione um status"
                className="p-column-filter"
                showClear={true} />
        );
    }

    const codBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.avaliacao.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const nomeBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Nome do Aluno</span>
                    <span className="table-data">{rowData.matricula.aluno.nome}</span>
                </p>
            </React.Fragment>
        );
    }

    const notaAlunoBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Nota do Aluno</span>
                    <InputNumber onValueChange={(e) => handleEditNota(rowData,e)} mode="decimal" minFractionDigits={2} value={rowData.notaAluno} inputStyle={rowData.notaAluno > rowData.avaliacao.notaTotal ? { color: 'red'} : rowData.notaAluno === 0 ? { color: 'orange'} : null}/>

                </p>
            </React.Fragment>
        );
    }

    const instrutorBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Instrutor</span>
                    <span className="table-data">{rowData.matricula.turma.perfil.nome}</span>
                </p>
            </React.Fragment>
        );
    }

    const turmaBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Turma</span>
                    <span className="table-data">{rowData.matricula.turma.curso.nome}</span>
                </p>
            </React.Fragment>
        );
    }

    const notaTotalBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Nota Total</span>
                    <span className="table-data">{rowData.avaliacao.notaTotal}</span>
                </p>
            </React.Fragment>
        );
    }

    const tipoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Tipo Avaliação</span>
                <span className="table-data">{rowData.avaliacao.tipo}</span>
            </React.Fragment>
        );
    }

    const onSort = (event) => {
        setSortableCampo(
            event.sortField === "avaliacaoId" ? "avaliacao.id" :
            event.sortField === "matriculaAlunoNome" ? "matricula.aluno.nome" :
            event.sortField === "matriculaTurmaPerfilNome" ? "matricula.turma.perfil.nome" :
            event.sortField === "matriculaTurmaCursoNome" ? "matricula.turma.curso.nome" : 
            event.sortField === "avaliacaoNotaTotal" ? "avaliacao.notaTotal" : 
            event.sortField === "avaliacaoTipo" ? "avaliacao.tipo" : 
            event.sortField
            );
        if(sortable === 'ASC'){
            setSortable('DESC')
        } else if (sortable === 'DESC') {
            setSortable('ASC')
        }
    }

    const onFilter = (event) => {
        event['first'] = 0;
        // setLazyParams(event);
        setFilter(event);   
    }
  
    const handleEditClick = (data) => {

        history.push(`/avaliacao/edit/${data.avaliacao.id}`);

    }

    const handleEditNota = async (data,e) => {
        const temp = {
            "id": data.id,
            "notaAluno": e.value,
        }

        try {
            if (temp.notaAluno <= data.avaliacao.notaTotal && temp.notaAluno != null)
            {
                api.put("nota/updateNotas", temp)
                
                setTimeout(async () => {
                    await setNotaFinal(data.matricula.id);
                }, 2000)
                toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro Alterado!', detail: `${data.matricula.aluno.nome} alterado com sucesso` });
            }
            else
            {
                toast.current.show({ life: 2000, severity: 'error', summary: 'Valor inválido!', detail: `Erro ao alterar ${data.matricula.aluno.nome} `});

            }

        }
        catch (error) {

            toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao alterar!', detail: "Erro ao alterar..." });

        }

    }

    const setNotaFinal = async (matriculaId) => {
        try {
            const response = await api.get(`nota/soma/${matriculaId}`);
            let tempMatricula = {
                'id': response.data.matricula.id,
                'alunoId': response.data.matricula.aluno.id,
                'conclusao': response.data.matricula.conclusao,
                'cpf': response.data.matricula.aluno.cpf,
                'dataMatricula': response.data.matricula.dataMatricula,
                'turmaId': response.data.matricula.turma.id,
                'instrumentoId': response.data.matricula.instrumento.id,
                'notaFinal': response.data.notaTotal,
                'status': response.data.matricula.status,
                'periodoId': response.data.matricula.periodoId
            }
            await api.put("matricula", tempMatricula)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao atualizar nota', detail: error.toString() });
        }
    }

    const onStatusSearchChange = (event) => {
        if (event.value !== null){
            dt.filter(event.value, 'avaliacaoTipo', 'equals');
            setSelectedStatus(event.value);
        }  
        else
            dt.filter(null, 'avaliacaoTipo', 'equals');
    }
    
    const getTipos = useCallback(async () => {
        const response = await api.get(`tipoAvaliacao`);
        setTipos(response.data.map(tipos => {
            var Tipos = { label: '', value: '' };
            Tipos.label = tipos
            Tipos.value = tipos

            return Tipos
        }))
        filter.filters !== undefined && (filter.filters.avaliacaoTipo !== undefined && filter.filters.avaliacaoTipo.value != null) ? setSelectedStatus(filter.filters.avaliacaoTipo.value) : setSelectedStatus(null)    
    }, [filter]);

    useEffect(() => {

        async function avaliacao() {
            await getTipos();
        };
        avaliacao();
    }, [getTipos])

    return (
        <>
        
        <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">
      
            <Toast ref={toast} position="bottom-right" />
            
            <Toast ref={confirmationToast} position="bottom-center" />
            
            <div className="card p-col-12 datatable-responsive-demo">
                
                <div className="p-fluid">
                    
                <DataTable style={{ width: '100%' }}      
                    totalRecords={totalRecords}
                    lazy="true"
                    first={first}
                    onPage={getNotas}
                    ref={(element) => setDt(element)}
                    header={header}
                    value={notas}
                    filterDisplay="row"
                    className="p-datatable-responsive-demo"
                    emptyMessage="Nenhuma avaliação encontrada"
                    loading={isLoading}
                    dataKey="avaliacaoId"
                    paginator
                    rows={20}
                    currentPageReportTemplate={`Mostrando de {first} a {last} de ${totalRecords} avaliações`}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                    // rowsPerPageOptions={[10, 20, 30]} 
                    onSort={onSort}
                    onFilter={onFilter}
                    filters={filter.filters}
                >

                    <Column style={{ width: '5%' }}
                        field="avaliacaoId"
                        header="Código"
                        sortable={sortable}
                        body={codBodyTemplate}                            
                        filter
                        filterMatchMode="custom"
                        filterPlaceholder={filter.filters !== undefined && (filter.filters.avaliacaoId !== undefined && filter.filters.avaliacaoId.value != null) ? filter.filters.avaliacaoId.value : "Pesquisar pelo Código"}
                        
                        />
                    
                    <Column style={{ width: '20%' }}
                        field="matriculaAlunoNome"
                        header="Nome do Aluno"
                        sortable={sortable}
                        body={nomeBodyTemplate}                            
                        filter
                        filterMatchMode="contains"
                        filterPlaceholder={filter.filters !== undefined && (filter.filters.matriculaAlunoNome !== undefined && filter.filters.matriculaAlunoNome.value !== "") ? filter.filters.matriculaAlunoNome.value : "Pesquisar pelo nome"}
                        
                    />


                    <Column style={{ width: '12.5%' }}
                        field="notaAluno"
                        header="Nota Aluno"
                        sortable={sortable}
                        body={notaAlunoBodyTemplate}                            
                        filter
                        filterMatchMode="contains"
                        filterPlaceholder={filter.filters !== undefined && (filter.filters.notaAluno !== undefined && filter.filters.notaAluno.value != null) ? filter.filters.notaAluno.value : "Pesquisar pela nota"}
                        
                    />


                    <Column style={{ width: '20%' }}
                        field="matriculaTurmaPerfilNome"
                        header="Instrutor"
                        sortable={sortable}
                        body={instrutorBodyTemplate}                            
                        filter
                        filterMatchMode="contains"
                        filterPlaceholder={filter.filters !== undefined && (filter.filters.matriculaTurmaPerfilNome !== undefined && filter.filters.matriculaTurmaPerfilNome.value !== "") ? filter.filters.matriculaTurmaPerfilNome.value : "Pesquisar pelo instrutor"}
                        
                    />


                    <Column style={{ width: '20%' }}
                        field="matriculaTurmaCursoNome"
                        header="Turma"
                        sortable={sortable}
                        body={turmaBodyTemplate}                            
                        filter
                        filterMatchMode="contains"
                        filterPlaceholder={filter.filters !== undefined && (filter.filters.matriculaTurmaCursoNome !== undefined && filter.filters.matriculaTurmaCursoNome.value !== "") ? filter.filters.matriculaTurmaCursoNome.value : "Pesquisar pela turma"}
                        
                    />

                    <Column style={{ width: '20%' }}
                        field="avaliacaoNotaTotal"
                        header="Nota Total"
                        sortable={sortable}
                        body={notaTotalBodyTemplate}                            
                        filter
                        filterMatchMode="contains"
                        filterPlaceholder={filter.filters !== undefined && (filter.filters.avaliacaoNotaTotal !== undefined && filter.filters.avaliacaoNotaTotal.value != null) ? filter.filters.avaliacaoNotaTotal.value : "Pesquisar pela nota total"}
                        
                    />

                    <Column style={{ width: '15%' }}
                        field="avaliacaoTipo"
                        header="Tipo de Avaliação"
                        sortable
                        body={tipoBodyTemplate}
                        filter
                        filterMatchMode="equals"
                        filterElement={filterStatus}

                    />

                    <Column style={{ width: '7.5%' }}
                        header="Ações"
                        body={actionBodyTemplate}
                        
                    />
    
                    </DataTable>
                    
                </div>
            </div>
        </div>
       
        </>
    );
}