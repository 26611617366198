import React, { useCallback, useEffect, useState } from 'react';

import { FormCampanhas } from '../../components/FormCampanhas';
import { ErrorRetrievingData } from '../../components/ErrorRetrievingData';
import MenuMain from '../../MenuMain';

import { AppFooter } from '../../AppFooter';

import api from '../../api/api';

export default function EditarCampanhasPage(props) {

    const [campanhas, setCampanhas] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorGettingCampanhas, setErrorGettingCampanhas] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [idCampanhas] = useState(props.match.params.id)

    const getCampanhas = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await api.get(`campanha/${idCampanhas}`)
            
            setCampanhas({
                'id': response.data.id,
                'nome': response.data.nome,
                'dataInicio': response.data.dataInicio,
                'dataFim': response.data.dataFim,
                'ativo': response.data.ativo
            })

            setErrorGettingCampanhas(false)
        }
        catch (error) {
            setErrorMessage(error.toString())
            setErrorGettingCampanhas(true)
        }

        setIsLoading(false);
    }, [idCampanhas]);

    useEffect(() => {

        async function campanhas() {
            await getCampanhas();
        };
        campanhas();

    }, [getCampanhas])

    return (
        <>
            <MenuMain >
                {
                    errorGettingCampanhas ?
                        <ErrorRetrievingData
                            errorMessage={errorMessage}
                            retrieveFunction={() => getCampanhas(idCampanhas)}
                            isLoading={isLoading}
                        />
                        :
                        <FormCampanhas
                            defaultValues={campanhas}
                            isEditing={true}
                            isLoading={isLoading}
                        />
                }
            </MenuMain>
            <AppFooter />
        </>
    );
}