import React, { useRef, useEffect, useState, useCallback } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

export const TabelaTurmaMatriculas = ({ turmaMatricula }) => {
    const toast = useRef(null);
    const [matriculas, setMatriculas] = useState([]);
    const [perfil, setPerfil] = useState([]);
    const [showPerfil, setShowPerfil] = useState(false);
    const confirmationToast = useRef(null)

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Matriculados</h2>
            </div>           
        </div>

    );

    const codBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const nomeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Nome</span>
                    <span className="table-data">{rowData.aluno.nome}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataMatriculaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Data Matrícula</span>
                    <span className="table-data">{new Date(rowData.dataMatricula).toLocaleDateString()}</span>
                </p>
            </React.Fragment>
        );
    }

    const conclusaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Conclusão</span>
                    {rowData.conclusao == null
                        ? <span className="table-data">NÃO CONCLUÍDO</span>
                        : <span className="table-data">{new Date(rowData.conclusao).toLocaleDateString()}</span>}
                </p>
            </React.Fragment>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Status</span>
                    <span className="table-data">{rowData.status}</span>
                </p>
            </React.Fragment>
        );
    }

    const instrumentoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Instrumento</span>
                    {rowData.instrumento == null
                        ? <span className="table-data">NÃO INFORMADO</span>
                        : <span className="table-data">{rowData.instrumento.nome}</span>}
                </p>
            </React.Fragment>
        );

    }

    const salaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Sala</span>
                    <span className="table-data">{rowData.turma.sala}</span>
                </p>
            </React.Fragment>
        );
    }

    const perfilBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                <Button icon="pi pi-info" className="p-button-rounded p-button-secondary" onClick={() => handlePerfilClick(rowData.aluno)}
                    style={{ "left": "5px", "bottom": "5px", "height": "20px", "width": "20px" }} />
                </p>
            </React.Fragment>
        );
    }

    const getMatriculas = useCallback(async () => {
        try {
            if(turmaMatricula !== undefined) {
                const response = await api.get(`matricula/turma/${turmaMatricula}`);
                setMatriculas(response.data)
            }            
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter matriculas na turma', detail: error.toString() });
        }
    }, [turmaMatricula])

    const handlePerfilClick = (perfil) => {
        setShowPerfil(true);
        setPerfil(perfil);
    }

    useEffect(() => {
        const matriculas = async () => {
            try {
                await getMatriculas();

            }
            catch (error){
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        matriculas();

    }, [getMatriculas])

    function formatCpf(data) {

        const cpf = data != null ? data.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4") : data;

        return cpf;
    }

    return (
        <>
            <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">
                <Dialog className='p-scrollpanel-bar-y' header="" onHide={() => setShowPerfil(false)} visible={showPerfil} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '50vw', heigth: '5vw' }} 
                >
                    <div>
                        <div className="p-d-flex p-ai-center p-flex-column header">
                            <h2>Perfil</h2>
                        </div>
                        <br></br>
                        <ol>
                            <div className="p-grid"  >
                                <div className="p-col-12"><b>Nome:</b> {perfil.nome}</div>
                                <div className="p-col-12"><b>CPF:</b> {formatCpf(perfil.cpf)}</div>
                                <div className="p-col-12"><b>Email:</b> {perfil.email}</div>
                                <div className="p-col-12"><b>Data de Nascimento:</b> {new Date(perfil.dataNascimento).toLocaleDateString()}</div>
                                <div className="p-col-12"><b>Deficiência:</b> {perfil.deficiencia}</div>
                                <div className="p-col-12"><b>Responsavel:</b> {perfil.responsavel}</div>
                                <div className="p-col-12"><b>Telefone:</b> {perfil.telefone}</div>
                                <div className="p-col-12"><b>Celular:</b> {perfil.celular}</div>
                            </div> 
                        </ol>    
                    </div>    
                </Dialog>
                <Toast ref={toast} position="bottom-right" />

                <Toast ref={confirmationToast} position="bottom-center" />

                <div className="card p-col-12 datatable-responsive-demo">

                    <div className="p-fluid">

                        <DataTable style={{ width: '100%' }}   
                            lazy="true"
                            onPage={getMatriculas}
                            header={header}
                            value={matriculas}
                            filterDisplay="row"
                            className="p-datatable-responsive-demo"
                            emptyMessage="Nenhuma matrícula encontrada"
                            dataKey="id"
                            rows={50}
                        >

                        <Column style={{ width: '5%' }}
                            field="id"
                            header="Código"
                            body={codBodyTemplate}
                            
                        />
                        
                        <Column style={{ width: '15%' }}
                            field="aluno.nome"
                            header="Nome"
                            body={nomeBodyTemplate}                  
                            
                        />                            

                        <Column style={{ width: '10%' }}
                            field="dataMatricula"
                            header="Data Matrícula"
                            body={dataMatriculaBodyTemplate}        

                        />

                        <Column style={{ width: '10%' }}
                            field="conclusao"
                            header="Conclusão"
                            body={conclusaoBodyTemplate}         

                        />

                        <Column style={{ width: '15%' }}
                            field="status"
                            header="Status"
                            body={statusBodyTemplate}                
                        />

                        <Column style={{ width: '15%' }}
                            field="instrumento.nome"
                            header="Instrumento"
                            body={instrumentoBodyTemplate}               
                        />
                        
                        <Column style={{ width: '5%' }}
                            field="turma"
                            header="Sala"
                            body={salaBodyTemplate}
                        />

                        <Column style={{ width: '5%' }}
                            field="perfil"
                            header="Perfil"
                            body={perfilBodyTemplate}
                        />

                        </DataTable>

                    </div>
                </div>
            </div>

        </>
    );
}