import { useContext } from 'react';
import { UserMenuContext } from '../contexts/UserMenuContext';

export const useUserMenu = () => {
    const {
        perfil,
        setPerfil,
        socioeconomico,
        setSocioeconomico,
        criancas,
        setCriancas,
        responsaveis,
        setResponsaveis,
        atividades,
        setAtividades,
        rendas,
        setRendas,
        orgaos,
        setOrgaos,
        distritos,
        setDistritos,
        isLoading,
        setIsLoading,
        getUserData,
        errorGettingData,
        setErrorGettingData,
        errorMessage,
        setErrorMessage,
        isCriancasLoading,
        setIsCriancasLoading,
        errorCriancasMessage,
        setErrorCriancasMessage,
        errorGettingCriancas,
        setErrorGettingCriancas,
        getCriancas,
        inicioMatriculas,
        fimMatriculas
    } = useContext(UserMenuContext)

    return {
        perfil,
        setPerfil,
        socioeconomico,
        setSocioeconomico,
        criancas,
        setCriancas,
        responsaveis,
        setResponsaveis,
        atividades,
        setAtividades,
        rendas,
        setRendas,
        orgaos,
        setOrgaos,
        distritos,
        setDistritos,
        isLoading,
        setIsLoading,
        getUserData,
        errorGettingData,
        setErrorGettingData,
        errorMessage,
        setErrorMessage,
        isCriancasLoading,
        setIsCriancasLoading,
        errorCriancasMessage,
        setErrorCriancasMessage,
        errorGettingCriancas,
        setErrorGettingCriancas,
        getCriancas,
        inicioMatriculas,
        fimMatriculas
    }
}