import React, { useCallback, useEffect, useState } from 'react';

import { FormLogs } from '../../components/FormLogs';
import { ErrorRetrievingData } from '../../components/ErrorRetrievingData';
import MenuMain from '../../MenuMain';

import { AppFooter } from '../../AppFooter';

import api from '../../api/api';

export default function EditarLogsPage(props) {

    const [logs, setLogs] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorGettingLogs, setErrorGettingLogs] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [idLogs] = useState(props.match.params.id)

    const getLogs = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await api.get(`logs/${idLogs}`)
            
            setLogs({
                'id': response.data.id,
                'usuario': response.data.usuario,
                'updated': response.data.updated,
                'cadastroId': response.data.cadastroId,
                'cadastro': response.data.cadastro,
                'acao': response.data.acao,
                'conteudo': response.data.conteudo                
            })

            setErrorGettingLogs(false)
        }
        catch (error) {
            setErrorMessage(error.toString())
            setErrorGettingLogs(true)
        }

        setIsLoading(false);
    }, [idLogs]);

    useEffect(() => {

        async function logs() {
            await getLogs();
        };
        logs();

    }, [getLogs])

    return (
        <>
            <MenuMain >
                {
                    errorGettingLogs ?
                        <ErrorRetrievingData
                            errorMessage={errorMessage}
                            retrieveFunction={() => getLogs(idLogs)}
                            isLoading={isLoading}
                        />
                        :
                        <FormLogs
                            defaultValues={logs}
                            isEditing={true}
                            isLoading={isLoading}
                        />
                }
            </MenuMain>
            <AppFooter />
        </>
    );
}