import React, { useRef, useState, useContext, useEffect, useCallback } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { BrCalendar } from './BrCalendar';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorValidationMessage } from './ErrorValidationMessage';

import { AuthContext } from '../contexts/AuthContext'

import api from '../api/api';

import '../layout/sass/_form.scss';
import { Dropdown } from 'primereact/dropdown';
import { TabelaAlunosFalta } from './TabelaAlunosFalta';

export const FormFalta = ({ isEditing, isLoading, defaultValues }) => {
    
    const [turma, setTurma] = useState("");
    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)
    const { user } = useContext(AuthContext);
    const [alunos, setAlunos] = useState([])

    const schema = Yup.object().shape({
        turmaId: Yup
            .number()
            .positive("O aluno deve ser selecionado")
            .integer("O aluno deve estar na lista"),
        obs: Yup
            .string(),
        dataOcorrencia: Yup
            .date()
            // .max(new Date(), "Data inicio inválida")
            .typeError("Uma data deve ser inserida")
            .required("A data de inicio é obrigatória")
    })

    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)                        
           
            try {
                if (!isEditing) {
                    let data1 = {id: null,
                                 dataOcorrencia: '',
                                 obs: ''
                    }
                    alunos.forEach(async item => {
                        
                        if (item.presente === false)
                        {
                            const response = await api.get(`licenca/porMatricula/${item.id}`);
                            if (response.data.length === 0)
                            {
                                data1 = {matriculaId: item.id,
                                    dataOcorrencia: data.dataOcorrencia,
                                    obs: data.obs
                                        }
                                await api.post("falta", data1);
                                toast.current.show({ life: 3000, severity: 'success', summary: 'Cadastro concluído!', detail: `O aluno(a) ${item.matriculaNome} adicionado(a) com sucesso` });
                            }
                            else
                            {
                                response.data.map(async (i) => { 
                                    let dataInicio = new Date(i.dataInicio).toISOString();
                                    let dataOcorrencia = new Date(data.dataOcorrencia).toISOString();
                                    let dataFim = new Date(i.dataFim).toISOString();
                                    if (dataOcorrencia >= dataInicio && dataOcorrencia <= dataFim)
                                    {
                                        toast.current.show({ life: 3000, severity: 'error', summary: 'Aluno de licença!', detail: `O aluno(a) ${item.matriculaNome} está de licença!` });
                                    }
                                    else
                                    {
                                        data1 = {matriculaId: item.id,
                                            dataOcorrencia: data.dataOcorrencia,
                                            obs: data.obs
                                                }
                                        await api.post("falta", data1);
                                        toast.current.show({ life: 3000, severity: 'success', summary: 'Cadastro concluído!', detail: `O aluno(a) ${item.matriculaNome} adicionado(a) com sucesso` });
                                    }
                                });
                            }
                            
                        }
                        
                    }); 
                    formik.resetForm()
                    setTimeout(async () => {
                        
                        history.push('/falta')

                    }, 3000)

                }
                else {
                    await api.put("falta", data)
                    
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `Aluno atualizado com sucesso` });
                    setTimeout(() => {
                                                
                        history.push('/falta')

                    }, 2000)
                }
                await setMatriculaDesistente();
            }
            catch (error) {
                if (!isEditing)
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao cadastrar falta!', detail: error.toString() });
                else
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar falta!', detail: error.toString() });

            }

            setIsSubmiting(false)
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getTurma = useCallback(async () => {
        try {
            if(user.perfilUser[0] !== 'user-instrutor') {
                const response = await api.get(`turma/turmaAllAtual`);  
                setTurma(response.data.map(turma => {
                    var turm = {label: '', value: ''};
                    turm.label = turma.perfilNome + " - " + turma.cursoNome +  " - " + turma.diaSemana + "  " + turma.hora
                    turm.value = turma.id 
                    return turm
                }))
            } else {
                const response = await api.get(`turma/turmaAllAtualInstrutor/${user.email}`);  
                setTurma(response.data.map(turma => {
                    var turm = {label: '', value: ''};
                    turm.label = turma.perfilNome + " - " + turma.cursoNome +  " - " + turma.diaSemana + "  " + turma.hora
                    turm.value = turma.id 
                    return turm
                }))
            }
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter matrícula', detail: error.toString() });
        }
    }, [user.perfilUser,user.email]);

    const setMatriculaDesistente = async () => {
        try {
            const response = await api.get(`falta/faltasSeguidas`);
            response.data.map(async (i) => { 
                
                if (i.datasOcorrencias.length > 30) {                    

                    await api.post(`matricula/alteraStatus/${i.matriculaId}`, "3" )
                    
                }                    
                
            });
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter matrícula', detail: error.toString() });
        }
    }

    const handleTurmaClick = async (e) => {
        try {
            formik.setFieldValue("turmaId",e.value);
            const response = await api.get(`matricula/turma/${e.value}`);
            const itens = response.data.map((i) => { return { id: i.id, matriculaNome: i.aluno.nome, presente: true} });
            setAlunos(itens);
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter alunos', detail: error.toString() });
        }
    }

    useEffect(() => {

        async function falta() {
            await getTurma();
        };
        falta();

    }, [getTurma]);

    return (
        <>
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <div className="p-col-12">
                <h2 className="p-mt-0">Falta</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-fluid p-mx-0">
                    {
                        !isEditing ?
                            <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="turmaId"
                                className={classNames({ 'p-error': isFormFieldValid('turmaId') })}
                            >
                                Turma
                            </label>
                            {
                                !isLoading ?
                                <Dropdown
                                    id="turmaId"
                                    name="turmaId"
                                    options={turma}
                                    emptyMessage="Nenhuma turma encontrada"
                                    emptyFilterMessage={"Nenhuma turma encontrada"}
                                    filter={true}
                                    value={formik.values.turmaId}
                                    onChange={(e) => handleTurmaClick(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('turmaId') })}
                                    placeholder="Selecione uma turma"
                                    showClear={true} 
                                />  
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.turmaId && formik.touched.turmaId &&
                                <ErrorValidationMessage message={formik.errors.turmaId} />
                            }
                            </div>
                        :
                            <></>
                    }
                    
                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="obs"
                                className={classNames({ 'p-error': isFormFieldValid('obs') })}
                            >
                                Observação
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="obs"
                                        name="obs"
                                        className={classNames({ 'p-invalid': isFormFieldValid('obs') })}
                                        value={formik.values.obs}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.obs && formik.touched.obs &&
                                <ErrorValidationMessage message={formik.errors.obs} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4 p-md-4">
                                <label
                                    htmlFor="dataOcorrencia"
                                    className={classNames({ 'p-error': isFormFieldValid('dataOcorrencia') })}
                                >
                                    Data Ocorrencia
                                </label>
                                <BrCalendar
                                    id="dataOcorrencia"
                                    name="dataOcorrencia"
                                    value={formik.values.dataOcorrencia}
                                    onChange={formik.handleChange}
                                    placeholder={ isEditing ? new Date(formik.values.dataOcorrencia + ' 03:00:00.000' ).toLocaleDateString('pt-BR') : "" }
                                    className={classNames({ 'p-invalid': isFormFieldValid('dataOcorrencia') })}
                                    yearRange={`2000:${new Date().getFullYear()}`}
                                />
                                {formik.errors.dataOcorrencia && formik.touched.dataOcorrencia &&
                                    <ErrorValidationMessage message={formik.errors.dataOcorrencia} />
                                }
                        </div>

                       
                        <div className="p-d-flex p-jc-end p-mr-1" >
                            {
                                !isEditing ?
                                    <TabelaAlunosFalta alunos={alunos} setAlunos={setAlunos} />
                                :
                                    <></>
                            }

                        </div>

                        
                    </div>
                    <div className="p-d-flex p-jc-end p-mr-1">
                        {
                            !isLoading ?
                                <Button
                                    label={isEditing ? 'Atualizar' : 'Salvar'}
                                    type="submit"
                                    iconPos="right"
                                    loading={isSubmiting}
                                />
                                :
                                <Skeleton width="82.16px" height="35px" />

                        }
                    </div>
                </form>
            </div>
            {/* <div className="p-col-12">
                { 
                    isEditing ?
                        <TabelaTurmasCurso
                            turmasCurso={defaultValues.id}
                        />
                    :
                        <></>
                }
                
            </div> */}
        </div>
        
    </>
    );
}