import React, { useRef, useState, useEffect, useCallback } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { BrCalendar } from './BrCalendar';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorValidationMessage } from './ErrorValidationMessage';

import api from '../api/api';

import '../layout/sass/_form.scss';
import { Dropdown } from 'primereact/dropdown';

export const FormLicenca = ({ isEditing, isLoading, defaultValues }) => {
    
    const [matricula, setMatricula] = useState("");
    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)
    
    const schema = Yup.object().shape({
        matriculaId: Yup
            .number()
            .required("O aluno é obrigatório")
            .positive("O aluno deve ser selecionado")
            .integer("O aluno deve estar na lista"),        
        obs: Yup
            .string(),
        dataInicio: Yup
            .date()
            .typeError("Uma data deve ser inserida")
            .required("A data de inicio é obrigatória"),
        dataFim: Yup
            .date()
            .typeError("Uma data deve ser inserida")
            .required("A data de fim é obrigatória"),     
        tipo: Yup
            .string()
            .required("O tipo é obrigatório")
    })

    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)      
          
            try {
                if (!isEditing) {
                    await api.post("licenca", data)

                    formik.resetForm()
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `Licença adicionada com sucesso` });
                    setTimeout(async () => {
                        await deleteFalta(data);
                        history.push('/licenca')

                    }, 2000)

                }
                else {
                    await api.put("licenca", data)
                    
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `Licença atualizada com sucesso` });
                    setTimeout(() => {
                        
                        history.push('/licenca')

                    }, 2000)
                }
            }
            catch (error) {
                if (!isEditing)
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao cadastrar licença!', detail: error.toString() });
                else
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar licença!', detail: error.toString() });

            }

            setIsSubmiting(false)
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    
    const deleteFalta = async (data) => {
        try {
            let result = await api.get(`falta/porMatricula/${data.matriculaId}`)
            result.data.map(async (i) => {
                let dataInicio = new Date(data.dataInicio).toISOString();
                let dataOcorrencia = new Date(i.dataOcorrencia + ' 03:00:00.000').toISOString();
                let dataFim = new Date(data.dataFim).toISOString();
                if (dataOcorrencia >= dataInicio && dataOcorrencia <= dataFim)
                {
                    const faltasSeguidas =  await api.get(`falta/faltasSeguidas/${data.matriculaId}`);
                    if (faltasSeguidas.data.length === 3)
                    {
                        let result = await api.get(`matricula/${data.matriculaId}`);
                        let status = await api.get(`historicoStatus/matricula/${data.matriculaId}`);

                        let data1 = {
                            id: result.data.id,
                            conclusao: result.data.conclusao,
                            dataMatricula: result.data.dataMatricula,
                            notaFinal: result.data.notaFinal,
                            status: status.data[0].status,
                            alunoId: result.data.alunoId,
                            instrumentoId: result.data.instrumentoId,
                            periodoId: result.data.periodoId,
                            turmaId: result.data.turmaId,
                        }
                        if (faltasSeguidas.data[0].matriculaStatus === "DESISTENTE")
                        {
                            setTimeout(async () => {
                                await api.put("matricula", data1);
        
                            }, 2000)
                            
                        }
                        
                    }
                    await api.delete(`falta/${i.id}`);  
                }
            });
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter faltas', detail: error.toString() });
        }
    }

    const getMatricula = useCallback(async () => {
        try {
            const response = await api.get(`matricula/turmaAtual`);

            setMatricula(response.data.map(matricula => {
                var matri = {label: '', value: ''};
                matri.label = matricula.aluno.nome + " - " + matricula.turma.ano + " - " + matricula.turma.curso.nome +  " - " + matricula.turma.diaSemana + "  " + matricula.turma.hora
                matri.value = matricula.id 
                return matri
            }))
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter matrícula', detail: error.toString() });
        }
    }, []);

    useEffect(() => {

        async function licenca() {
            await getMatricula();
        };
        licenca();

    }, [getMatricula]);

    return (
        <>
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <div className="p-col-12">
                <h2 className="p-mt-0">Licença</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-fluid p-mx-0">
                    <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="matriculaId"
                                className={classNames({ 'p-error': isFormFieldValid('matriculaId') })}
                            >
                                Aluno
                            </label>
                            {
                                !isLoading ?
                                <Dropdown
                                    id="matriculaId"
                                    name="matriculaId"
                                    options={matricula}
                                    emptyMessage="Nenhum aluno encontrado"
                                    emptyFilterMessage={"Nenhum aluno encontrado"}
                                    filter={true}
                                    value={formik.values.matriculaId}
                                    onChange={(e) => formik.setFieldValue("matriculaId", e.value)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('matriculaId') })}
                                    placeholder="Selecione um aluno"
                                    showClear={true} 
                                />  
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.matriculaId && formik.touched.matriculaId &&
                                <ErrorValidationMessage message={formik.errors.matriculaId} />
                            }
                        </div>
                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="obs"
                                className={classNames({ 'p-error': isFormFieldValid('obs') })}
                            >
                                Observação
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="obs"
                                        name="obs"
                                        className={classNames({ 'p-invalid': isFormFieldValid('obs') })}
                                        value={formik.values.obs}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.obs && formik.touched.obs &&
                                <ErrorValidationMessage message={formik.errors.obs} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4 p-md-4">
                                <label
                                    htmlFor="dataInicio"
                                    className={classNames({ 'p-error': isFormFieldValid('dataInicio') })}
                                >
                                    Data Inicio
                                </label>
                                <BrCalendar
                                    id="dataInicio"
                                    name="dataInicio"
                                    value={formik.values.dataInicio}
                                    onChange={formik.handleChange}
                                    placeholder={ isEditing ? new Date(formik.values.dataInicio).toLocaleDateString('pt-BR') : "" }
                                    className={classNames({ 'p-invalid': isFormFieldValid('dataInicio') })}
                                    yearRange={`2000:${new Date().getFullYear()}`}
                                />
                                {formik.errors.dataInicio && formik.touched.dataInicio &&
                                    <ErrorValidationMessage message={formik.errors.dataInicio} />
                                }
                        </div>

                        <div className="p-field p-col-12 p-sm-4 p-md-4">
                                <label
                                    htmlFor="dataFim"
                                    className={classNames({ 'p-error': isFormFieldValid('dataFim') })}
                                >
                                    Data Fim
                                </label>
                                <BrCalendar
                                    id="dataFim"
                                    name="dataFim"
                                    value={formik.values.dataFim}
                                    onChange={formik.handleChange}
                                    placeholder={ isEditing ? new Date(formik.values.dataFim).toLocaleDateString('pt-BR') : ""}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dataFim') })}
                                    yearRange={`2000:${new Date().getFullYear()}`}
                                />
                                {formik.errors.dataFim && formik.touched.dataFim &&
                                    <ErrorValidationMessage message={formik.errors.dataFim} />
                                }
                        </div>


                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="tipo"
                                className={classNames({ 'p-error': isFormFieldValid('tipo') })}
                            >
                                Tipo
                            </label>
                            {
                                !isLoading ?
                                    <Dropdown
                                        id="tipo"
                                        name="tipo"
                                        value={formik.values.tipo}
                                        options={formik.values.tipos}
                                        className={classNames({ 'p-invalid': isFormFieldValid('tipo') })}
                                        onChange={formik.handleChange}
                                    /> 
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.tipo && formik.touched.tipo &&
                                <ErrorValidationMessage message={formik.errors.tipo} />
                            }
                        </div>

                    </div>
                    <div className="p-d-flex p-jc-end p-mr-1">
                        {
                            !isLoading ?
                                <Button
                                    label={isEditing ? 'Atualizar' : 'Salvar'}
                                    type="submit"
                                    iconPos="right"
                                    loading={isSubmiting}
                                />
                                :
                                <Skeleton width="82.16px" height="35px" />

                        }
                    </div>
                </form>
            </div>
        </div>
        
    </>
    );
}