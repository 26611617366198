import React from 'react';
import { Button } from 'primereact/button';

export const ErrorRetrievingData = ({ retrieveFunction, errorMessage, isLoading }) => {
  return (
    <div className="p-d-flex p-flex-column p-jc-center p-ai-center" style={{ marginTop: '25vh' }}>
      <div className="p-mb-1">
        <i className="pi pi-exclamation-triangle p-error" style={{ 'fontSize': '4em' }}></i>
      </div>
      <div className="p-text-center p-error p-mb-2">
        <p>{errorMessage}</p>
      </div>
      <div>
        <Button
          className="p-button-secondary"
          label="Tentar novamente"
          loading={isLoading}
          iconPos="right"
          onClick={retrieveFunction}
        />
      </div>
    </div>
  );
}

