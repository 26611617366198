import React, { useRef, useEffect, useState, useCallback } from 'react';

import { useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DialogPeriodoMatricula } from './DialogPeriodoMatricula';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';

export const TabelaPeriodoMatricula = () => {
    const toast = useRef(null);
    const [periodoMatricula, setPeriodoMatricula] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const confirmationToast = useRef(null)
    const history = useHistory();
    const [showListaPeriodoMatricula, setShowListaPeriodoMatricula] = useState(false);   
    const [sortable, setSortable] = useState('asc');
    const [sortableCampo, setSortableCampo] = useState('id');
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 20,
        sortField: null,
        sortOrder: null,
        filters: {
            'tipo': { value: '', matchMode: 'contains' },
        },
        
    });

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Periodo da Matricula</h2>
            </div>
        </div>
        
    );

    const getPeriodoMatricula = useCallback(async (event) => {
        setIsLoading(true)
        setPeriodoMatricula([])

        if(event !== undefined) {
            setTimeout(() => {
                const startIndex = event.first;

                setFirst(startIndex);          
            }, 250);
        }
       
        try {
            const response = await api.get(`periodoMatricula?page=${event.page}&sort=${sortableCampo},${sortable}`);
            response.data.content.forEach(periodoMatricula => {
                setPeriodoMatricula(periodoMatriculas => [...periodoMatriculas, periodoMatricula])
            })  

            const respElements = await api.get(`periodoMatricula?sort=${sortableCampo},${sortable}`);
            setTotalRecords(respElements.data.totalElements);

        }
        catch (error) {  

            if(lazyParams !== undefined){
                if(event === undefined) {
                    const response = await api.get(`periodoMatricula?sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(periodoMatricula => {
                        setPeriodoMatricula(periodoMatriculas => [...periodoMatriculas, periodoMatricula])
                    })                  
                } else {
                    const response = await api.get(`periodoMatricula?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(periodoMatricula => {
                        setPeriodoMatricula(periodoMatriculas => [...periodoMatriculas, periodoMatricula])
                    }) 
                    
                }

                const respElements = await api.get(`periodoMatricula?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);
            } else {
                toast.current.show({ severity: 'error', summary: 'Erro ao obter periodo da matricula', detail: error.toString() });    
            }
              
        }

        setIsLoading(false)
    }, [lazyParams,sortable,sortableCampo]);

    useEffect(() => {
        const fetchPeriodoMatricula = async () => {
            try {
                await getPeriodoMatricula();

            }
            catch (error){
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchPeriodoMatricula();

    }, [getPeriodoMatricula])

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2 edit-btn" onClick={() => handleEditClick(rowData)}/>
            
            </div>
        );
    }

    const codBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const tipoBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Tipo</span>
                    <span className="table-data">{rowData.tipo}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataInicialBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Data Inicio</span>
                    <span className="table-data">{new Date(rowData.dataInicial).toLocaleDateString('pt-BR')}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataFinalBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Data Fim</span>
                    <span className="table-data">{new Date(rowData.dataFinal).toLocaleDateString('pt-BR')}</span>
                </p>
            </React.Fragment>
        );
    }

    const onSort = (event) => {
        setSortableCampo(event.sortField);
        setLazyParams(event);
        if(sortable === 'asc'){
            setSortable('desc')
        } else if (sortable === 'desc') {
            setSortable('asc')
        }
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);   
    }

  
    const handleEditClick = (data) => {

        history.push(`/periodoMatricula/edit/${data.id}`);

    }
    
    const handlePrintClick = () => {
        setShowListaPeriodoMatricula(true);
    }

    return (
        <>
        <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

         <DialogPeriodoMatricula
                visible={showListaPeriodoMatricula}
                setVisible={setShowListaPeriodoMatricula}
                periodoMatricula={periodoMatricula}
            />
            <div className="p-my-0 p-dir-rev p-col">
                <div className="p-my-0">
                <Button 
                    textalign="center"
                    icon="pi pi-print"
                    className="p-button-secondary"
                    style={{ width: '35px' }}
                    onClick={handlePrintClick}
                    tooltip="Gerar relatório"
                    tooltipOptions={{ position: 'top' }}
    /> 
                </div>
            </div>
      
            <Toast ref={toast} position="bottom-right" />
            
            <Toast ref={confirmationToast} position="bottom-center" />
            
            <div className="card p-col-12 datatable-responsive-demo">
                
                <div className="p-fluid">
                    
                    <DataTable      
                        totalRecords={totalRecords}  
                        lazy="true"
                        first={first} 
                        onPage={getPeriodoMatricula}       
                        header={header}
                        value={periodoMatricula}
                        filterDisplay="row"
                        className="p-datatable-responsive-demo"
                        emptyMessage="Nenhum periodo da matricula encontrado"
                        loading={isLoading}
                        dataKey="id"
                        paginator
                        rows={20}
                        currentPageReportTemplate={`Mostrando de {first} a {last} de ${totalRecords} periodos da matricula`}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                        onSort={onSort}                       
                        onFilter={onFilter}
                       filters={lazyParams.filters}
                    >

                        <Column
                            field="id"
                            header="Código"
                            sortable={sortable}
                            body={codBodyTemplate}                            
                            
                        />
                        
                        <Column
                            field="tipo"
                            header="Tipo"
                            sortable={sortable}
                            body={tipoBodyTemplate}                            
                            
                        />

                        <Column
                            field="dataInicial"
                            header="Data Inicio"
                            sortable={sortable}
                            body={dataInicialBodyTemplate}                          
                            
                        />

                        <Column
                            field="dataFinal"
                            header="Data Fim"
                            sortable={sortable}
                            body={dataFinalBodyTemplate}                         
                            
                        />

                        <Column
                            header="Ações"
                            body={actionBodyTemplate}
                            
                        />
    
                    </DataTable>
                    
                </div>
            </div>
        </div>
       
        </>
    );
}