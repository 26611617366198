import React, { useState, createContext, useCallback } from 'react';

export const PrimeiroAcessoContext = createContext({});

export default function PrimeiroAcessoProvider({ children }) {

    const [activeIndex, setActiveIndex] = useState(0)
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [aceite, setAceite] = useState({ aceite: false });
    const [perfil, setPerfil] = useState({
        'nome': '',
        'email': '',
        'cpf': '',
        'resonsavel': '',
        'nascimento': '',
        'celular': '',
        'telefone': '',
        'teste': '',
    });

    const getUsefullsData = useCallback(async () => {
        setIsLoading(true);

        setIsLoading(false);
    }, [])

    return (
        <PrimeiroAcessoContext.Provider
            value={{
                activeIndex,
                setActiveIndex,
                visible,
                setVisible,
                aceite,
                setAceite,
                perfil,
                setPerfil,
                isLoading,
                setIsLoading,
                getUsefullsData
            }}
        >
                {children}
        </PrimeiroAcessoContext.Provider>
    );
}
