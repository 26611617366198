import React, { useRef, useEffect, useState, useContext, useCallback } from 'react';

import { useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';
import {FilterContext} from '../contexts/FilterContext';
import {AuthContext} from '../contexts/AuthContext';
import { BrCalendar } from './BrCalendar';

export const TabelaFaltaInstrutor = () => {
    const toast = useRef(null);
    const [faltas, setFaltas] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const confirmationToast = useRef(null);
    const [dt, setDt] = useState(null);
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const [sortable, setSortable] = useState('ASC');
    const [sortableCampo, setSortableCampo] = useState('id');
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const { filter, setFilter } = useContext(FilterContext);
    const [selectedData, setSelectedData] = useState(null);

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Faltas</h2>
            </div>
            <div className="p-my-0 p-grid p-dir-rev p-col">
                <div className="p-m-2">
                <Button
                    label="Adicionar"
                    className="p-mb-2 p-button-primary"
                    icon="pi pi-plus"
                    iconPos="left"
                    type="button"

                    onClick={() => history.push('/faltacreate')}

                />
                </div>
                <div className="p-m-2">
                    <Button
                        label="Limpar Filtros"
                        className="p-mb-2 p-button-primary"
                        iconPos="left"
                        type="button"
                        onClick={() => handleLimparClick()}
                    />
                </div>
            </div>
        </div>
        
    );

    const handleLimparClick = () => {
        setFilter({
            first: 0,
            rows: 20,
            sortField: null,
            sortOrder: null,
            filters: {
            },
        });
        setSelectedData(null);
    }

    const getFaltas = useCallback(async (event) => {
        setIsLoading(true)
        setFaltas([])

        // console.log(event)   

        if(event !== undefined) {
            setTimeout(() => {
                const startIndex = event.first;

                setFirst(startIndex);
                // setFaltas(dataSource.slice(startIndex, endIndex));            
            }, 250);
        }
       
        try {

            if(filter.filters === undefined) 
            {

                if(event === undefined) {
                    const response = await api.get(`falta/porInstrutor/${user.email}?sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(falta => {
                        setFaltas(faltas => [...faltas, falta])
                    })                
                } else {
                    const response = await api.get(`falta/porInstrutor/${user.email}?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(falta => {
                        setFaltas(faltas => [...faltas, falta])
                    })  
                    
                }

                const respElements = await api.get(`falta/porInstrutor/${user.email}?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);

            } 
            else 
            {     
                const pesquisa = {
                    "id": filter.filters['id'] !== undefined ? filter.filters['id'].value : null,
                    "nome": filter.filters['matriculaAlunoNome'] !== undefined ? filter.filters['matriculaAlunoNome'].value : "",
                    "obs": filter.filters['obs'] !== undefined ? filter.filters['obs'].value : "",
                    "email": user.email, 
                    "dataOcorrencia": filter.filters['dataOcorrencia'] !== undefined ? filter.filters['dataOcorrencia'].value : null,
                }

                if (event === undefined) {   
                    const respElements = await api.post(`falta/searchFaltaInstrutor/${sortable}/${sortableCampo}?`, pesquisa);
                    respElements.data.content.forEach(falta => {
                        setFaltas(faltas => [...faltas, falta])
                    })
                    setTotalRecords(respElements.data.totalElements);
                } else {
                    const respElements = await api.post(`falta/searchFaltaInstrutor/${sortable}/${sortableCampo}?page=${event.page}`, pesquisa);
                    respElements.data.content.forEach(falta => {
                        setFaltas(faltas => [...faltas, falta])
                    })
                    setTotalRecords(respElements.data.totalElements);
                }
            }
            
        }
        catch (error) {  

            if(filter !== undefined){
                if(event === undefined) {
                    const response = await api.get(`falta/porInstrutor/${user.email}?sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(falta => {
                        setFaltas(faltas => [...faltas, falta])
                    })                  
                } else {
                    const response = await api.get(`falta/porInstrutor/${user.email}?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(falta => {
                        setFaltas(faltas => [...faltas, falta])
                    }) 
                    
                }

                const respElements = await api.get(`falta/porInstrutor/${user.email}?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);
            } else {
                toast.current.show({ severity: 'error', summary: 'Erro ao obter falta', detail: error.toString() });    
            }
              
        }

        setIsLoading(false)
    }, [filter,sortable,sortableCampo,user.email]);

   useEffect(() => {
        const fetchFaltas = async () => {
            try {
                await getFaltas();

            }
            catch (error){
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchFaltas();

    }, [getFaltas])

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2 edit-btn" onClick={() => handleEditClick(rowData)}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-mr-2 p-button-danger" loading={isDeleting} onClick={() => handleDeleteClick(rowData)}/>
            
            </div>
        );
    }

    const codBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const nomeBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Nome</span>
                    <span className="table-data">{rowData.matricula.aluno.nome}</span>
                </p>
            </React.Fragment>
        );
    }

    const observacaoBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Observação</span>
                    <span className="table-data">{rowData.obs}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataOcorrenciaBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Data Ocorrencia</span>
                    <span className="table-data">{new Date(rowData.dataOcorrencia + ' 03:00:00.000').toLocaleDateString()}</span>
                </p>
            </React.Fragment>
        );
    }

    const onSort = (event) => {
        setSortableCampo(event.sortField === "matriculaAlunoNome" ? "matricula.aluno.nome" : event.sortField);
        if(sortable === 'ASC'){
            setSortable('DESC')
        } else if (sortable === 'DESC') {
            setSortable('ASC')
        }
    }

    const onFilter = (event) => {
        event['first'] = 0;
        // setLazyParams(event);
        setFilter(event);   
    }
  
    const handleEditClick = (data) => {

        history.push(`/falta/edit/${data.id}`);
    }
    
    const handleDeleteClick = (data) => {
        data['userEmail'] = user.email

        confirmDialog({
            message: `Você tem certeza que quer remover ${data.matricula.aluno.nome}?`,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: async () => {
                setIsDeleting(true);
                try {
                    const faltasSeguidas =  await api.get(`falta/faltasSeguidas/${data.matricula.id}`);
                    if (faltasSeguidas.data.length === 3)
                    {
                        let result = await api.get(`matricula/${data.matricula.id}`);
                        let status = await api.get(`historicoStatus/matricula/${data.matricula.id}`);

                        let data1 = {
                            id: result.data.id,
                            conclusao: result.data.conclusao,
                            dataMatricula: result.data.dataMatricula,
                            notaFinal: result.data.notaFinal,
                            status: status.data[0].status,
                            alunoId: result.data.alunoId,
                            instrumentoId: result.data.instrumentoId,
                            periodoId: result.data.periodoId,
                            turmaId: result.data.turmaId,
                        }
                        if (faltasSeguidas.data[0].matriculaStatus === "DESISTENTE")
                        {
                            setTimeout(async () => {
                                await api.put("matricula", data1);
        
                            }, 2000)
                            
                        }
                        
                    }
                    await api.delete(`falta/${data.id}`);       
                    toast.current.show({ life: 5000, severity: 'success', summary: 'Remoção concluída!', detail: `Falta de ${data.matricula.aluno.nome} removido(a) com sucesso` });
                    getFaltas()
                }
                catch (error) {
                    toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover a falta', detail: error.response.data });
                }

                setIsDeleting(false);
            },
            reject: () => { return }
        });
    }

    const onDataSearchChange = (event) => {
        if (event.value !== null) {
            let data = event.value.toISOString().slice(0, 10);
            dt.filter(data, 'dataOcorrencia', 'equals');
            setSelectedData(event.value);
        }
        else
            dt.filter(null, 'dataOcorrencia', 'equals');
    }

    const onDataInputChange = (event) => {
        if (event.target.value !== null) {
            let data = new Date(event.target.value.split('/').reverse().join('/')).toISOString().slice(0, 10);
            dt.filter(data, 'dataOcorrencia', 'equals');
            setSelectedData(new Date(event.target.value.split('/').reverse().join('/')));
        }
        else
            dt.filter(null, 'dataOcorrencia', 'equals');
    }

    return (
        <>
        <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            
            <Toast ref={confirmationToast} position="bottom-center" />
            
            <div className="card p-col-12 datatable-responsive-demo">
                
                <div className="p-fluid">
                    
                    <DataTable      
                        totalRecords={totalRecords}  
                        lazy="true"
                        first={first} 
                        onPage={getFaltas}       
                        ref={(element) => setDt(element)}
                        header={header}
                        value={faltas}
                        filterDisplay="row"
                        className="p-datatable-responsive-demo"
                        emptyMessage="Nenhum falta encontrada"
                        loading={isLoading}
                        dataKey="id"
                        paginator
                        rows={20}
                        currentPageReportTemplate={`Mostrando de {first} a {last} de ${totalRecords} faltas`}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                        // rowsPerPageOptions={[10, 20, 30]} 
                        onSort={onSort}                       
                        onFilter={onFilter}
                       filters={filter.filters}
                    >

                        <Column
                            field="id"
                            header="Código"
                            sortable={sortable}
                            body={codBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.id !== undefined && filter.filters.id.value != null) ? filter.filters.id.value : "Pesquisar pelo Código"}
                            
                        />
                        
                        <Column
                            field="matriculaAlunoNome"
                            header="Nome"
                            sortable={sortable}
                            body={nomeBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.matriculaAlunoNome !== undefined && filter.filters.matriculaAlunoNome.value !== "") ? filter.filters.matriculaAlunoNome.value : "Pesquisar pelo nome"}
                            
                        />

                        <Column
                            field="obs"
                            header="Observação"
                            sortable={sortable}
                            body={observacaoBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.obs !== undefined && filter.filters.obs.value !== "") ? filter.filters.obs.value : "Pesquisar pela observação"}
                            
                        />

                        <Column
                            field="dataOcorrencia"
                            header="Data Ocorrencia"
                            sortable={sortable}
                            body={dataOcorrenciaBodyTemplate}                     
                            filter
                            filterElement={
                                <BrCalendar
                                    key={selectedData}
                                    id="data"
                                    name="data"
                                    value={selectedData}
                                    onSelect={(e) => onDataSearchChange(e)}
                                    onInput={(e) => onDataInputChange(e)}
                                    yearRange={`2000:${new Date().getFullYear()}`}
                                    placeholder="Selecione uma data"
                                    onClearButtonClick={() => handleLimparClick()}
                                />
                            }
                        />

                        <Column
                            header="Ações"
                            body={actionBodyTemplate}
                            
                        />
    
                    </DataTable>
                    
                </div>
            </div>
        </div>
       
        </>
    );
}