import React, { useRef, useState, useContext, useEffect, useCallback } from 'react';
import { classNames } from 'primereact/utils';
import { InputNumber} from 'primereact/inputnumber';
import { useHistory, useParams } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { BrCalendar } from './BrCalendar';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorValidationMessage } from './ErrorValidationMessage';
import { AuthContext } from '../contexts/AuthContext'
import api from '../api/api';
import '../layout/sass/_form.scss';
import { TabelaEditarNotas } from './TabelaEditarNotas';
import { Dropdown } from 'primereact/dropdown';
import { TabelaAdicionarNotas } from './TabelaAdicionarNotas';

export const FormAvaliacao = ({ isEditing, isLoading, defaultValues }) => {

    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)
    const { user } = useContext(AuthContext);
    const [turma, setTurma] = useState("");
    const { id } = useParams();
    const [turmaTemplate, setTurmaTemplate] = useState("");
    const [alunos, setAlunos] = useState([])

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const schema = Yup.object().shape({
        turmaId: Yup
            .number()
            .positive("A turma deve ser selecionado")
            .integer("A turma deve estar na lista"),
        data: Yup
            .date()
            .typeError("Uma data deve ser inserida")
            .required("A data é obrigatória"),         
        tipo: Yup
            .string()
            .required("O tipo de avaliação é obrigatório"),
        notaTotal: Yup
            .string()
            .required("A nota total é obrigatório"),
        
    })

    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)                        
            
            try {                

                if (!isEditing) {
                    const dataAvaliacao = await api.post("avaliacao", data)
                    let data1 = {id: null,
                        data: '',
                        tipo: '',
                        notaTotal: ''
                    }
                    alunos.forEach(async item => {
                        data1 = {
                            matriculaId: item.id,
                            avaliacaoId: dataAvaliacao.data.id,
                            notaAluno: item.notaAluno === undefined ? 0 : item.notaAluno 
                        }
                        await api.post("nota", data1);
                        
                    }); 
                   
                    formik.resetForm()
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `Adicionado com sucesso` });
                    setTimeout(() => {
                        alunos.forEach(async item => {
                            await setNotaFinal(item.id);
                        }); 
                        history.push('/avaliacao')

                    }, 2000)

                }
                else {
                    let result = await api.get(`avaliacao/${data.id}`);
                    let nota = result.data.nota;
                    let data1 = {
                        id: data.id,
                        data: data.data,
                        tipo: data.tipo,
                        nota: nota,
                        notaTotal: data.notaTotal
                    }
                    await api.put("avaliacao", data1)
                    
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `Avaliação atualizada com sucesso` });
                    setTimeout(() => {
                        
                        history.push('/avaliacao')

                    }, 2000)
                }
            }
            catch (error) {
                if (!isEditing)
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao cadastrar avaliação!', detail: error.toString() });
                else
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar avaliação!', detail: error.toString() });

            }

            setIsSubmiting(false)
        }
    })

    const handleTurmaClick = async (e) => {
        try {
            formik.setFieldValue("turmaId",e.value);
            const response = await api.get(`matricula/turma/${e.value}`);
            const itens = response.data.map((i) => { return { id: i.id, matriculaNome: i.aluno.nome, notaAluno: i.notaAluno} });
            setAlunos(itens);
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter alunos', detail: error.toString() });
        }
    }
    
    const setNotaFinal = async (matriculaId) => {
        try {
            const response = await api.get(`nota/soma/${matriculaId}`);
            let tempMatricula = {
                'id': response.data.matricula.id,
                'alunoId': response.data.matricula.aluno.id,
                'conclusao': response.data.matricula.conclusao,
                'cpf': response.data.matricula.aluno.cpf,
                'dataMatricula': response.data.matricula.dataMatricula,
                'turmaId': response.data.matricula.turma.id,
                'instrumentoId': response.data.matricula.instrumento.id,
                'notaFinal': response.data.notaTotal,
                'status': response.data.matricula.status,
                'periodoId': response.data.matricula.periodoId
            }
            await api.put("matricula", tempMatricula)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao atualizar nota', detail: error.toString() });
        }
    }

    const getTurma = useCallback(async () => {
        try {
            if(user.perfilUser[0] !== 'user-instrutor') {
                const response = await api.get(`turma/turmaAllAtual`);
                setTurma(response.data.map(turma => {
                    var turm = {label: '', value: ''};
                    turm.label = turma.ano + " - " + turma.cursoNome +  " - " + turma.diaSemana + "  " +turma.hora
                    turm.value = turma.id 
                    return turm
                }))
            } else {
                const response = await api.get(`turma/turmaAllAtualInstrutor/${user.email}`);
                setTurma(response.data.map(turma => {
                    var turm = {label: '', value: ''};
                    turm.label = turma.ano + " - " + turma.cursoNome +  " - " + turma.diaSemana + "  " +turma.hora
                    turm.value = turma.id 
                    return turm
                }))                
            }

            if (isEditing)
            {
                const response = await api.get(`nota/avaliacao/${id}`);

                setTurmaTemplate(response.data[0].matricula.turma.ano + " - " + response.data[0].matricula.turma.curso.nome +  " - " + response.data[0].matricula.turma.diaSemana + "  " + response.data[0].matricula.turma.hora)
            }
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter turmas', detail: error.toString() });
        }
    }, [user.perfilUser,isEditing,user.email,id]);

    useEffect(() => {

        async function avaliacao() {
            await getTurma();
        };
        avaliacao();

    }, [getTurma])

    return (
        <>
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <div className="p-col-12">
                <h2 className="p-mt-0">Avaliação</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-fluid p-mx-0">
                    <div className="p-field p-col-12 p-sm-3">
                            <label
                                htmlFor="turmaId"
                                className={classNames({ 'p-error': isFormFieldValid('turmaId') })}
                            >
                                Turma
                            </label>
                            {
                                !isLoading ?
                                <Dropdown
                                    id="turmaId"
                                    name="turmaId"
                                    options={turma}
                                    emptyMessage={"Nenhuma turma encontrada"}
                                    emptyFilterMessage={"Nenhuma turma encontrado"}
                                    filter={true}
                                    value={formik.values.turmaId}
                                    onChange={(e) => handleTurmaClick(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('turmaId') })}
                                    placeholder={isEditing ? turmaTemplate : "Selecione uma turma"}
                                    showClear={true} 
                                    disabled={isEditing}
                                    // disabled={disabled}

                                />  
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.turmaId && formik.touched.turmaId &&
                                <ErrorValidationMessage message={formik.errors.turmaId} />
                            }
                        </div>
                        <div className="p-field p-col-12 p-sm-4 p-md-3">
                                <label
                                    htmlFor="data"
                                    className={classNames({ 'p-error': isFormFieldValid('data') })}
                                >
                                    Data 
                                </label>
                                <BrCalendar
                                    id="data"
                                    name="data"
                                    value={formik.values.data}
                                    onChange={formik.handleChange}
                                    placeholder={ isEditing ? new Date(formik.values.data).toLocaleDateString('pt-BR') : "" }
                                    className={classNames({ 'p-invalid': isFormFieldValid('data') })}
                                    yearRange={`2000:${new Date().getFullYear()}`}
                                />
                                {formik.errors.data && formik.touched.data &&
                                    <ErrorValidationMessage message={formik.errors.data} />
                                }
                        </div>

                        <div className="p-field p-col-12 p-sm-3">
                                <label
                                    htmlFor="tipo"
                                    className={classNames({ 'p-error': isFormFieldValid('tipo') })}
                                    style={{ textAlign: 'justify', textJustify: 'distribute' }}
                                >
                                    Tipo de Avaliacao
                                </label>
                                {
                                    !isLoading ?
                                        <Dropdown
                                            id="tipo"
                                            name="tipo"
                                            value={formik.values.tipo}
                                            options={formik.values.tipoAvaliacao}
                                            emptyMessage="Nenhum dia encontrado"
                                            emptyFilterMessage={"Nenhum dia encontrado"}
                                            filter={true}
                                            onChange={(e) => formik.setFieldValue("tipo", e.value)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('tipo') })}
                                            placeholder="Selecione o tipo de perfil"
                                            showClear={true}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }

                                {formik.errors.tipo && formik.touched.tipo &&
                                    <ErrorValidationMessage message={formik.errors.tipo} />
                                }
                            </div>

                        <div className="p-field p-col-12 p-sm-3">
                            <label
                                htmlFor="sigla"
                                className={classNames({ 'p-error': isFormFieldValid('sigla') })}
                            >
                                Nota Total
                            </label>
                            {
                                !isLoading ?
                                    <InputNumber
                                        id="notaTotal"                                    
                                        name="notaTotal"    
                                        mode="decimal" minFractionDigits={2}                                                                    
                                        className={classNames({ 'p-invalid': isFormFieldValid('notaTotal') })}
                                        onChange={(e) => formik.setFieldValue("notaTotal", e.value)}
                                        locale="pt-BR"
                                        value={formik.values.notaTotal}
                                    /> 
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.notaTotal && formik.touched.notaTotal &&
                                <ErrorValidationMessage message={formik.errors.notaTotal} />
                            }
                        </div>                       

                    </div>

                    <div className="p-d-flex p-jc-end p-mr-1" >
                        {
                            !isEditing ?
                                <TabelaAdicionarNotas alunos={alunos} setAlunos={setAlunos} avaliacao={formik.values.notaTotal} />
                            :
                                <></>
                        }

                    </div>

                    <div className="p-d-flex p-jc-end p-mr-1">
                        {
                            !isLoading ?
                                <Button
                                    label={isEditing ? 'Atualizar' : 'Salvar'}
                                    type="submit"
                                    iconPos="right"
                                    loading={isSubmiting}
                                />
                                :
                                <Skeleton width="82.16px" height="35px" />

                        }
                    </div>
                </form>
                <div className="p-col-12">
                { 
                    isEditing ?
                        <TabelaEditarNotas
                            avaliacaoId={defaultValues.id}
                            avaliacao={formik.values.notaTotal}
                        />
                    :
                        <></>
                }
                
                </div>
            </div>
            
        </div>
        
    </>
    );
}