import React, { useContext } from 'react';
import { TabelaLicenca } from '../../components/TabelaLicenca';
import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';
import { AuthContext } from '../../contexts/AuthContext';
import { TabelaLicencaInstrutor } from '../../components/TabelaLicencaInstrutor';

export default function LicencaPage() {
  const { user } = useContext(AuthContext);

  return (
    <div className="p-pt-0">
      <MenuMain >
      {user.perfilUser[0] !== 'user-instrutor' ?
       <TabelaLicenca />
        :
        <TabelaLicencaInstrutor />
      }
      </MenuMain>
      <AppFooter />
    </div>
  );
}