import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import { Dropdown } from 'primereact/dropdown';
import { Card } from 'primereact/card';
import { AuthContext } from '../contexts/AuthContext';
import api from '../api/api';
import { FormAgenda } from './FormAgenda';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { useHistory } from "react-router-dom";
import { Toast } from 'primereact/toast';
import { FormTurmaAgenda } from './FormTurmaAgenda';

function FullCalendarApp() {

    const toast = useRef(null);
    const [events, setEvents] = useState([]);
    const [instrutores, setInstrutores] = useState([]);
    const [instrutorSelected, setInstrutorSelected] = useState(null);
    const [agendaNome, setAgendaNome] = useState("Agenda Semanal");
    const { user } = useContext(AuthContext);
    const [agenda, setAgenda] = useState([]);
    const [showAgenda, setShowAgenda] = useState(false);
    const [showDeleteAgenda, setShowDeleteAgenda] = useState(false);
    const [loadingAgenda, setLoadingAgenda] = useState(false);
    const [atualizarAgenda, setAtualizarAgenda] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const getAgenda = useCallback(async (receptorId) => {
            const respAgenda = await api.get(`/turma/agenda/${receptorId}`);
            setEvents(respAgenda.data);
    }, []);
    const [turmas, setTurmas] = useState([]);
    const [loadingTurma, setLoadingTurma] = useState(false);
    const [showTurma, setShowTurma] = useState(false);
    const history = useHistory();

    useEffect(() => {

        async function calendar() {
            const resInstrutor = await api.get(`/perfil/instrutor`);
            const instrutor = await api.get(`/perfil/instrutor/${user.email}`);
            if (user.perfilUser[0] !== 'user-instrutor' && resInstrutor?.data?.length > 0)
            {
                setInstrutores(resInstrutor.data);
            }
            else
            {
                setInstrutores([instrutor.data]);
                setInstrutorSelected(instrutor.data.id)
            }
            instrutorSelected && (
                await getAgenda(instrutorSelected)
            );
        };
        calendar();

    }, [getAgenda,instrutorSelected,atualizarAgenda]);
    
    const handleDeleteClick = (data) => {

        data['userEmail'] = user.email;

        confirmDialog({
            message: `Você tem certeza que quer remover essa turma?`,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: async () => {

                try {

                    await api.delete(`turma/${data.id}`);       
                    toast.current.show({ life: 5000, severity: 'success', summary: 'Remoção concluída!', detail: `Turma essa turma removido(a) com sucesso` });
                    setAtualizarAgenda(Math.random());
                }
                catch (error) {
                    toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover o turma', detail: error.response.data });
                }

            },
            reject: () => { return }
        });
    }

    const handleAdicionarClick = () => {
        setLoadingAgenda(true);
        setIsEditing(false);
        setAgenda({
            'diaSemana': '',
            'diaSemanas': ['DOMINGO', 'SEGUNDA', 'TERÇA', 'QUARTA', 'QUINTA', 'SEXTA', 'SÁBADO'],
            'hora': '',
            'receptorId': '',
            'emissorId': '',
            'titulo': '',
            'semanal': '',
            'data': '',
        })
        setShowAgenda(true);
        setLoadingAgenda(false);
    }

    const handleEventClick = async (e) => {
        setLoadingAgenda(true);
        if (e._def.ui.backgroundColor === "#E74C3C")
        {
            const agenda = await api.get(`/agenda/${e.id}`);
            confirmDialog({
                message: `Deseja editar ou deletar o planejamento?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Editar',
                rejectLabel: 'Deletar',
                accept: async () => {
                    setIsEditing(true);
                    let hora = agenda.data.hora.toString();
                    let hora2 = hora.substring(0, hora.length - 3)
                    setAgenda({
                        'id': agenda.data.id,
                        'diaSemana': agenda.data.diaSemana,
                        'diaSemanas': ['DOMINGO', 'SEGUNDA', 'TERÇA', 'QUARTA', 'QUINTA', 'SEXTA', 'SÁBADO'],
                        'hora': hora2,
                        'receptorId': agenda.data.receptorId,
                        'emissorId': agenda.data.emissorId,
                        'titulo': agenda.data.titulo,
                        'semanal': agenda.data.semanal,
                        'data': agenda.data.data,
                    })
                    setShowAgenda(true);
                },
                reject: () => { 
                    let hora = agenda.data.hora.toString();
                    let hora2 = hora.substring(0, hora.length - 3)
                    setAgenda({
                        'id': agenda.data.id,
                        'diaSemana': agenda.data.diaSemana,
                        'diaSemanas': ['DOMINGO', 'SEGUNDA', 'TERÇA', 'QUARTA', 'QUINTA', 'SEXTA', 'SÁBADO'],
                        'hora': hora2,
                        'receptorId': agenda.data.receptorId,
                        'receptorNome': agenda.data.receptorNome,
                        'emissorId': agenda.data.emissorId,
                        'emissorEmail': agenda.data.emissorEmail,
                        'titulo': agenda.data.titulo,
                        'semanal': agenda.data.semanal,
                        'data': agenda.data.data,
                    })
                    setShowDeleteAgenda(true);
                }
            });
        }
        else
        {
            if (user.perfilUser[0] !== 'user-instrutor')
            {
                let data = {
                    "id": e.id,
                    "userEmail": "",
                }
                confirmDialog({
                    message: `Deseja editar ou deletar a turma?`,
                    header: 'Confirmação',
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: 'Editar',
                    rejectLabel: 'Deletar',
                    accept: async () => {
                        setLoadingTurma(true);
                        const response = await api.get(`turma/${e.id}`)
                        let hora = response.data.hora.toString();
                        let hora2 = hora.substring(0, hora.length - 3)
                        setTurmas({
                            'id': response.data.id,
                            'ano': response.data.ano,
                            'diaSemana': response.data.diaSemana,
                            'diaSemanas': ['DOMINGO', 'SEGUNDA', 'TERÇA', 'QUARTA', 'QUINTA', 'SEXTA', 'SÁBADO'],
                            'hora': hora2,
                            'qtdeVagas': response.data.qtdeVagas,
                            'sala': response.data.sala,
                            'cursoId': response.data.cursoId,
                            'perfilId': response.data.perfilId,
                        })
                        setShowTurma(true);
                        setLoadingTurma(false);
                        
                    },
                    reject: () => { 
                        if (e._def.ui.backgroundColor === "#EB4D39")
                        {
                            handleDeleteClick(data);
                        }
                        else
                        {
                            toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover o turma', detail: "Turma contém alunos, não é permitido deletar" });
                        }
                    }
                });
               
            }
        }
        setLoadingAgenda(false);
    }

    return (
        <>
        <FormTurmaAgenda
            defaultValues={turmas}
            visible={showTurma}
            setVisible={setShowTurma}
            isLoading={loadingTurma}
            setAtualizarAgenda={setAtualizarAgenda}
        />
        <Toast ref={toast} position="bottom-right" />
            <FormAgenda
                defaultValues={agenda}
                visible={showAgenda}
                setVisible={setShowAgenda}
                visibleDelete={showDeleteAgenda}
                setVisibleDelete={setShowDeleteAgenda}
                isLoading={loadingAgenda}
                setAtualizarAgenda={setAtualizarAgenda}
                isEditing={isEditing}
            />
            <Card title={agendaNome}>
            <div className="p-my-0 p-grid p-dir-rev p-col">
                <div className="p-m-2">
                <Button
                    label="Adicionar"
                    className="p-mb-2 p-button-primary"
                    icon="pi pi-plus"
                    iconPos="left"
                    type="button"
                    onClick={() => handleAdicionarClick()}

                />
                </div>
            </div>
                <div className="p-field p-col-12 p-sm-4 p-md-4">
                    
                <Dropdown
                    id="instrutor"
                    name="instrutor"
                    options={instrutores}
                    value={instrutorSelected}
                    onChange={(e) => setInstrutorSelected(e.value)}
                    filter
                    optionLabel="nome"
                    optionValue="id"
                    placeholder="Selecione um Instrutor"
                />
                </div>

                <div className="App">

                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        initialView="timeGridWeek"
                        headerToolbar={{
                            center: 'dayGridMonth,timeGridWeek,timeGridDay',
                        }}
                        customButtons={{
                            new: {
                                text: 'new',
                                click: () => console.log('new event'),
                            },
                        }}
                        firstDay={1}
                        slotMinTime='07:00'
                        slotMaxTime="22:00"
                        events={events}
                        nowIndicator
                        dateClick={(e) => console.log(e.dateStr)}
                        eventClick={(e) => handleEventClick(e.event)}
                        datesSet={(e) => {
                            const view = e.view;
                            if (view.type === "timeGridWeek") {
                              setAgendaNome("Agenda Semanal");
                            } else if (view.type === "timeGridDay" || view.type === "timeGrid") {
                              setAgendaNome("Agenda Diária");
                            } else if (view.type === "dayGridMonth") {
                              setAgendaNome("Agenda Mensal");
                            } else {
                              setAgendaNome("");
                            }
                          }}         
                        locale={ptBrLocale}
                    />
                </div>
            </Card>
        </>
    );
}

export default FullCalendarApp;