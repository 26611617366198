import React from 'react';

import { TabelaTurmas } from '../../components/TabelaTurmas';
import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';

export default function TurmasPage() {

  return (
    <>
      <MenuMain >
        <TabelaTurmas />
      </MenuMain>
      <AppFooter />
    </>
  );
}
