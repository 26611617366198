import React from 'react';
import MenuMain from '../../MenuMain';
import { FormMatriculas } from '../../components/FormMatriculas';
import { AppFooter } from '../../AppFooter';


export default function CadastrarMatriculasPage() {

  const defaultValues = {
    'dataMatricula': '',
    'conclusao': '',
    'notaFinal': '',
    'status': '',
    'statusLista': ['MATRICULADO', 'REMATRICULADO', 'TRANCADO', 'DESISTENTE'],
    'alunoId': '',
    'instrumentoId': '',
    'turmaId': '',


  }
  

  return (
    <div>
      <MenuMain>
         <FormMatriculas
          defaultValues={defaultValues}
        />
      </MenuMain>
      <AppFooter />
    </div>
  );
}
