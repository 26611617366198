import React, { useRef, useEffect, useState, useCallback } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';

export const TabelaNotasMatricula = ({ notas, visible, setVisible }) => {
    const toast = useRef(null);
    const confirmationToast = useRef(null)

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Notas</h2>
            </div>
        </div>

    );

    const nomeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Nome do Aluno</span>
                    <span className="table-data">{rowData.matriculaAlunoNome}</span>
                </p>
            </React.Fragment>
        );
    }

    const notaAlunoBodyTemplate = (rowData) => {
        const porcentagem = (rowData.notaAluno / rowData.avaliacaoNotaTotal) * 100;

        let color = 'black'; 
    
        if (porcentagem === 100) {
            color = 'green'; 
        } else if (porcentagem >= 60) {
            color = 'orange'; 
        } else if (porcentagem < 60) {
            color = 'red'; 
        }
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Nota do Aluno</span>
                    <span className="table-data" style={{color: color}}>{rowData.notaAluno}</span>

                </p>
            </React.Fragment>
        );
    }

    const instrutorBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Instrutor</span>
                    <span className="table-data">{rowData.matriculaTurmaPerfilNome}</span>
                </p>
            </React.Fragment>
        );
    }

    const turmaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Turma</span>
                    <span className="table-data">{rowData.matriculaTurmaCursoNome}</span>
                </p>
            </React.Fragment>
        );
    }

    const notaTotalBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Nota Total</span>
                    <span className="table-data">{rowData.avaliacaoNotaTotal}</span>
                </p>
            </React.Fragment>
        );
    }

    const tipoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Tipo Avaliação</span>
                <span className="table-data">{rowData.avaliacaoTipo}</span>
            </React.Fragment>
        );
    }

    return (
        <>
            <Dialog
                visible={visible}
                onHide={() => setVisible(false)}
                className='p-scrollpanel-bar-y'
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ width: '65vw', heigth: '10vw' }}
            >
                <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

                    <Toast ref={toast} position="bottom-right" />

                    <Toast ref={confirmationToast} position="bottom-center" />

                    <div className="card p-col-12 datatable-responsive-demo">

                        <div className="p-fluid">

                            <DataTable style={{ width: '100%' }}
                                lazy="true"
                                header={header}
                                value={notas}
                                filterDisplay="row"
                                className="p-datatable-responsive-demo"
                                emptyMessage="Nenhuma falta encontrada"
                                dataKey="id"
                                rows={50}
                            >

                                <Column
                                    field="matriculaAlunoNome"
                                    header="Nome do Aluno"
                                    body={nomeBodyTemplate}

                                />

                                <Column
                                    field="notaAluno"
                                    header="Nota Aluno"
                                    body={notaAlunoBodyTemplate}

                                />

                                <Column
                                    field="avaliacaoNotaTotal"
                                    header="Valor Avaliação"
                                    body={notaTotalBodyTemplate}

                                />
                                
                                <Column
                                    field="matriculaTurmaPerfilNome"
                                    header="Instrutor"
                                    body={instrutorBodyTemplate}

                                />

                                <Column
                                    field="matriculaTurmaCursoNome"
                                    header="Turma"
                                    body={turmaBodyTemplate}

                                />

                                <Column
                                    field="avaliacaoTipo"
                                    header="Tipo de Avaliação"
                                    sortable
                                    body={tipoBodyTemplate}

                                />

                            </DataTable>

                        </div>
                    </div>
                </div>

            </Dialog>
        </>
    );
}