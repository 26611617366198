import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';
import '../layout/sass/_form.scss';

export const FormIndisponivel = () => {
    const toast = useRef(null);

    return (
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <div className="p-col-12">
                <h2 className="p-mt-0">Indisponível</h2>
                <p>Fora do período de inscrição.</p>
            </div>
        </div>
    
    );
}