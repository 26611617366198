import React, { useRef, useEffect, useState, useCallback } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import '../layout/sass/_pickListSugestoes.scss'

import '../layout/sass/_responsiveTable.scss'

import api from '../api/api';

export const TabelaEditarNotas = ({ avaliacaoId, avaliacao }) => {
    const toast = useRef(null);
    const confirmationToast = useRef(null)
    const [notas, setNotas] = useState([])
    
    const codigoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.matriculaId}</span>
                </p>
            </React.Fragment>
        );
    }

    const matriculaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Nome do Aluno</span>
                    <span className="table-data">{rowData.alunoNome}</span>
                </p>
            </React.Fragment>
        );
    }

    const notaAlunoBodyTemplate = (rowData) => {
        
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Nota do Aluno</span>
                    <InputNumber onValueChange={(e) => handleEditNota(rowData,e)} mode="decimal" minFractionDigits={2} value={rowData.notaAluno == null ? 0.00 : rowData.notaAluno}/>
                </p>
            </React.Fragment>
        );
    }

    const setNotaFinal = async (matriculaId) => {
        try {
            const response = await api.get(`nota/soma/${matriculaId}`);
            let tempMatricula = {
                'id': response.data.matricula.id,
                'alunoId': response.data.matricula.aluno.id,
                'conclusao': response.data.matricula.conclusao,
                'cpf': response.data.matricula.aluno.cpf,
                'dataMatricula': response.data.matricula.dataMatricula,
                'turmaId': response.data.matricula.turma.id,
                'instrumentoId': response.data.matricula.instrumento.id,
                'notaFinal': response.data.notaTotal,
                'status': response.data.matricula.status,
                'periodoId': response.data.matricula.periodoId
            }
            await api.put("matricula", tempMatricula)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao atualizar nota', detail: error.toString() });
        }
    }

    const handleEditNota = async (data,e) => {
        const temp = {
            "id": data.id,
            "notaAluno": e.value,
        }
        try {
            if (temp.notaAluno <= avaliacao && temp.notaAluno != null)
            {
                api.put("nota/updateNotas", temp)
                setTimeout(async () => {
                    await setNotaFinal(data.matriculaId);
                }, 2000)
                toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro Alterado!', detail: `${data.alunoNome} alterado com sucesso` });
            }
            else
            {
                toast.current.show({ life: 2000, severity: 'error', summary: 'Valor inválido!', detail: `Erro ao alterar ${data.alunoNome} `});

            }

        }
        catch (error) {

            toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao alterar!', detail: error.toString() });

        }

    }

    const onRowEditComplete1 = (e) => {
        let notas2 = [...notas];
        let { newData, index } = e;

        notas2[index] = newData;

        setNotas(notas2);
    }
   
    const getAvaliacoes = useCallback(async () => {
        try {
            if(avaliacaoId !== undefined) {
                const response = await api.get(`nota/avaliacao/${avaliacaoId}`);
                const tempNotas = response.data.map((item) => {
                    // console.log(item)
                    return ({
                        "id": item.id,
                        "matriculaId": item.matricula.id,
                        "notaAluno": item.notaAluno,
                        "alunoNome": item.matricula.aluno.nome,
                    }
                    )
    
                })
                setNotas(tempNotas);
            }            
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter notas na avaliação', detail: error.toString() });
        }
    }, [avaliacaoId]);

    useEffect(() => {

        async function editarNota() {
            await getAvaliacoes();
        };
        editarNota();

    }, [getAvaliacoes]);

    return (
        <>

            <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

                <Toast ref={toast} position="bottom-right" />

                <Toast ref={confirmationToast} position="bottom-center" />

                <div className="card p-col-12 datatable-responsive-demo">

                    <div className="p-fluid">

                        <DataTable
                            editMode="row"
                            onRowEditComplete={onRowEditComplete1}
                            responsiveLayout="scroll"
                            value={notas}
                            className="p-datatable-responsive-demo"
                            emptyMessage="Nenhuma nota encontrada"
                            dataKey="id"
                            paginator
                            rows={20}
                            currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} nota"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            rowsPerPageOptions={[20, 30]}
                            sortField="nome"
                            sortOrder={1}
                            removableSort
                        >

                        <Column style={{ width: '12%' }}
                            field="matriculaId"
                            header="Matrícula"
                            sortable
                            body={codigoBodyTemplate}
                        />

                        <Column
                            field="matriculaNome"
                            header="Nome do Aluno"
                            sortable
                            body={matriculaBodyTemplate}

                        />

                        <Column
                            field="notaAluno"
                            header="Nota Aluno"
                            body={notaAlunoBodyTemplate}
                            style={{ width: '20%' }}>

                        </Column>

                    </DataTable>

                    </div>
                </div>
            </div>

        </>
    );
}