import React, { useState, createContext, useCallback } from 'react';

export const UserMenuContext = createContext({})

export default function UserMenuProvider({ children }) {

    const [perfil, setPerfil] = useState({});
    const [errorGettingData, setErrorGettingData] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const getUserData = useCallback(async () => {
        setIsLoading(true);

        setIsLoading(false);
    }, [])

    return (
        <UserMenuContext.Provider
            value={{
                perfil,
                setPerfil,
                isLoading,
                setIsLoading,
                getUserData,
                errorGettingData,
                setErrorGettingData,
                errorMessage,
                setErrorMessage,
            }}
        >
            {children}
        </UserMenuContext.Provider>
    );
}

