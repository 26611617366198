import React, { useRef, useEffect, useState, useContext, useCallback} from 'react';

import { useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { DialogTurmas } from './DialogTurmas';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';
import {FilterContext} from '../contexts/FilterContext';

import {AuthContext} from '../contexts/AuthContext';
import { Calendar } from 'primereact/calendar';
import { InputMask } from 'primereact/inputmask';

export const TabelaTurmas = () => {
    const toast = useRef(null);
    const [turmasTotal, setTurmasTotal] = useState([]);
    const [turmas, setTurmas] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const confirmationToast = useRef(null)
    const [dt, setDt] = useState(null);
    const history = useHistory();
    const [showListaTurmas, setShowListaTurmas] = useState(false);        
    const { user } = useContext(AuthContext);
    const [sortable, setSortable] = useState('DESC');
    const [sortableCampo, setSortableCampo] = useState('ano');
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [tipos, setTipos] = useState([]);
    const filterStatus = renderFilterStatus();
    const { filter, setFilter } = useContext(FilterContext);
    const [selectedHora, setSelectedHora] = useState(null);

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Turmas</h2>
            </div>
            <div className="p-my-0 p-grid p-dir-rev p-col">
                <div className="p-m-2">
                <Button
                    label="Adicionar"
                    className="p-mb-2 p-button-primary"
                    icon="pi pi-plus"
                    iconPos="left"
                    type="button"

                    onClick={() => history.push('/turmascreate')}

                />
                </div>
                <div className="p-m-2">
                    <Button
                        label="Limpar Filtros"
                        className="p-mb-2 p-button-primary"
                        iconPos="left"
                        type="button"
                        onClick={() => handleLimparClick()}
                    />
                </div>
            </div>
        </div>
        
    );

    const handleLimparClick = () => {
        setFilter({
            first: 0,
            rows: 20,
            sortField: null,
            sortOrder: null,
            filters: {
            },
            
        });
        setSelectedStatus(null);
        setSelectedHora(null);
    }

    const getTurmas = useCallback(async (event) => {
        setIsLoading(true)
        setTurmas([])

        // console.log(event)   

        if(event !== undefined) {
            setTimeout(() => {
                const startIndex = event.first;

                setFirst(startIndex);
                // setTurmas(dataSource.slice(startIndex, endIndex));            
            }, 250);
        }
       
        try {

            // console.log(lazyParams.filters);
            if(filter.filters === undefined) 
            {

                if(event === undefined) {
                    const response = await api.get(`turma?sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(turma => {
                        setTurmas(turmas => [...turmas, turma])
                    })                
                } else {                   
                    const response = await api.get(`turma?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(turma => {
                        setTurmas(turmas => [...turmas, turma])
                    })  
                    
                }

                const respElements = await api.get(`turma?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);

            } 
            else 
            {             
                const pesquisa = {
                    "perfilNome": filter.filters['perfilNome'] !== undefined ? filter.filters['perfilNome'].value : "",
                    "ano": filter.filters['ano'] !== undefined ? filter.filters['ano'].value : null,
                    "diaSemana": filter.filters['diaSemana'] !== undefined ? filter.filters['diaSemana'].value : null,
                    "hora2": filter.filters['hora2'] !== undefined ? filter.filters['hora2'].value : null,
                    "qtdeVagas": filter.filters['qtdeVagas'] !== undefined ? filter.filters['qtdeVagas'].value : null,
                    "sala": filter.filters['sala'] !== undefined ? filter.filters['sala'].value : "",
                    "cursoNome": filter.filters['cursoNome'] !== undefined ? filter.filters['cursoNome'].value : "",
                    "id": filter.filters['id'] !== undefined ? filter.filters['id'].value : null,
                    "qtdeAlunos": filter.filters['qtdeAlunos'] !== undefined ? filter.filters['qtdeAlunos'].value : null,
                }
                // console.log(pesquisa)
                if (event === undefined) {  
                    const respElements = await api.post(`turma/searchTurma/${sortable}/${sortableCampo}`, pesquisa);
                    respElements.data.content.forEach(turma => {
                        setTurmas(turmas => [...turmas, turma])
                    })
                    setTotalRecords(respElements.data.totalElements);
                } else {
                    const respElements = await api.post(`turma/searchTurma/${sortable}/${sortableCampo}?page=${event.page}`, pesquisa);
                    respElements.data.content.forEach(turma => {
                        setTurmas(turmas => [...turmas, turma])
                    })
                    setTotalRecords(respElements.data.totalElements);                    
                }
            }
            
            
        }
        catch (error) {  

            if(filter !== undefined){
                if(event === undefined) {
                    const response = await api.get(`turma?sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(turma => {
                        setTurmas(turmas => [...turmas, turma])
                    })                  
                } else {
                    const response = await api.get(`turma?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(turma => {
                        setTurmas(turmas => [...turmas, turma])
                    }) 
                    
                }

                const respElements = await api.get(`turma?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);
            } else {
                toast.current.show({ severity: 'error', summary: 'Erro ao obter turmas', detail: error.toString() });    
            }
              
        }

        setIsLoading(false)
    }, [filter,sortable,sortableCampo])

    useEffect(() => {
        const fetchTurmas = async () => {
            try {
                await getTurmas();
                
            }
            catch (error){
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchTurmas();

    }, [getTurmas])

    function renderFilterStatus() {
        return (
            <Dropdown
                value={selectedStatus}
                options={tipos}
                onChange={(e) => onStatusSearchChange(e)}
                placeholder="Selecione uma semana"
                className="p-column-filter"
                showClear={true} />
        );
    }

    const onStatusSearchChange = (event) => {
            if (event.value !== null) {
                dt.filter(event.value, 'diaSemana', 'equals');
                setSelectedStatus(event.value);
            }
            else
                dt.filter(null, 'diaSemana', 'equals');
        }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2 edit-btn" onClick={() => handleEditClick(rowData)}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-mr-2 p-button-danger" loading={isDeleting} onClick={() => handleDeleteClick(rowData)}/>
            
            </div>
        );
    }

    const codBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const instrutorBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Instrutor</span>
                    <span className="table-data">{rowData.perfil.nome}</span>
                </p>
            </React.Fragment>
        );
    }

    const anoBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Ano</span>
                    <span className="table-data">{rowData.ano}</span>
                </p>
            </React.Fragment>
        );
    }

    const semanaBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Dia da Semana</span>
                    <span className="table-data">{rowData.diaSemana}</span>
                </p>
            </React.Fragment>
        );
    }

    const horaBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Hora</span>
                    <span className="table-data">{rowData.hora}</span>
                </p>
            </React.Fragment>
        );
    }

    const vagasBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Quantidade de Vagas</span>
                    <span className="table-data">{rowData.qtdeVagas}</span>
                </p>
            </React.Fragment>
        );
    }

    const salaBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Sala</span>
                    <span className="table-data">{rowData.sala}</span>
                </p>
            </React.Fragment>
        );
    }

    const cursoBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Curso</span>
                    <span className="table-data">{rowData.curso.nome}</span>
                </p>
            </React.Fragment>
        );
    }

    const qtdeAlunosBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Qtde. Alunos</span>
                    <span className="table-data">{rowData.qtdeAlunos}</span>
                </p>
            </React.Fragment>
        );
    }

    const onSort = (event) => {    
        setSortableCampo(event.sortField === "perfilNome" ? "perfil.nome" : 
        event.sortField  === "cursoNome" ? "curso.nome" : 
        event.sortField);
        if(sortable === 'ASC'){            
            setSortable('DESC')
        } else if (sortable === 'DESC') {
            setSortable('ASC')
        }
    }

    const onFilter = (event) => {
        event['first'] = 0;
        // setLazyParams(event);
        setFilter(event);
    }

    const handleEditClick = (data) => {

        history.push(`/turmas/edit/${data.id}`);
    }
    
    const handleDeleteClick = (data) => {

        data['userEmail'] = user.email

        confirmDialog({
            message: `Você tem certeza que quer remover essa turma?`,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: async () => {
                setIsDeleting(true);

                try {

                    await api.delete(`turma/${data.id}`);       
                    toast.current.show({ life: 5000, severity: 'success', summary: 'Remoção concluída!', detail: `Turma essa turma removido(a) com sucesso` });
                    getTurmas()
                }
                catch (error) {
                    toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover o turma', detail: error.response.data });
                }

                setIsDeleting(false);
            },
            reject: () => { return }
        });
    }

    const handlePrintClick = () => {
        setShowListaTurmas(true);
    }

    const getTurmasAll = useCallback(async () => {
        try {
            const response = await api.get(`turma/turmaAll`);
            setTurmasTotal(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter turmas', detail: error.toString() });
        }
    }, []);

    const getTipos = useCallback(async () => {
        const response = await api.get(`diaSemana`);
        setTipos(response.data.map(tipos => {
            var Tipos = { label: '', value: '' };
            Tipos.label = tipos
            Tipos.value = tipos

            return Tipos
        }))
        filter.filters !== undefined && (filter.filters.diaSemana !== undefined && filter.filters.diaSemana.value != null) ? setSelectedStatus(filter.filters.diaSemana.value) : setSelectedStatus(null)    
    }, [filter]);

    useEffect(() => {

        async function turmas() {
            await getTurmasAll();
            await getTipos();
        };
        turmas();
    }, [getTurmasAll,getTipos])

    const onHoraSearchChange = (event) => {
        if (event.value !== null) {
            dt.filter(event.value + ":00", 'hora2', 'equals');
            setSelectedHora(event.value);
        }
        else
            dt.filter(null, 'hora2', 'equals');
    }

    return (
        <>
        <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

         <DialogTurmas
                visible={showListaTurmas}
                setVisible={setShowListaTurmas}
                turma={turmasTotal}
            />
            <div className="p-my-0 p-dir-rev p-col">
                <div className="p-my-0">
                <Button 
                    textalign="center"
                    icon="pi pi-print"
                    className="p-button-secondary"
                    style={{ width: '35px' }}
                    onClick={handlePrintClick}
                    tooltip="Gerar relatório"
                    tooltipOptions={{ position: 'top' }}
    /> 
                </div>
            </div>
      
            <Toast ref={toast} position="bottom-right" />
            
            <Toast ref={confirmationToast} position="bottom-center" />
            
            <div className="card p-col-12 datatable-responsive-demo">
                
                <div className="p-fluid">
                    
                    <DataTable      
                        totalRecords={totalRecords}  
                        lazy="true"
                        first={first} 
                        onPage={getTurmas}       
                        ref={(element) => setDt(element)}
                        header={header}
                        value={turmas}
                        filterDisplay="row"
                        className="p-datatable-responsive-demo"
                        emptyMessage="Nenhuma turma encontrada"
                        loading={isLoading}
                        dataKey="id"
                        paginator
                        rows={20}
                        currentPageReportTemplate={`Mostrando de {first} a {last} de ${totalRecords} turmas`}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                        // rowsPerPageOptions={[10, 20, 30]} 
                        onSort={onSort}                       
                        onFilter={onFilter}
                        filters={filter.filters}
                    >

                        <Column
                            field="id"
                            header="Código"
                            sortable={sortable}
                            body={codBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.id !== undefined && filter.filters.id.value != null) ? filter.filters.id.value : "Pesquisar pelo Código"}
                            
                        />
                        
                        <Column
                            field="perfilNome"
                            header="Instrutor"
                            sortable={sortable}
                            body={instrutorBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.perfilNome !== undefined && filter.filters.perfilNome.value !== "") ? filter.filters.perfilNome.value : "Pesquisar pelo instrutor"}
                            
                        />


                        <Column
                            field="ano"
                            header="Ano"
                            sortable={sortable}
                            body={anoBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.ano !== undefined && filter.filters.ano.value !== "") ? filter.filters.ano.value : "Pesquisar pelo ano"}
                            
                        />

                        <Column
                            field="diaSemana"
                            header="Dia da Semana"
                            sortable={sortable}
                            body={semanaBodyTemplate}                            
                            filter
                            filterMatchMode="custom"
                            filterElement={filterStatus}
                            
                        />
                        
                        <Column
                            field="hora"
                            header="Hora"
                            sortable={sortable}
                            body={horaBodyTemplate}                            
                            filter
                            filterElement={
                                <InputMask 
                                    mask="99:99" 
                                    value={selectedHora} 
                                    placeholder="Pesquisar por hora" 
                                    onComplete={(e) => onHoraSearchChange(e)}>
                                </InputMask>
                            }
                        />

                        <Column
                            field="qtdeVagas"
                            header="Quantiade de Vagas"
                            sortable={sortable}
                            body={vagasBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.qtdeVagas !== undefined && filter.filters.qtdeVagas.value != null) ? filter.filters.qtdeVagas.value : "Pesquisar pela quantidade de vagas"}
                            
                        />

                        <Column
                            field="sala"
                            header="Sala"
                            sortable={sortable}
                            body={salaBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.sala !== undefined && filter.filters.sala.value !== "") ? filter.filters.sala.value : "Pesquisar pela quantidade de vagas"}
                            
                        />

                        <Column
                            field="cursoNome"
                            header="Curso"
                            sortable={sortable}
                            body={cursoBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.cursoNome !== undefined && filter.filters.cursoNome.value !== "") ? filter.filters.cursoNome.value : "Pesquisar pelo curso"}
                            
                        />

                        <Column
                            field="qtdeAlunos"
                            header="Qtde Alunos"
                            sortable={sortable}
                            body={qtdeAlunosBodyTemplate}                      
                            
                        />

                        <Column
                            header="Ações"
                            body={actionBodyTemplate}
                            
                        />
    
                    </DataTable>
                    
                </div>
            </div>
        </div>
       
        </>
    );
}