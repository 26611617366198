import React from 'react';
import MenuMain from '../../MenuMain';
import { FormLicenca } from '../../components/FormLicenca';
import { AppFooter } from '../../AppFooter';


export default function CadastrarLicencaPage() {

  const defaultValues = {
    'matriculaId': 0,
    'obs': '',
    'dataInicio': '',
    'dataFim': '',
    'tipo': '',
    'tipos': ['MEDICA','ACOMPANHANTE','VIAGEM','OUTRAS']
  }

  return (
    <div>
      <MenuMain>
         <FormLicenca
          defaultValues={defaultValues}
        />
      </MenuMain>
      <AppFooter />
    </div>
  );
}
