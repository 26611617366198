import React, { useEffect, useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorValidationMessage } from './ErrorValidationMessage';

import { TabMenu } from 'primereact/tabmenu';

import api from '../api/api';

import '../layout/sass/_form.scss';
import { Dropdown } from 'primereact/dropdown';
import { TabelaMatriculasPerfil } from './TabelaMatriculasPerfil';

export const FormHistoricoPerfil = ({ }) => {
    const history = useHistory();
    const toast = useRef(null);
    const [perfis, setPerfis] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    const items = [
        { label: 'Matrícula', icon: 'pi pi-fw pi-id-card' },
    ];

    const schema = Yup.object().shape({
    });

    const formik = useFormik({

        initialValues: {
            "idPerfil": null
        },

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
        }
    })
    
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getPerfis = async () => {
        const response = await api.get(`perfil/perfilAll`);
        setPerfis(response.data.map(perfis => {
            let cpf  = perfis.cpf ? perfis.cpf : "NÃO INFORMADO"
            let perf = { label: '', value: '' };
            perf.label = perfis.nome + " - " + cpf 
            perf.value = perfis.id

            return perf
        }))
    };

    useEffect(() => {

        async function historico() {
            await getPerfis();
        };
        historico();

    }, [])

    return (
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <div className="p-col-12">
                <h2 className="p-mt-0">Histórico Perfil</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-fluid p-mx-0">
                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="idPerfil"
                                className={classNames({ 'p-error': isFormFieldValid('idPerfil') })}
                                style={{ textAlign: 'justify', textJustify: 'distribute' }}
                            >
                                Aluno
                            </label>
                            {
                                <Dropdown
                                    id="idPerfil"
                                    name="idPerfil"
                                    value={formik.values.idPerfil}
                                    options={perfis}
                                    emptyMessage="Nenhum aluno encontrado"
                                    emptyFilterMessage={"Nenhum aluno encontrado"}
                                    filter={true}
                                    onChange={(e) => formik.setFieldValue("idPerfil", e.value)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('idPerfil') })}
                                    placeholder="Selecione o aluno"
                                    showClear={true}
                                />
                            }

                            {formik.errors.idPerfil && formik.touched.idPerfil &&
                                <ErrorValidationMessage message={formik.errors.idPerfil} />
                            }
                        </div>

                    </div>

                    {/* <div className="p-d-flex p-jc-end p-mr-1">
                        {
                            <Button
                                label="Pesquisar"
                                type="submit"
                                iconPos="right"
                                loading={isSubmiting}
                            />
                        }
                    </div> */}
                </form>

            </div>
            <div className="p-col-12">
                {
                    formik.values.idPerfil ?
                        <>
                            {/* <div className="card">
                                <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => { setActiveIndex(e.index)}} />
                            </div> */}
                            <TabelaMatriculasPerfil
                                matriculasPerfil={formik.values.idPerfil}
                            />

                        </>
                        :
                        <></>
                }
            </div>
        </div>

    );
}
