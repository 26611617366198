import React, { useCallback, useRef, useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { BrCalendar } from './BrCalendar';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ErrorValidationMessage } from './ErrorValidationMessage';
import api from '../api/api';
import '../layout/sass/_form.scss';

export const FormPeriodoMatricula = ({ isEditing, isLoading, defaultValues, titulo }) => {

    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [periodoInscricoes, setPeriodoInscricoes] = useState(null);
    const schema = Yup.object().shape({
        dataInicial: Yup
            .date()
            .typeError("Uma data deve ser inserida")
            .required("A data de inicio é obrigatória"),
        dataFinal: Yup
            .date()
            .typeError("Uma data deve ser inserida")
            .required("A data de fim é obrigatória"),     
    })

    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)                        
          
            try {
                if (!isEditing) {

                    await api.post("periodoMatricula", data)

                    formik.resetForm()
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `Periodo adicionado(a) com sucesso` });
                    setTimeout(() => {
                        history.push('/periodoMatricula')

                    }, 2000)

                }
                else {
                    const response = await api.get(`periodoMatricula?sort=dataInicial,ASC`);
                    data.dataInicial = new Date(data.dataInicial).toISOString();
                    data.dataFinal = new Date(data.dataFinal).toISOString();
                    // console.log(data);
                    if (data.tipo === "MATRICULA")
                    {
                        if (data.dataInicial > new Date(response.data.content[0].dataFinal).toISOString())
                        {
                            await api.put("periodoMatricula", data)
                            toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `Periodo atualizado(a) com sucesso` });
                            setTimeout(() => {
                                
                                history.push('/periodoMatricula')

                            }, 2000)
                        }
                        else
                        {
                            toast.current.show({ life: 5000, severity: 'error', summary: 'Cadastro falhou!', detail: `A MATRICULA deve se iniciar após o término da REMATRICULA!` });
                        }
                    }
                    else
                    {
                        if (data.dataFinal < new Date(response.data.content[1].dataInicial).toISOString())
                        {
                            await api.put("periodoMatricula", data)
                            toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `Periodo atualizado(a) com sucesso` });
                            setTimeout(() => {
                                
                                history.push('/periodoMatricula')

                            }, 2000)
                        }
                        else
                        {
                            toast.current.show({ life: 5000, severity: 'error', summary: 'Cadastro falhou!', detail: `A REMATRICULA deve finalizar antes do início da MATRICULA!` });
                        }
                    }
                }
            }
            catch (error) {
                if (!isEditing)
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao cadastrar o periodo da matricula!', detail: error.toString() });
                else
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar periodo da matricula!', detail: error.toString() });

            }

            setIsSubmiting(false)
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getPeriodo = useCallback(async () => {
        try {
            const response = await api.get(`periodoMatricula/periodo`);
            setPeriodoInscricoes(response.data.periodoInscricoes);
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter periodo da matricula', detail: error.toString() });
        }
    }, []);

    useEffect(() => {
        async function periodoMatricula() {
            await getPeriodo();
        };
        periodoMatricula();

    }, [getPeriodo])

    return (
                
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <div className="p-col-12">
                <h2 className="p-mt-0">{titulo}</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-fluid p-mx-0">

                        <div className="p-field p-col-12 p-sm-4 p-md-4">
                                <label
                                    htmlFor="dataInicial"
                                    className={classNames({ 'p-error': isFormFieldValid('dataInicial') })}
                                >
                                    Data Inicial
                                </label>
                                <BrCalendar
                                    id="dataInicial"
                                    name="dataInicial"
                                    value={formik.values.dataInicial}
                                    onChange={formik.handleChange}
                                    placeholder={ formik.values.dataInicial != null ? new Date(formik.values.dataInicial).toLocaleDateString('pt-BR') : "" }
                                    className={classNames({ 'p-invalid': isFormFieldValid('dataInicial') })}
                                    yearRange={`2000:${new Date().getFullYear()}`}
                                />
                                {formik.errors.dataInicial && formik.touched.dataInicial &&
                                    <ErrorValidationMessage message={formik.errors.dataInicial} />
                                }
                        </div>

                        <div className="p-field p-col-12 p-sm-4 p-md-4">
                                <label
                                    htmlFor="dataFinal"
                                    className={classNames({ 'p-error': isFormFieldValid('dataFinal') })}
                                >
                                    Data Final
                                </label>
                                <BrCalendar
                                    id="dataFinal"
                                    name="dataFinal"
                                    value={formik.values.dataFinal}
                                    onChange={formik.handleChange}
                                    placeholder={ formik.values.dataFinal != null ? new Date(formik.values.dataFinal).toLocaleDateString('pt-BR') : ""}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dataFinal') })}
                                    yearRange={`2000:${new Date().getFullYear()}`}
                                />
                                {formik.errors.dataFinal && formik.touched.dataFinal &&
                                    <ErrorValidationMessage message={formik.errors.dataFinal} />
                                }
                        </div>
                        <div className="p-field p-col-12 p-sm-12 p-md-12">
                            <span style={{color: "#CA2121 "}}>{periodoInscricoes ? "Não é permitido editar o período de matriculas/rematriculas se o periodo de inscrições está ativo!" : ""}</span>
                        </div>
                    </div>
                    <div className="p-d-flex p-jc-end p-mr-1">
                        {
                            !isLoading ?
                                <Button
                                    label={isEditing ? 'Atualizar' : 'Salvar'}
                                    type="submit"
                                    iconPos="right"
                                    loading={isSubmiting}
                                    disabled={periodoInscricoes}
                                />
                                :
                                <Skeleton width="82.16px" height="35px" />

                        }
                    </div>
                </form>
            </div>
        </div>
    
    );
}