import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorValidationMessage } from './ErrorValidationMessage';
import api from '../api/api';
import '../layout/sass/_form.scss';
import { Dropdown } from 'primereact/dropdown';
import {AuthContext} from '../contexts/AuthContext';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { InputMask } from 'primereact/inputmask';
import { BrCalendar } from './BrCalendar';
import { confirmDialog } from 'primereact/confirmdialog';

export const FormAgenda = ({ isEditing, isLoading, defaultValues, visible, setVisible, visibleDelete, setVisibleDelete, setAtualizarAgenda }) => {

    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [instrutor, setInstrutor] = useState(null);
    const [instrutores, setInstrutores] = useState([]);
    const { user } = useContext(AuthContext);
    const schema = Yup.object().shape({
        diaSemana: Yup
            .string()
            .when([],{
                is: () => formik.values.semanal === true || formik.values.semanal === '',
                then: Yup.string().required("O dia da semana é obrigatório"),
                otherwise: Yup.string().notRequired(),
            }),
        receptorId: Yup
            .number()
            .when([],{
                is: () => instrutor,
                then: Yup.number().notRequired(),
                otherwise: Yup.number().required("O instrutor é obrigatório"),
            }),
        titulo: Yup
            .string()
            .required("o título é obrigatório"),
        hora: Yup
            .string()
            .required("a hora é obrigatória"),
        data: Yup.date().when('semanal', {
                is: false,
                then: Yup.date().required("A data é obrigatória"),
                otherwise: Yup.date().notRequired(),
            }),
    })
    const [value, setValue] = useState();
    const [planejamentos, setPlanejamentos] = useState([]);
    const [semPermissao, setSemPermissao] = useState(false);
    const [instrutorSemPefil, setInstrutorSemPerfil] = useState(false);
    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)                
          
            try {
                if (!isEditing) {
                    if (instrutor)
                    {
                        data.receptorId = [instrutor]
                    }
                    if (data.data !== '')
                    {
                        data.diaSemana = new Date(data.data).getDay();
                    }
                    const emissor = await api.get(`perfil/instrutor/admin/${user.email}`);
                    let emissorId = emissor.data.id != undefined ? emissor.data.id : null;
                    data.emissorId = emissorId;
                    if (data.emissorId == null && user.perfilUser[0] === 'user-instrutor')
                    {
                        setInstrutorSemPerfil(true);
                    }
                    else
                    {
                        if (data.receptorId.length === 1)
                        {
                            data.receptorId = data.receptorId[0];
                            formik.setFieldValue("receptorId", [data.receptorId])
                            await api.post("agenda", data)
                            formik.resetForm()
                            toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `Planejamento adicionado com sucesso` });
                            setTimeout(() => {
                                setVisible(false);
                                setAtualizarAgenda(Math.random());
        
                            }, 2000)
                        }
                        else
                        {
                            data.receptorId.forEach(async receptor => {
                                data.receptorId = receptor;
                                formik.setFieldValue("receptorId", [data.receptorId])
                                await api.post("agenda", data)
                            });
                            formik.resetForm()
                            toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `Planejamento adicionado com sucesso` });
                            setTimeout(() => {
                                setVisible(false);
                                setAtualizarAgenda(Math.random());
        
                            }, 2000)
                        }
                    }
                }
                else {
                    if (planejamentos.length > 1)
                    {
                        if (user.perfilUser[0] !== 'user-instrutor')
                        {
                            confirmDialog({
                                message: `Deseja editar o planejamento para todos?`,
                                header: 'Confirmação',
                                icon: 'pi pi-exclamation-triangle',
                                acceptLabel: 'Sim',
                                rejectLabel: 'Não',
                                accept: async () => {
                                    let tempPlanejamento = {};
                                    planejamentos.forEach(async planejamento => {
                                        tempPlanejamento = {
                                            "id": planejamento.id,
                                            "diaSemana": data.diaSemana,
                                            "hora": data.hora,
                                            "data": data.data,
                                            "receptorId": planejamento.receptor.id,
                                            "emissorId": planejamento.emissor.id,
                                            "semanal": data.semanal,
                                            "titulo": data.titulo,
                                        }
                                        await api.put("agenda", tempPlanejamento);
                                    });
                                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastros atualizados!', detail: `Planejamentos atualizados com sucesso` });
                                    setTimeout(() => {
                                        setVisible(false);
                                        setAtualizarAgenda(Math.random());
                
                                    }, 2000)
                                },
                                reject: async () => { 
                                    await api.put("agenda", data);
                                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `Planejamento atualizado com sucesso` });
                                    setTimeout(() => {
                                        setVisible(false);
                                        setAtualizarAgenda(Math.random());
                
                                    }, 2000)
                                }
                            });
                        }
                        else {
                            toast.current.show({ life: 3000, severity: 'error', summary: 'Sem permissão!', detail: `O usuário atual não tem permissão para editar esse planejamento!` });
                        }
                    }
                    else
                    {
                        await api.put("agenda", data);
                        toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `Planejamento atualizado com sucesso` });
                        setTimeout(() => {
                            setVisible(false);
                            setAtualizarAgenda(Math.random());
    
                        }, 2000)
                    }
                    
                }
            }
            catch (error) {
                if (!isEditing)
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao cadastrar mensagem!', detail: error.toString() });
                else
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar mensagem!', detail: error.toString() });

            }

            setIsSubmiting(false)
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    
    const getInstrutor = useCallback(async () => {
        try {
            const response = await api.get(`perfil/instrutor`);
            const instrutor = await api.get(`/perfil/instrutor/${user.email}`);
            setInstrutores(response.data);
            if (user.perfilUser[0] === 'user-instrutor' && instrutor.data.id)
            {
                setInstrutor(instrutor.data.id);
            }
            if (isEditing)
            {
                let response = "";
                formik.values.diaSemana && (
                     response = await api.get(`agenda/planejamento/${formik.values.diaSemana}/${formik.values.hora}/${formik.values.titulo}`)
                )
                response !== "" && (
                    setPlanejamentos(response.data)
                )
                setValue(formik.values.hora);
            }
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter instrutores', detail: error.toString() });
        }
    }, [isEditing,formik.values.id]);

    useEffect(() => {
        
        async function agenda() {
                await getInstrutor();
        };
        agenda();

    }, [getInstrutor])

    const handleDeleteClick = useCallback(async (data) => {
        data['userEmail'] = user.email
        setVisibleDelete(false);
        let response = "";
        let planejamento = 0;
        let planejamentos = [];
        response = await api.get(`agenda/planejamento/${data.diaSemana}/${data.hora}/${data.titulo}`);
        planejamento = response.data.length;
        planejamentos = response.data;
        if (planejamento > 1)
        {
            if (user.perfilUser[0] !== 'user-instrutor')
                {
                    confirmDialog({
                        message: `Deseja remover o planejamento para todos?`,
                        header: 'Confirmação',
                        icon: 'pi pi-exclamation-triangle',
                        acceptClassName: 'p-button-danger',
                        acceptLabel: 'Sim',
                        rejectLabel: 'Não',
                        accept: async () => {
                            try {
                                confirmDialog({
                                    message: `Tem certeza que quer remover ${data.titulo} para todos?`,
                                    header: 'Confirmação',
                                    icon: 'pi pi-exclamation-triangle',
                                    acceptClassName: 'p-button-danger',
                                    acceptLabel: 'Sim',
                                    rejectLabel: 'Não',
                                    accept: async () => {
                                        try {
                                            planejamentos.forEach(async planejamento => {
                                                await api.delete(`agenda/${planejamento.id}`);
                                            });
                                            toast.current.show({ life: 2000, severity: 'success', summary: 'Remoção concluída!', detail: `Planejamento removido com sucesso` });
                                            setTimeout(() => {
                                                setAtualizarAgenda(Math.random());
                        
                                            }, 2000)  
                                        }
                                        catch (error) {
                                            toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover planejamento', detail: error.response.data });
                                        }
                                    },
                                    reject: () => { return }
                                });
                                
                            }
                            catch (error) {
                                toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover planejamento', detail: error.response.data });
                            }
                        },
                        reject: async () => { 
                            confirmDialog({
                                message: `Tem certeza que quer remover ${data.titulo} somente para ${data.receptorNome} ?`,
                                header: 'Confirmação',
                                icon: 'pi pi-exclamation-triangle',
                                acceptClassName: 'p-button-danger',
                                acceptLabel: 'Sim',
                                rejectLabel: 'Não',
                                accept: async () => {
                                    try {
                                        await api.delete(`agenda/${data.id}`);
                                        toast.current.show({ life: 2000, severity: 'success', summary: 'Remoção concluída!', detail: `Planejamento ${data.titulo} removido com sucesso` });
                                        setTimeout(() => {
                                            setAtualizarAgenda(Math.random());
                    
                                        }, 2000)  
                                    }
                                    catch (error) {
                                        toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover planejamento', detail: error.response.data });
                                    }
                                },
                                reject: () => { return }
                            });
                            }
                    });
                }
                else {
                    setSemPermissao(true);
                }
            
        }
        else
        {
            if (user.perfilUser[0] !== 'user-instrutor')
            {
                confirmDialog({
                    message: `Tem certeza que quer remover ${data.titulo}?`,
                    header: 'Confirmação',
                    icon: 'pi pi-exclamation-triangle',
                    acceptClassName: 'p-button-danger',
                    acceptLabel: 'Sim',
                    rejectLabel: 'Não',
                    accept: async () => {
                        try {
                            await api.delete(`agenda/${data.id}`);   
                            toast.current.show({ life: 2000, severity: 'success', summary: 'Remoção concluída!', detail: `Planejamento ${data.titulo} removido com sucesso` });
                            setTimeout(() => {
                                setAtualizarAgenda(Math.random());
        
                            }, 2000)  
                        }
                        catch (error) {
                            toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover planejamento', detail: error.response.data });
                        }
                    },
                    reject: () => { return }
                });
            }
            else
            {
                if (user.email === data.emissorEmail)
                {
                    confirmDialog({
                        message: `Tem certeza que quer remover ${data.titulo}?`,
                        header: 'Confirmação',
                        icon: 'pi pi-exclamation-triangle',
                        acceptClassName: 'p-button-danger',
                        acceptLabel: 'Sim',
                        rejectLabel: 'Não',
                        accept: async () => {
                            try {
                                await api.delete(`agenda/${data.id}`);   
                                toast.current.show({ life: 2000, severity: 'success', summary: 'Remoção concluída!', detail: `Planejamento ${data.titulo} removido com sucesso` });
                                setTimeout(() => {
                                    setAtualizarAgenda(Math.random());
            
                                }, 2000)  
                            }
                            catch (error) {
                                toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover planejamento', detail: error.response.data });
                            }
                        },
                        reject: () => { return }
                    });
                }
                else
                {
                    setSemPermissao(true);
                }
            }
        }
        
    }, []);

    useEffect(() => {
        function agenda() {
            if (visibleDelete === true)
            {
                handleDeleteClick(defaultValues);
            }
        };
        agenda();

    }, [handleDeleteClick,defaultValues])

    return (
        <>
        <Toast ref={toast} position="bottom-right" />
        <Dialog
            className='p-scrollpanel-bar-y'
            header="Não é possível deletar"
            onHide={() => { setSemPermissao(false); formik.resetForm() }}
            visible={semPermissao}
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            style={{ width: '50vw', heigth: '5vw' }}
        >
        O usuário atual não tem permissão para editar esse planejamento!
        </Dialog>
        <Dialog
            className='p-scrollpanel-bar-y'
            header='Atenção!'
            onHide={() => { setInstrutorSemPerfil(false)}}
            visible={instrutorSemPefil}
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            style={{ width: '50vw', heigth: '5vw' }}
        >
            <div className="p-field p-col-12 p-sm-12">
                O usuário atual não possui perfil. Por gentileza, entrar em contato com a administração do conservatório.
            </div>
        </Dialog>
        <Dialog
            className='p-scrollpanel-bar-y'
            header={isEditing ? 'Editar Planejamento' : 'Adicionar Planejamento'}
            onHide={() => { setVisible(false); formik.resetForm() }}
            visible={visible}
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            style={{ width: '50vw', heigth: '5vw' }}
        >
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <div className="p-col-12">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-fluid p-mx-0">

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="titulo"
                                className={classNames({ 'p-error': isFormFieldValid('titulo') })}
                            >
                                Título
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="titulo"
                                        name="titulo"
                                        className={classNames({ 'p-invalid': isFormFieldValid('titulo') })}
                                        value={formik.values.titulo}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.titulo && formik.touched.titulo &&
                                <ErrorValidationMessage message={formik.errors.titulo} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="receptorId"
                                className={classNames({ 'p-error': isFormFieldValid('receptorId') })}
                            >
                                Perfil
                            </label>
                            {
                                !isLoading ?
                                    instrutor ?
                                    <Dropdown
                                        value={instrutor}
                                        options={instrutores}
                                        optionLabel="nome"
                                        optionValue="id"
                                        className={classNames({ 'p-invalid': isFormFieldValid('receptorId') })}
                                        showClear={true}
                                        disabled
                                    />
                                    :
                                    isEditing ?
                                    <Dropdown
                                        value={formik.values.receptorId}
                                        options={instrutores}
                                        optionLabel="nome"
                                        optionValue="id"
                                        className={classNames({ 'p-invalid': isFormFieldValid('receptorId') })}
                                        showClear={true}
                                        disabled
                                    />
                                    :
                                    <MultiSelect
                                        value={formik.values.receptorId}
                                        options={instrutores}
                                        optionLabel="nome"
                                        optionValue="id"
                                        emptyMessage="Nenhum perfil encontrado"
                                        emptyFilterMessage="Nenhum perfil encontrado"
                                        filter={true}
                                        onChange={(e) => formik.setFieldValue("receptorId", e.value)}
                                        className={classNames({ 'p-invalid': isFormFieldValid('receptorId') })}
                                        placeholder="Selecione o(s) perfil(s)"
                                        display="chip"
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.receptorId && formik.touched.receptorId &&
                                <ErrorValidationMessage message={formik.errors.receptorId} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="semanal"
                                className={classNames({ 'p-error': isFormFieldValid('semanal') })}
                            >
                                Tipo de Planejamento
                            </label>
                            {
                                !isLoading ?
                                    <Dropdown
                                        id="semanal"
                                        name="semanal"
                                        value={formik.values.semanal === true ? "Semanal" : formik.values.semanal === false ? "Apenas na data marcada" : null}
                                        options={["Semanal","Apenas na data marcada"]}
                                        filter={true}
                                        onChange={(e) => formik.setFieldValue("semanal", e.value === "Semanal" ? true : false)}
                                        className={classNames({ 'p-invalid': isFormFieldValid('semanal') })}
                                        placeholder="Selecione o dia da Semana"
                                        showClear={true} 
                                    />  
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.semanal && formik.touched.semanal &&
                                <ErrorValidationMessage message={formik.errors.semanal} />
                            }
                        </div>
                            {formik.values.semanal === true || formik.values.semanal === '' ?
                            <>
                                <div className="p-field p-col-12 p-sm-4">
                                    <label
                                        htmlFor="diaSemana"
                                        className={classNames({ 'p-error': isFormFieldValid('diaSemana') })}
                                    >
                                        Dia da Semana
                                    </label>
                                    {
                                        !isLoading ?
                                            <Dropdown
                                                id="diaSemana"
                                                name="diaSemana"
                                                value={formik.values.diaSemana}
                                                options={formik.values.diaSemanas}
                                                emptyMessage="Nenhum dia encontrado"
                                                emptyFilterMessage={"Nenhum dia encontrado"}
                                                filter={true}
                                                onChange={(e) => formik.setFieldValue("diaSemana", e.value)}
                                                className={classNames({ 'p-invalid': isFormFieldValid('diaSemana') })}
                                                placeholder="Selecione o dia da Semana"
                                                showClear={true}
                                                disabled={formik.values.semanal === ''} 
                                            />  
                                            :
                                            <Skeleton height="35px" />
                                    }
                                    {formik.errors.diaSemana && formik.touched.diaSemana &&
                                        <ErrorValidationMessage message={formik.errors.diaSemana} />
                                    }
                                </div>
        
                            </>
                            :
                            <div className="p-field p-col-12 p-sm-4 p-md-4">
                                <label
                                    htmlFor="data"
                                    className={classNames({ 'p-error': isFormFieldValid('data') })}
                                >
                                    Data
                                </label>
                                <BrCalendar
                                    id="data"
                                    name="data"
                                    value={formik.values.data}
                                    onChange={formik.handleChange}
                                    placeholder={ isEditing ? new Date(formik.values.data).toLocaleDateString('pt-BR') : ""}
                                    className={classNames({ 'p-invalid': isFormFieldValid('data') })}
                                    yearRange={`2000:${new Date().getFullYear()}`}
                                />
                                {formik.errors.data && formik.touched.data &&
                                    <ErrorValidationMessage message={formik.errors.data} />
                                }
                            </div>
                            }

                            <div className="p-field p-col-12 p-sm-4 p-md-4">
                                    <label
                                        htmlFor="hora"
                                        className={classNames({ 'p-error': isFormFieldValid('hora') })}
                                    >
                                        Hora
                                    </label>
                                    {isEditing ?
                                        <InputMask 
                                            mask="99:99" 
                                            value={value} 
                                            onChange={(e) => setValue(e.value)}                                             
                                            onComplete={(e) => formik.setFieldValue("hora", e.value)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('hora') })}
                                        >
                                        </InputMask>
                                        :
                                        <InputMask 
                                            mask="99:99" 
                                            value={value} 
                                            onChange={(e) => setValue(e.value)} 
                                            onComplete={(e) => formik.setFieldValue("hora", e.value)}
                                            disabled={formik.values.semanal === ''}
                                            className={classNames({ 'p-invalid': isFormFieldValid('hora') })}
                                        >
                                        </InputMask>
                                    }
                                    
                                    {formik.errors.hora && formik.touched.hora &&
                                        <ErrorValidationMessage message={formik.errors.hora} />
                                    }
                                </div>

                    </div>
                    <div className="p-d-flex p-jc-end p-mr-1">
                        {
                            !isLoading ?
                                <Button
                                    label={isEditing ? 'Atualizar' : 'Salvar'}
                                    type="submit"
                                    iconPos="right"
                                    loading={isSubmiting}
                                />
                                :
                                <Skeleton width="82.16px" height="35px" />

                        }
                    </div>
                </form>
            </div>
        </div>
        </Dialog>
        
    </>
    );
}