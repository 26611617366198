import React, { useContext, useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Printd } from 'printd';

import logo2 from '../assets/logo_conservatorio.jpg';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
import { AuthContext } from '../contexts/AuthContext';
import api from '../api/api';

export const DialogFaltasSeguidas = ({ visible, setVisible, faltas }) => {
    const d = new Printd();
    const [emailVisible, setEmailVisible] = useState(false);
    const [assunto, setAssunto] = useState("Faltas seguidas");
    const [mensagem, setMensagem] = useState("Informamos que consta em nosso sistema mais de duas faltas seguidas. Favor entrar em contato com a secretaria do conservatório municipal no telefone: 34 3822-9668.");
    const [alunos, setAlunos] = useState([]);
    const toast = useRef(null);
    const { user } = useContext(AuthContext);
    const cssText = `
    #print-content {
        font-family: 'Open Sans', sans-serif;
        width: 100%;
        text-align: left;
    }
    
    img {
            margin-top: 25px;
            width: 100%;
            max-width: 250px;
            height: auto;
            display: block;
        }
        
        .header-img {
            display: flex;
            justify-content: center;
        }

        .header {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        .header h2 {
            margin-bottom: 0px;
        }

        .header h4 {
            margin-top: 7px;
        }
    `
    
    const listaAlunos = faltas.map((item) => {        
        return (            
            <tr key={item.matriculaId}>
                <td align='center'> <span>{item.matriculaId}</span></td>
                <td> <span>{item.matriculaAlunoNome}</span></td>
                <td align='center'> <span>{item.datasOcorrencias}</span></td>
                <td> <span>{item.matriculaAlunoEmail}</span></td>
                <td> <span>{item.matriculaAlunoTelefone !== "" ? item.matriculaAlunoCelular + " ou " + item.matriculaAlunoTelefone : item.matriculaAlunoCelular}</span></td>
                <td> <span>{item.obs}</span></td>
            </tr>
        )
    })

    const handlePrintClick = () => {
        d.print(document.getElementById("print-content"), [cssText])
    }

    const handleEmailsClick = () => {
        setEmailVisible(true);
    }

    const handleSubmit = () => {
        if (alunos.length === 0)
        {
            toast.current.show({ life: 2000, severity: 'error', summary: 'Nenhum aluno selecionado!', detail: "Selecione um ou mais alunos" });
        }
        else
        {
            if (assunto === '' || mensagem === '')
            {
                toast.current.show({ life: 4000, severity: 'error', summary: 'Assunto ou Mensagem não especificado!', detail: "É necessário escrever o assunto e a mensagem." });

            }
            else
            {
                alunos.forEach(async aluno => {
                    await api.get(`email-send/${assunto}/${mensagem}/${aluno}/${user.email}`)
                    
                });
                toast.current.show({ life: 2000, severity: 'success', summary: 'Email(s) enviado(s) com sucesso!' });
                setTimeout(() => {
                    setAlunos([]); 
                    setAssunto("Faltas seguidas"); 
                    setMensagem("Informamos que consta em nosso sistema mais de duas faltas seguidas. Favor entrar em contato com a secretaria do conservatório municipal no telefone: 34 3822-9668.")
                    setEmailVisible(false);
                }, 2000);
            }
        }
    }

    const header = (
        <div className="p-my-0 p-grid p-col">
            <div className="p-m-2">
                <Button label="Imprimir" className="p-button-secondary" icon="pi pi-print" onClick={handlePrintClick} />
            </div>
            <div className="p-m-2">
                {listaAlunos && (
                    listaAlunos.length > 0 ?
                    <Button label="Enviar emails" className="p-button-primary" icon="pi pi-envelope" onClick={handleEmailsClick} />
                    :
                    <></>
                )}
            </div>
        </div>
    );

    return (
        <>
        <Toast ref={toast} position="bottom-right" />
        <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            header={header}
            className='p-scrollpanel-bar-y'
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            style={{ width: '50vw', heigth: '5vw' }}
        >
            <div id="print-content">
                <div className="p-d-flex p-jc-center header-img">
                    <img
                        id="logo"
                        alt="Logo"
                        src={logo2}
                    />
                </div>
                <div className="p-d-flex p-ai-center p-flex-column header">
                    <h2>Alunos com faltas seguidas</h2>

                </div>
                <table width="100%" border="1" style={{ 'borderCollapse': 'collapse' }} cellPadding={3} cellSpacing={0}>
                    <thead>
                        <tr>
                            <th><i>Matrícula</i></th>
                            <th><i>Nome</i></th>
                            <th><i>Faltas ocorreram em</i></th>
                            <th><i>Email</i></th>
                            <th><i>Celular</i></th>
                            <th><i>Observação</i></th>
                        </tr>
                    </thead>

                    <tbody>
                        {listaAlunos}
                    </tbody>


                </table>

            </div>


        </Dialog>
        <Dialog
            className='p-scrollpanel-bar-y'
            header="Selecione os alunos"
            onHide={() => { setEmailVisible(false)}}
            visible={emailVisible}
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            style={{ width: '50vw', heigth: '5vw' }}
        >
             <div className="p-formgrid p-grid p-fluid p-mx-0">

            <div className="p-field p-col-12 p-sm-12">
                <label>
                    Alunos
                </label>
                {faltas &&
                    (
                        <MultiSelect
                            value={alunos}
                            options={faltas}
                            optionLabel="matriculaAlunoNome"
                            optionValue="matriculaAlunoEmail"
                            emptyMessage="Nenhum aluno encontrado"
                            emptyFilterMessage="Nenhum aluno encontrado"
                            filter={true}
                            onChange={(e) => setAlunos(e.value)}
                            placeholder="Selecione o(s) aluno(s)"
                            display="chip"
                        />
                    )}
            </div>
            <div className="p-field p-col-12 p-sm-12">
                <label>
                    Assunto
                </label>
                {
                    <InputTextarea 
                        rows={1} 
                        cols={60}  
                        value={assunto}
                        onChange={(e) => setAssunto(e.target.value)}
                    />
                }
            </div>
            <div className="p-field p-col-12 p-sm-12">
                <label>
                    Mensagem
                </label>
                {
                    <InputTextarea 
                        rows={5} 
                        cols={60}  
                        value={mensagem}
                        onChange={(e) => setMensagem(e.target.value)}
                    />
                }
            </div>
             </div>
            <div className="p-d-flex p-jc-end p-mr-1">
                {
                    <Button
                        label='Enviar'
                        type="submit"
                        iconPos="right"
                        onClick={handleSubmit}
                    />
                }
            </div>
        </Dialog>
        </>
    );
}