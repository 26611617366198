import React, { useRef, useEffect, useState, useContext, useCallback } from 'react';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DialogTotalAlunos } from './DialogTotalAlunos';
import { DialogAlunosMatriculados } from './DialogAlunosMatriculados';
import { DialogAlunosRematriculados } from './DialogAlunosRematriculados';
import { DialogAlunosTrancados } from './DialogAlunosTrancados';
import { DialogAlunosDesistentes } from './DialogAlunosDesistentes';
import { DialogInstrutores } from './DialogInstrutores';
import { DialogTeoria } from './DialogTeoria';
import { DialogInstrumento } from './DialogInstrumento';
import { DialogAguardandoInstrumento } from './DialogAguardandoInstrumento';
import { DialogTurmasVigentes } from './DialogTurmasVigentes';
import { DialogFaltasSeguidas } from './DialogFaltasSeguidas';
import '../layout/sass/_responsiveTable.scss'

import '../layout/sass/_dashboard.scss';

import { AuthContext } from '../contexts/AuthContext';

import api from '../api/api';

import FullCalendarApp from './FullCalendarApp';
import { Dropdown } from 'primereact/dropdown';

export const DashBoard = () => {
    const toast = useRef(null);
    const [showRelatorioAlunos, setShowRelatorioAlunos] = useState(false);
    const [showRelatorioMatriculados, setShowRelatorioMatriculados] = useState(false);
    const [showRelatorioRematriculados, setShowRelatorioRematriculados] = useState(false);
    const [showRelatorioTrancados, setShowRelatorioTrancados] = useState(false);
    const [showRelatorioDesistentes, setShowRelatorioDesistentes] = useState(false);
    const [showRelatorioInstrutores, setShowRelatorioInstrutores] = useState(false);
    const [showRelatorioTeoria, setShowRelatorioTeoria] = useState(false);
    const [showRelatorioInstrumento, setShowRelatorioInstrumento] = useState(false);
    const [showRelatorioAguardandoInstrumento, setShowRelatorioAguardandoInstrumento] = useState(false);
    const [showAlunosAtual, setShowAlunosAtual] = useState(false);
    const [showFaltasSeguidas, setShowFaltasSeguidas] = useState(false);
    const [totalAlunos, setTotalAlunos] = useState([]);
    const [matriculados, setMatriculados] = useState([]);
    const [rematriculados, setRematriculados] = useState([]);
    const [trancados, setTrancados] = useState([]);
    const [desistentes, setDesistentes] = useState([]);
    const [instrutor, setInstrutor] = useState([]);
    const [teoria, setTeoria] = useState([]);
    const [instrumento, setInstrumento] = useState([]);
    const [aguardandoInstrumento, setAguardandoInstrumento] = useState([]);
    const [alunosAtual, setAlunosAtual] = useState([]);
    const [faltasSeguidas, setFaltasSeguidas] = useState([]);
    const [countTotalAlunos, setCountTotalAlunos] = useState(0);
    const [countMatriculados, setCountMatriculados] = useState(0);
    const [countRematriculados, setCountRematriculados] = useState(0);
    const [countTrancados, setCountTrancados] = useState(0);
    const [countDesistentes, setCountDesistentes] = useState(0);
    const [countInstrutor, setCountInstrutor] = useState(0);
    const [countTeoria, setCountTeoria] = useState(0);
    const [countInstrumento, setCountInstrumento] = useState(0);
    const [countAguardandoInstrumento, setCountAguardandoInstrumento] = useState(0);
    const [countAlunosAtual, setCountAlunosAtual] = useState(0);
    const [countFaltasSeguidas, setCountFaltasSeguidas] = useState(0);
    const [cursos, setCursos] = useState([]);
    const [curso, setCurso] = useState(null);
    const { user } = useContext(AuthContext);

    const verificaTrancado = useCallback(async () => {
        try{
            const response = await api.get(`historicoStatus/trancado`);
            if (response.data.length !== 0 && user.perfilUser[0] !== 'user-instrutor')
            {
                response.data.forEach(async item => {
                    const result = await api.get(`matricula/${item.matriculaId}`);
                    let data = {
                        id: result.data.id,
                        conclusao: result.data.conclusao,
                        dataMatricula: result.data.dataMatricula,
                        notaFinal: result.data.notaFinal,
                        status: "DESISTENTE",
                        alunoId: result.data.alunoId,
                        instrumentoId: result.data.instrumentoId,
                        periodoId: result.data.periodoId,
                        turmaId: result.data.turmaId,
                    }
                    let data1 = {
                        matriculaId: result.data.id,
                        data: new Date(),
                        status: "DESISTENTE",
                        statusAtual: true,
                    }
                    await api.put("matricula", data)
                    await api.post("historicoStatus", data1)
                });
            }

        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro', detail: error.toString() });
        }

    }, [user.perfilUser]);

    const getCounts = useCallback(async () => {
        try {
            // const response = await api.get(`matricula/counts`);
            const faltasSeguidas = await api.get(`falta/faltasSeguidas`);
            // setCountTotalAlunos(response.data.countMatricula);
            // setCountMatriculados(response.data.countMatriculado);
            // setCountRematriculados(response.data.countRematriculado);
            // setCountTrancados(response.data.countTrancado);
            // setCountDesistentes(response.data.countDesistente);
            // setCountInstrutor(response.data.countInstrutor);
            // setCountTeoria(response.data.countTeoria);
            // setCountInstrumento(response.data.countInstrumento);
            // setCountAguardandoInstrumento(response.data.countFilaInstrumento);
            // setCountAlunosAtual(response.data.countAtual);
            setCountFaltasSeguidas(faltasSeguidas.data.length);
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter total de alunos', detail: error.toString() });
        }
    }, []);

    const getCountAguardandoInstrumento = useCallback(async (cursoId) => {
        try {
            const response = await api.get(`matricula/counts/${cursoId}`);
            setCountAguardandoInstrumento(response.data.countFilaInstrumento);
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter total de alunos', detail: error.toString() });
        }
    }, []);

    const getTotalAlunos = useCallback(async () => {
        try {
            const response = await api.get(`matricula/matriculaAll`);
            setTotalAlunos(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter total de alunos', detail: error.toString() });
        }
    }, []);

    const getMatriculados = useCallback(async () => {
        try {
            const response = await api.get(`matricula/status/MATRICULADO`);
            setMatriculados(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter alunos matriulados', detail: error.toString() });
        }
    }, []);

    const getRematriculados = useCallback(async () => {
        try {
            const response = await api.get(`matricula/status/REMATRICULADO`);
            setRematriculados(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter alunos rematriculado', detail: error.toString() });
        }
    }, []);

    const getTrancados = useCallback(async () => {
        try {
            const response = await api.get(`matricula/status/TRANCADO`);
            setTrancados(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter alunos trancados', detail: error.toString() });
        }
    }, []);

    const getDesistentes = useCallback(async () => {
        try {
            const response = await api.get(`matricula/status/DESISTENTE`);
            setDesistentes(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter alunos desistentes', detail: error.toString() });
        }
    }, []);

    const getAguardandoInstrumento = useCallback(async () => {
        try {
            const response = await api.get(`matricula/filaInstrumento`);
            setAguardandoInstrumento(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter alunos fila de alunos aguardando instrumento', detail: error.toString() });
        }
    }, []);

    const getAguardandoInstrumentoCurso = useCallback(async (curso) => {
        try {
            const response = await api.get(`matricula/filaInstrumento/${curso}`);
            setAguardandoInstrumento(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter alunos por curso fila de alunos aguardando instrumento', detail: error.toString() });
        }
    }, []);

    const getInstrutor = useCallback(async () => {
        try {
            const response = await api.get(`perfil/instrutor`);
            setInstrutor(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter instrutores', detail: error.toString() });
        }
    }, []);

    const getTeoria = useCallback(async () => {
        try {
            const response = await api.get(`matricula/cursoTipo/TEORIA`);
            setTeoria(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter alunos na teoria', detail: error.toString() });
        }
    }, []);

    const getInstrumento = useCallback(async () => {
        try {
            const response = await api.get(`matricula/cursoTipo/INSTRUMENTO`);
            setInstrumento(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter alunos no instrumento', detail: error.toString() });
        }
    }, []);

    const getMatriculasAtual = useCallback(async () => {
        try {
            const response = await api.get(`matricula/turmaAtual`);
            setAlunosAtual(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter alunos', detail: error.toString() });
        }
    }, []);

    const getFaltasSeguidas = useCallback(async () => {
        try {
            const response = await api.get(`falta/faltasSeguidas`);
            setFaltasSeguidas(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter alunos', detail: error.toString() });
        }

    }, []);

    const getCursos = useCallback(async () => {
        try {
            const response = await api.get(`curso/instrumento`);
            setCursos(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter cursos', detail: error.toString() });
        }
    }, []);

    useEffect(() => {
        
        async function dashboard() {
                // await getCounts();
                // await verificaTrancado();
                // await getTotalAlunos();
                // await getMatriculados();
                // await getRematriculados();
                // await getTrancados();
                // await getDesistentes();
                // await getInstrutor();
                // await getTeoria();
                // await getInstrumento();
                // await getAguardandoInstrumento();
                // await getMatriculasAtual();
                await getFaltasSeguidas();
                // await getCursos();
        };
        dashboard();

    }, [getFaltasSeguidas])

    const handleChangeCurso = async (e) => {
        if (e.value != undefined)
        {
            setCurso(e.value);
            await getAguardandoInstrumentoCurso(e.value);
            await getCountAguardandoInstrumento(e.value);
            toast.current.show({ severity: 'success', summary: 'Relatório atualizado com sucesso'});
        }
        else
        {
            setCurso(null);
            await getAguardandoInstrumento();
            await getCounts();
            toast.current.show({ severity: 'success', summary: 'Relatório atualizado com sucesso'});
        }
    }

    return (
        <>
            <Toast ref={toast} position="bottom-right" />

            {
                <>


                <div className="p-grid p-fluid">
                {user.perfilUser[0] !== 'user-instrutor' ? 
                    <>
                    {/* <div className="p-col-12 p-md-4 p-xl-4">
                        <div className="p-d-flex counter-container">
                            <div className="counter-label-container" style={{ "backgroundColor": "#1d8ef0", "color": "#fff" }}>
                                <div className="actions">
                                </div>
                                <span>Total de Alunos</span>
                            </div>
                            <div className="counter-value-container p-p-1" style={{ "backgroundColor": "#fff", "color": "#858585" }}>
                                <span className="count-label"><i className="pi pi-folder-open"></i> Total</span>
                                <span className="count">{countTotalAlunos}</span>
                                {
                                    showRelatorioAlunos ?
                                    <DialogTotalAlunos 
                                        visible={showRelatorioAlunos}
                                        setVisible={setShowRelatorioAlunos}
                                        alunos={totalAlunos}
                                    />
                                    :
                                    ""
                                }
                                <Button icon="pi pi-info" className="p-button-rounded p-button-secondary" onClick={() => setShowRelatorioAlunos(true)}
                                    tooltip="Relatório alunos total" tooltipOptions={{ position: 'top' }} style={{ "left": "5px", "bottom": "5px", "height": "20px", "width": "15px" }} />
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="p-col-12 p-md-4 p-xl-4">
                        <div className="p-d-flex counter-container">
                            <div className="counter-label-container" style={{ "backgroundColor": "#1d8ef0", "color": "#fff" }}>
                                <span>Matriculados</span>
                            </div>
                            <div className="counter-value-container p-p-1" style={{ "backgroundColor": "#fff", "color": "#858585" }}>
                                <span className="count-label"><i className="pi pi-id-card"></i> Total</span>
                                <span className="count">{countMatriculados}</span>
                                {
                                    showRelatorioMatriculados ?
                                    <DialogAlunosMatriculados
                                        visible={showRelatorioMatriculados}
                                        setVisible={setShowRelatorioMatriculados}
                                        alunos={matriculados}
                                    />
                                    :
                                    ""
                                }
                                <Button icon="pi pi-info" className="p-button-rounded p-button-secondary" onClick={() => setShowRelatorioMatriculados(true)}
                                    tooltip="Relatório alunos matriculados" tooltipOptions={{ position: 'top' }} style={{ "left": "5px", "bottom": "5px", "height": "20px", "width": "15px" }} />
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="p-col-12 p-md-4 p-xl-4">
                        <div className="p-d-flex counter-container">
                            <div className="counter-label-container" style={{ "backgroundColor": "#1d8ef0", "color": "#fff" }}>
                                <span>Rematriculados</span>
                            </div>
                            <div className="counter-value-container p-p-1" style={{ "backgroundColor": "#fff", "color": "#858585" }}>
                                <span className="count-label"><i className="pi pi-id-card"></i> Total</span>
                                <span className="count">{countRematriculados}</span>
                                {
                                    showRelatorioRematriculados ?
                                    <DialogAlunosRematriculados
                                        visible={showRelatorioRematriculados}
                                        setVisible={setShowRelatorioRematriculados}
                                        alunos={rematriculados}
                                    />
                                    :
                                    ""
                                }
                                <Button icon="pi pi-info" className="p-button-rounded p-button-secondary" onClick={() => setShowRelatorioRematriculados(true)}
                                    tooltip="Relatório alunos rematriculados" tooltipOptions={{ position: 'top' }} style={{ "left": "5px", "bottom": "5px", "height": "20px", "width": "15px" }} />
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="p-col-12 p-md-4 p-xl-4">
                        <div className="p-d-flex counter-container">
                            <div className="counter-label-container" style={{ "backgroundColor": "#1d8ef0", "color": "#fff" }}>
                                <span>Trancados</span>
                            </div>
                            <div className="counter-value-container p-p-1" style={{ "backgroundColor": "#fff", "color": "#858585" }}>
                                <span className="count-label"><i className="pi pi-id-card"></i> Total</span>
                                <span className="count">{countTrancados}</span>
                                {
                                    showRelatorioTrancados ?
                                    <DialogAlunosTrancados
                                        visible={showRelatorioTrancados}
                                        setVisible={setShowRelatorioTrancados}
                                        alunos={trancados}
                                    />
                                    :
                                    ""
                                }
                                <Button icon="pi pi-info" className="p-button-rounded p-button-secondary" onClick={() => setShowRelatorioTrancados(true)}
                                    tooltip="Relatório alunos trancados" tooltipOptions={{ position: 'top' }} style={{ "left": "5px", "bottom": "5px", "height": "20px", "width": "15px" }} />
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="p-col-12 p-md-4 p-xl-4">
                        <div className="p-d-flex counter-container">
                            <div className="counter-label-container" style={{ "backgroundColor": "#1d8ef0", "color": "#fff" }}>
                                <span>Desistentes</span>
                            </div>
                            <div className="counter-value-container p-p-1" style={{ "backgroundColor": "#fff", "color": "#858585" }}>
                                <span className="count-label"><i className="pi pi-id-card"></i> Total</span>
                                <span className="count">{countDesistentes}</span>
                                {
                                    showRelatorioDesistentes ?
                                    <DialogAlunosDesistentes
                                        visible={showRelatorioDesistentes}
                                        setVisible={setShowRelatorioDesistentes}
                                        alunos={desistentes}
                                    />
                                    :
                                    ""
                                }
                                <Button icon="pi pi-info" className="p-button-rounded p-button-secondary" onClick={() => setShowRelatorioDesistentes(true)}
                                    tooltip="Relatório alunos desistentes" tooltipOptions={{ position: 'top' }} style={{ "left": "5px", "bottom": "5px", "height": "20px", "width": "15px" }} />
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="p-col-12 p-md-4 p-xl-4">
                        <div className="p-d-flex counter-container">
                            <div className="counter-label-container" style={{ "backgroundColor": "#1d8ef0", "color": "#fff" }}>
                                <span>Total de Instrutores</span>
                            </div>
                            <div className="counter-value-container p-p-1" style={{ "backgroundColor": "#fff", "color": "#858585" }}>
                                <span className="count-label"><i className="pi pi-home"></i> Total</span>
                                <span className="count">{countInstrutor}</span>
                                {
                                    showRelatorioInstrutores ?
                                    <DialogInstrutores
                                        visible={showRelatorioInstrutores}
                                        setVisible={setShowRelatorioInstrutores}
                                        instrutores={instrutor}
                                    />
                                    :
                                    ""
                                }
                                <Button icon="pi pi-info" className="p-button-rounded p-button-secondary" onClick={() => setShowRelatorioInstrutores(true)}
                                    tooltip="Relatório instrutores" tooltipOptions={{ position: 'top' }} style={{ "left": "5px", "bottom": "5px", "height": "20px", "width": "15px" }} />
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="p-col-12 p-md-4 p-xl-4">
                        <div className="p-d-flex counter-container">
                            <div className="counter-label-container" style={{ "backgroundColor": "#1d8ef0", "color": "#fff" }}>
                                <span>Teoria</span>
                            </div>
                            <div className="counter-value-container p-p-1" style={{ "backgroundColor": "#fff", "color": "#858585" }}>
                                <span className="count-label"><i className="pi pi-home"></i> Total</span>
                                <span className="count">{countTeoria}</span>
                                {
                                    showRelatorioTeoria ?
                                    <DialogTeoria
                                        visible={showRelatorioTeoria}
                                        setVisible={setShowRelatorioTeoria}
                                        teoria={teoria}
                                    />
                                    :
                                    ""
                                }
                                <Button icon="pi pi-info" className="p-button-rounded p-button-secondary" onClick={() => setShowRelatorioTeoria(true)}
                                    tooltip="Relatório de alunos em turma teoria" tooltipOptions={{ position: 'top' }} style={{ "left": "5px", "bottom": "5px", "height": "20px", "width": "15px" }} />
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="p-col-12 p-md-4 p-xl-4">
                        <div className="p-d-flex counter-container">
                            <div className="counter-label-container" style={{ "backgroundColor": "#1d8ef0", "color": "#fff" }}>
                                <span>Instrumento</span>
                            </div>
                            <div className="counter-value-container p-p-1" style={{ "backgroundColor": "#fff", "color": "#858585" }}>
                                <span className="count-label"><i className="pi pi-home"></i> Total</span>
                                <span className="count">{countInstrumento}</span>
                                {
                                    showRelatorioInstrumento ?
                                    <DialogInstrumento
                                        visible={showRelatorioInstrumento}
                                        setVisible={setShowRelatorioInstrumento}
                                        instrumento={instrumento}
                                    />
                                    :
                                    ""
                                }
                                <Button icon="pi pi-info" className="p-button-rounded p-button-secondary" onClick={() => setShowRelatorioInstrumento(true)}
                                    tooltip="Relatório de alunos em turma instrumento" tooltipOptions={{ position: 'top' }} style={{ "left": "5px", "bottom": "5px", "height": "20px", "width": "15px" }} />
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="p-col-12 p-md-4 p-xl-4">
                        <div className="p-d-flex counter-container">
                            <div className="counter-label-container" style={{ "backgroundColor": "#1d8ef0", "color": "#fff" }}>
                                <span>Alunos Ano Vigente</span>
                            </div>
                            <div className="counter-value-container p-p-1" style={{ "backgroundColor": "#fff", "color": "#858585" }}>
                                <span className="count-label"><i className="pi pi-home"></i> Total</span>
                                <span className="count">{countAlunosAtual}</span>
                                {
                                    showAlunosAtual ?
                                    <DialogTurmasVigentes
                                        visible={showAlunosAtual}
                                        setVisible={setShowAlunosAtual}
                                        alunos={alunosAtual}
                                    />
                                    :
                                    ""
                                }
                                <Button icon="pi pi-info" className="p-button-rounded p-button-secondary" onClick={() => setShowAlunosAtual(true)}
                                    tooltip="Relatório de alunos matriculados no ano vigente" tooltipOptions={{ position: 'top' }} style={{ "left": "5px", "bottom": "5px", "height": "20px", "width": "15px" }} />
                            </div>
                        </div>
                    </div> */}

                    <div className="p-col-12 p-md-4 p-xl-4">
                        <div className="p-d-flex counter-container" >
                            <div className="counter-label-container" style={countFaltasSeguidas > 0 ? { "backgroundColor": "red", "color": "#fff"} : { "backgroundColor": "#1d8ef0", "color": "#fff" }} >
                                <span>Faltas seguidas</span>
                            </div>
                            <div className="counter-value-container p-p-1" style={{ "backgroundColor": "#fff", "color": "#858585" }}>
                                <span className="count-label" style={countFaltasSeguidas > 0 ? { "fontWeight": "bold", "color": "red"} : {}}><i className="pi pi-calendar" ></i> Total</span>
                                <span className="count" style={countFaltasSeguidas > 0 ? { "fontWeight": "bold", "color": "red"} : {}} >{countFaltasSeguidas}</span>
                                {
                                    showFaltasSeguidas ?
                                    <DialogFaltasSeguidas
                                        visible={showFaltasSeguidas}
                                        setVisible={setShowFaltasSeguidas}
                                        faltas={faltasSeguidas}
                                    />
                                    :
                                    ""
                                }
                                <Button icon="pi pi-info" className="p-button-rounded p-button-secondary" onClick={() => setShowFaltasSeguidas(true)}
                                    tooltip="Relatório de alunos com faltas seguidas" tooltipOptions={{ position: 'top' }} style={{ "left": "5px", "bottom": "5px", "height": "20px", "width": "15px" }} />
                            </div>
                        </div>
                    </div>

                    {/* <div className="p-col-12 p-md-4 p-xl-8">
                        <div className="p-d-flex counter-container">
                            <div className="counter-label-container" style={{ "backgroundColor": "#1d8ef0", "color": "#fff" }}>
                                <span>Aguardando Instrumento</span>
                            </div>
                            <div className="counter-value-container p-p-1" style={{ "backgroundColor": "#fff", "color": "#858585"}}>
                                {cursos && (
                                    <Dropdown
                                        value={curso}
                                        options={cursos}
                                        optionLabel="nome"
                                        optionValue="id"
                                        emptyMessage="Nenhum curso encontrado"
                                        emptyFilterMessage={"Nenhum curso encontrado"}
                                        filter={true}
                                        onChange={(e) => handleChangeCurso(e)}
                                        placeholder="Selecione o curso"
                                        showClear={true}
                                        style={{marginTop: "16px"}}
                                    />
                                )}
                           </div>
                            <div className="counter-value-container p-p-1" style={{ "backgroundColor": "#fff", "color": "#858585"}}>
                                <span className="count-label"><i className="pi pi-id-card"></i> Total</span>
                                <span className="count">{countAguardandoInstrumento}</span>
                                {
                                    showRelatorioAguardandoInstrumento ?
                                    <DialogAguardandoInstrumento
                                        visible={showRelatorioAguardandoInstrumento}
                                        setVisible={setShowRelatorioAguardandoInstrumento}
                                        instrumento={aguardandoInstrumento}
                                    />
                                    :
                                    ""
                                }
                                <Button icon="pi pi-info" className="p-button-rounded p-button-secondary" onClick={() => setShowRelatorioAguardandoInstrumento(true)}
                                    tooltip="Relatório alunos aguardando instrumento" tooltipOptions={{ position: 'top' }} style={{ "left": "5px", "bottom": "5px", "height": "20px", "width": "15px" }} />
                            </div>
                        </div>
                    </div> */}

                    <div className="p-col-12 p-md-12 p-xl-12" height="100" >

                        <FullCalendarApp />

                    </div>

                    </>
                    :
                    <div className="p-col-12 p-md-12 p-xl-12" height="100" >

                        <FullCalendarApp />

                    </div>
                }

                </div>


                                {/* Total de Matriculas */}
                <div style={{ paddingTop: "10px", paddingBottom: "10px" }}></div>
                <div className="counter-value-container p-p-1" ><iframe
                    src="https://metabase.app.patosdeminas.mg.gov.br/public/dashboard/fdb54a40-01ee-4d40-9c67-1de6efb84019"
                    frameborder="0"
                    width="1700"
                    height="600"
                    allowtransparency
                 ></iframe></div>

                {/* Total de Tipo de Curso */}
                <div style={{ paddingTop: "10px", paddingBottom: "10px" }}></div>
                <div className="counter-value-container p-p-1" ><iframe
                    src="https://metabase.app.patosdeminas.mg.gov.br/public/dashboard/e1018435-a45e-4184-b25b-9e6092776990"
                    frameborder="0"
                    width="1700"
                    height="600"
                    allowtransparency
                 ></iframe></div>
                 
                </>
            }
        </>
    );
}
