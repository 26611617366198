import React from 'react';
import { TabelaMatriculas } from '../../components/TabelaMatriculas';
import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';


export default function MatriculasPage() {

  return (
    <div className="p-pt-0">
      <MenuMain >
        <TabelaMatriculas />
      </MenuMain>
      <AppFooter />
    </div>
  );
}