import React, { useRef, useEffect, useState, useContext, useCallback } from 'react';

import { useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { DialogMatriculas } from './DialogMatriculas';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';
import {FilterContext} from '../contexts/FilterContext';
import { Dialog } from 'primereact/dialog';
import {AuthContext} from '../contexts/AuthContext';
import { confirmDialog } from 'primereact/confirmdialog';

export const TabelaMatriculas = () => {
    const toast = useRef(null);
    const [matriculasTotal, setMatriculasTotal] = useState([]);
    const [instrumentosTotal, setInstrumentosTotal] = useState([]);
    const [matriculas, setMatriculas] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const confirmationToast = useRef(null)
    const [dt, setDt] = useState(null);
    const history = useHistory();
    const [showListaMatriculas, setShowListaMatriculas] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedConclusao, setSelectedConclusao] = useState(null);
    const [selectedInstrumento, setSelectedInstrumento] = useState(null);
    const [sortable, setSortable] = useState('ASC');
    const [sortableCampo, setSortableCampo] = useState('id');
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tipos, setTipos] = useState([]);
    const [conclusao, setConclusao] = useState([]);
    const filterConclusao = renderFilterConclusao();
    const filterStatus = renderFilterStatus();
    const filterInstrumentos = renderFilterInstrumentos();
    const { filter, setFilter } = useContext(FilterContext);
    const [perfil, setPerfil] = useState([]);
    const [showPerfil, setShowPerfil] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const { user } = useContext(AuthContext);

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Matrículas</h2>
            </div>
            <div className="p-my-0 p-grid p-dir-rev p-col">
                <div className="p-m-2">
                    <Button
                        label="Adicionar"
                        className="p-mb-2 p-button-primary"
                        icon="pi pi-plus"
                        iconPos="left"
                        type="button"

                        onClick={() => history.push('/matriculascreate')}

                    />
                </div>
                <div className="p-m-2">
                    <Button
                        label="Limpar Filtros"
                        className="p-mb-2 p-button-primary"
                        iconPos="left"
                        type="button"
                        onClick={() => handleLimparClick()}
                    />
                </div>
            </div>
        </div>

    );

    const handleLimparClick = () => {
        setFilter({
            first: 0,
            rows: 20,
            sortField: null,
            sortOrder: null,
            filters: {
            },
            
        });
        setSelectedConclusao(null);
        setSelectedStatus(null);
        setSelectedInstrumento(null);
    }
    
    const getMatriculas = useCallback(async (event) => {
        // console.log("Fetching matriculas...");
        // console.log("Current filters:", filter.filters);
    
        setIsLoading(true);
        setMatriculas([]);
    
        if (event !== undefined) {
            setTimeout(() => {
                const startIndex = event.first;
                setFirst(startIndex);
            }, 250);
        }
    
        try {
            if (filter.filters === undefined) {
                const response = await api.get(`matricula?sort=${sortableCampo},${sortable}`);
                response.data.content.forEach(matricula => {
                    setMatriculas(matriculas => [...matriculas, matricula]);
                });
    
                const respElements = await api.get(`matricula?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);
    
            } else {
                const pesquisa = {
                    "aluno": filter.filters['alunoNome'] !== undefined ? filter.filters['alunoNome'].value : "",
                    "instrumentoId": filter.filters['instrumentoId'] !== undefined ? filter.filters['instrumentoId'].value : null,
                    "conclusao": filter.filters['conclusao'] !== undefined ? filter.filters['conclusao'].value : null,
                    "status": filter.filters['status'] !== undefined ? filter.filters['status'].value : null,
                    "turma": filter.filters['turmaSala'] !== undefined ? filter.filters['turmaSala'].value : "",
                    "id": filter.filters['id'] !== undefined ? filter.filters['id'].value : null,
                    "ano": filter.filters['ano'] !== undefined ? filter.filters['ano'].value : null,
                };
                // console.log("Search parameters:", pesquisa);
    
                const respElements = await api.post(`matricula/searchMatricula/${sortable}/${sortableCampo}?`, pesquisa);
                respElements.data.content.forEach(matricula => {
                    setMatriculas(matriculas => [...matriculas, matricula]);
                });
                setTotalRecords(respElements.data.totalElements);
            }
        } catch (error) {
            console.error("Error fetching matriculas:", error);
            toast.current.show({ severity: 'error', summary: 'Erro ao obter matrículas', detail: error.toString() });
        }
    
        setIsLoading(false);
    }, [filter, sortable, sortableCampo]);
    
    

    useEffect(() => {
        const fetchMatriculas = async () => {
            try {
                await getMatriculas();

            }
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchMatriculas();

    }, [getMatriculas])

    const handleDeleteClick = (data) => {

        data['userEmail'] = user.email;

        confirmDialog({
            message: `Você tem certeza que quer remover essa matricula?`,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: async () => {
                setIsDeleting(true);
                try {

                    await api.delete(`matricula/${data.id}`);       
                    toast.current.show({ life: 5000, severity: 'success', summary: 'Remoção concluída!', detail: `Matricula removida com sucesso` });
                    getMatriculas()
                }
                catch (error) {
                    toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover a matricula', detail: error.response.data });
                }
                setIsDeleting(false);
            },
            reject: () => { return }
        });
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-1 edit-btn" onClick={() => handleEditClick(rowData)} />
                <Button icon="pi pi-info" className="p-button-rounded p-mr-1 p-button-secondary" onClick={() => handlePerfilClick(rowData.aluno)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-mr-1 p-button-danger" loading={isDeleting} onClick={() => handleDeleteClick(rowData)}/>
                <Button icon="pi pi-chart-bar" className='p-button-rounded p-mr-1' onClick={() => (rowData.status === 'MATRICULADO' || rowData.status === 'REMATRICULADO') ? handleNotasClick(rowData) : alert('O lançamento de notas só pode ser feito caso o aluno esteja MATRICULADO/REMATRICULADO')} />
            </div>
        );
    }

    const codBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const nomeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Nome</span>
                    <span className="table-data">{rowData.aluno.nome}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataMatriculaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Data Matrícula</span>
                    <span className="table-data">{new Date(rowData.dataMatricula).toLocaleDateString()}</span>
                </p>
            </React.Fragment>
        );
    }

    const conclusaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Conclusão</span>
                    {rowData.conclusao == null
                        ? <span className="table-data">NÃO CONCLUÍDO</span>
                        : <span className="table-data">{new Date(rowData.conclusao).toLocaleDateString()}</span>}
                </p>
            </React.Fragment>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Status</span>
                    <span className="table-data">{rowData.status}</span>
                </p>
            </React.Fragment>
        );
    }

    const instrumentoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Instrumento</span>
                    {rowData.instrumento == null
                        ? <span className="table-data">NÃO INFORMADO</span>
                        : <span className="table-data">{rowData.instrumento.nome}</span>}
                </p>
            </React.Fragment>
        );

    }

    const turmaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Turma</span>
                    <span className="table-data">{(rowData.turma.curso.sigla + " - " + rowData.turma.diaSemana + " - " + rowData.turma.hora + " - " + rowData.turma.perfil.nome)}</span>
                </p>
            </React.Fragment>
        );
    }

    const salaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Sala</span>
                    <span className="table-data">{rowData.turma.sala}</span>
                </p>
            </React.Fragment>
        );
    }

    const notaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Nota</span>
                    {rowData.notaFinal == null
                        ? <span className="table-data">NÃO INFORMADO</span>
                        : <span className="table-data">{rowData.notaFinal.toLocaleString('pt-BR')}</span>}
                </p>
            </React.Fragment>
        );
    }

    const onSort = (event) => {
        setSortableCampo(event.sortField === "alunoNome" ? "aluno.nome" : 
        event.sortField === "turmaSala" ? "turma.sala" :
        event.sortField === "ano" ? "dataMatricula" : 
        event.sortField === "instrumentoId" ? "instrumento.id" :
        event.sortField 
        );
        if (sortable === 'ASC') {
            setSortable('DESC')
        } else if (sortable === 'DESC') {
            setSortable('ASC')
        }
    }

    const onFilter = (event) => {
        event['first'] = 0;
        // setLazyParams(event);
        setFilter(event);  
    }

    function renderFilterStatus() {
        return (
            <Dropdown
                value={selectedStatus}
                options={tipos}
                onChange={(e) => onStatusSearchChange(e)}
                placeholder="Selecione um status"
                className="p-column-filter"
                showClear={true} />
        );
    }

    function renderFilterConclusao() {
        return (
            <Dropdown
                value={selectedConclusao}
                options={conclusao}
                onChange={(e) => onConclusaoSearchChange(e)}
                placeholder="Selecione a conclusão"
                className="p-column-filter"
                showClear={true} />
        );
    }

    function renderFilterInstrumentos() {
        return (
            <Dropdown
                value={selectedInstrumento}
                options={instrumentosTotal}
                onChange={(e) => onInstrumentosSearchChange(e)}
                placeholder="Selecione um instrumento"
                className="p-column-filter"
                showClear={true} 
                optionLabel='nome'
                optionValue='id'
                />
        );
    }

    const onConclusaoSearchChange = (event) => {
        
        if (event.value !== undefined) {
            if (event.value === 'CONCLUIDO') {
                dt.filter('CONCLUIDO', 'conclusao', 'custom'); 
            } else if (event.value === 'NAO_CONCLUIDO') {
                dt.filter('NAO_CONCLUIDO', 'conclusao', 'custom'); 
            }
            setSelectedConclusao(event.value);
        } else {
            setSelectedConclusao(null);
            dt.filter(null, 'conclusao', 'custom');
        }
    };
    
    
    const onStatusSearchChange = (event) => {
        
        if (event.value !== undefined) {
            dt.filter(event.value, 'status', 'equals');
            setSelectedStatus(event.value);
        }
        else {
            setSelectedStatus(null);
            dt.filter(null, 'status', 'equals');
        }
    }

    const onInstrumentosSearchChange = (event) => {
        
        if (event.value !== undefined) {
            dt.filter(event.value, 'instrumentoId', 'equals');
            setSelectedInstrumento(event.value);
        }
        else {
            setSelectedInstrumento(null);
            dt.filter(null, 'instrumentoId', 'equals');
        }
    }

    const handleEditClick = (data) => {

        history.push(`/matriculas/edit/${data.id}`);

    }

    const handleNotasClick = (data) => {

        history.push(`/matriculas/notas/${data.id}`);

    }

    const handlePerfilClick = (perfil) => {
        setShowPerfil(true);
        setPerfil(perfil);
    }

    const handlePrintClick = () => {
        setShowListaMatriculas(true);
    }

    const getInstrumentosAll = useCallback(async () => {
        try {
            const response = await api.get(`curso/instrumento`);
            setInstrumentosTotal(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter instrumentos', detail: error.toString() });
        }
    }, []);

    const getMatriculasAll = useCallback(async () => {
        try {
            const response = await api.get(`matricula/matriculaAll`);
            setMatriculasTotal(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter matriculas', detail: error.toString() });
        }
    }, []);

    const getTipos = useCallback(async () => {
        const response = await api.get(`statusMatricula`);
        setTipos(response.data.map(tipos => {
            var Tipos = { label: '', value: '' };
            Tipos.label = tipos
            Tipos.value = tipos

            return Tipos
        }))
        filter.filters !== undefined && (filter.filters.status !== undefined && filter.filters.status.value != null) ? setSelectedStatus(filter.filters.status.value) : setSelectedStatus(null)    
    }, [filter]);

    const getConclusao = useCallback(async () => {
        setConclusao([
            { label: 'Concluído', value: 'CONCLUIDO' },
            { label: 'Não Concluído', value: 'NAO_CONCLUIDO' }
        ]);
        
        if (filter.filters !== undefined && filter.filters.conclusao !== undefined && filter.filters.conclusao.value != null) {
            setSelectedConclusao(filter.filters.conclusao.value);
        } else {
            setSelectedConclusao(null);
        }
    }, [filter]);
    

    useEffect(() => {

        async function matriculas() {
            await getInstrumentosAll();
            await getMatriculasAll();
            await getTipos();
            await getConclusao();
        };
        matriculas();
    }, [getInstrumentosAll,getMatriculasAll,getTipos])

    
    function formatCpf(data) {

        const cpf = data != null ? data.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4") : data;

        return cpf;
    }

    return (
        <>
            <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

                <DialogMatriculas
                    visible={showListaMatriculas}
                    setVisible={setShowListaMatriculas}
                    matricula={matriculasTotal}
                />
                <Dialog className='p-scrollpanel-bar-y' header="" onHide={() => setShowPerfil(false)} visible={showPerfil} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '50vw', heigth: '5vw' }} 
                >
                    <div>
                        <div className="p-d-flex p-ai-center p-flex-column header">
                            <h2>Perfil</h2>
                        </div>
                        <br></br>
                        <ol>
                            <div className="p-grid"  >
                                <div className="p-col-12"><b>Nome:</b> {perfil.nome}</div>
                                <div className="p-col-12"><b>CPF:</b> {formatCpf(perfil.cpf)}</div>
                                <div className="p-col-12"><b>Email:</b> {perfil.email}</div>
                                <div className="p-col-12"><b>Data de Nascimento:</b> {new Date(perfil.dataNascimento).toLocaleDateString()}</div>
                                <div className="p-col-12"><b>Deficiência:</b> {perfil.deficiencia}</div>
                                <div className="p-col-12"><b>Responsavel:</b> {perfil.responsavel}</div>
                                <div className="p-col-12"><b>Telefone:</b> {perfil.telefone}</div>
                                <div className="p-col-12"><b>Celular:</b> {perfil.celular}</div>
                            </div> 
                        </ol>    
                    </div>    
                </Dialog>
                <div className="p-my-0 p-dir-rev p-col">
                    <div className="p-my-0">
                        <Button
                            textalign="center"
                            icon="pi pi-print"
                            className="p-button-secondary"
                            style={{ width: '35px' }}
                            onClick={handlePrintClick}
                            tooltip="Gerar relatório"
                            tooltipOptions={{ position: 'top' }}
                        />
                    </div>
                </div>

                <Toast ref={toast} position="bottom-right" />

                <Toast ref={confirmationToast} position="bottom-center" />

                <div className="card p-col-12 datatable-responsive-demo">

                    <div className="p-fluid">

                        <DataTable style={{ width: '100%' }}      
                            totalRecords={totalRecords}
                            lazy="true"
                            first={first}
                            onPage={getMatriculas}
                            ref={(element) => setDt(element)}
                            header={header}
                            value={matriculas}
                            filterDisplay="row"
                            className="p-datatable-responsive-demo"
                            emptyMessage="Nenhuma matrícula encontrada"
                            loading={isLoading}
                            dataKey="id"
                            paginator
                            rows={20}
                            currentPageReportTemplate={`Mostrando de {first} a {last} de ${totalRecords} matrículas`}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                            onSort={onSort}
                            onFilter={onFilter}
                            filters={filter.filters}
                        >

                        <Column style={{ width: '5%' }}
                            field="id"
                            header="Código"
                            sortable={sortable}
                            body={codBodyTemplate}
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.id !== undefined && filter.filters.id.value != null) ? filter.filters.id.value : "Pesquisar pelo Código"}
                            
                        />
                        
                        <Column style={{ width: '15%' }}
                            field="alunoNome"
                            header="Nome"
                            sortable={sortable}
                            body={nomeBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.alunoNome !== undefined && filter.filters.alunoNome.value !== "") ? filter.filters.alunoNome.value : "Pesquisar pelo nome"}
                            
                        />      

                        <Column style={{ width: '10%' }}
                            field="ano"
                            header="Data Matrícula"
                            sortable={sortable}
                            body={dataMatriculaBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.ano !== undefined && filter.filters.ano.value !== "") ? filter.filters.ano.value : "Ano da turma"}

                            />

                        <Column style={{ width: '10%' }}
                            field="conclusao"
                            header="Conclusão"
                            sortable={sortable}
                            body={conclusaoBodyTemplate}   
                            filter
                            filterMatchMode="custom"
                            filterElement={filterConclusao}                 
                            />

                        <Column style={{ width: '10%' }}
                            field="status"
                            header="Status"
                            sortable={sortable}
                            body={statusBodyTemplate}                            
                            filter
                            filterMatchMode="custom"
                            filterElement={filterStatus}
                            />

                        <Column style={{ width: '10%' }}
                            field="instrumentoId"
                            header="Instrumento"
                            sortable={sortable}
                            body={instrumentoBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterElement={filterInstrumentos}                            
                            />

                        <Column style={{ width: '20%' }}
                            field="turmaSala"
                            header="Turma"
                            sortable={sortable}
                            body={turmaBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.turmaSala !== undefined && filter.filters.turmaSala.value !== "") ? filter.filters.turmaSala.value : "Pesquisar pela turma"}

                            />

                        <Column style={{ width: '5%' }}
                            field="sala"
                            header="Sala"
                            body={salaBodyTemplate}

                        />

                        <Column style={{ width: '5%' }}
                            field="notaFinal"
                            header="Nota"
                            body={notaBodyTemplate}

                        />

                        <Column style={{ width: '10%' }}
                            header="Ações"
                            body={actionBodyTemplate}

                            />

                        </DataTable>

                    </div>
                </div>
            </div>

        </>
    );
}