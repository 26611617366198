import { useContext } from 'react';
import { PrimeiroAcessoContext } from '../contexts/PrimeiroAcessoContext';

export const usePrimeiroAcesso = () => {
    const {
        activeIndex,
        setActiveIndex,
        visible,
        setVisible,
        aceite,
        setAceite,
        perfil,
        setPerfil,
        socioeconomico,
        setSocioeconomico,
        criancas,
        setCriancas,
        criancasPrimeiroAcesso,
        setCriancasPrimeiroAcesso,
        inscricoes,
        setInscricoes,
        responsaveis,
        setResponsaveis,
        atividades,
        setAtividades,
        rendas,
        setRendas,
        orgaos,
        setOrgaos,
        distritos,
        setDistritos,
        isLoading,
        setIsLoading,
        getUsefullsData
    } = useContext(PrimeiroAcessoContext)

    return {
        activeIndex,
        setActiveIndex,
        visible,
        setVisible,
        aceite,
        setAceite,
        perfil,
        setPerfil,
        socioeconomico,
        setSocioeconomico,
        criancas,
        setCriancas,
        criancasPrimeiroAcesso,
        setCriancasPrimeiroAcesso,
        inscricoes,
        setInscricoes,
        responsaveis,
        setResponsaveis,
        atividades,
        setAtividades,
        rendas,
        setRendas,
        orgaos,
        setOrgaos,
        distritos,
        setDistritos,
        isLoading,
        setIsLoading,
        getUsefullsData
    };
}