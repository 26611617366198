import React, { useContext, useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Checkbox } from 'primereact/checkbox';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import { BrCalendar } from './BrCalendar';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Image } from 'primereact/image';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ErrorValidationMessage } from './ErrorValidationMessage';
import { TabelaMatriculasPerfil } from './TabelaMatriculasPerfil';
import api from '../api/api';
import '../layout/sass/_form.scss';
import { AuthContext } from '../contexts/AuthContext';

export const FormPerfis = ({ isEditing, isLoading, defaultValues }) => {

    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [showLGPD, setShowLGPD] = useState(false);
    const [showSemDocs, setShowSemDocs] = useState(false);
    const [showDocs, setShowDocs] = useState(false);
    const [docsAnexo, setDocsAnexo] = useState([]);

    const { signUp } = useContext(AuthContext);

    const getDocs = async () => {

        let nome = formik.values.cpf;

        let docs = [];

        const doc = await api({
            url: `/matricula/download/${nome}doc`,
            method: 'GET',
            responseType: 'blob'
        });

        const end = await api({
            url: `/matricula/download/${nome}endereco`,
            method: 'GET',
            responseType: 'blob'
        });

        console.log(end);

        docs.push({
            src: URL.createObjectURL(doc.data),
            type: doc.headers['content-type'],
            tipoAnexo: 'Documento'
        });
        docs.push({
            src: URL.createObjectURL(end.data),
            type: end.headers['content-type'],
            tipoAnexo: 'Comprovante de Endereço'
        });

        setDocsAnexo(docs);
        if (doc.data === "") {
            setShowSemDocs(true);
        }
        else {
            setShowDocs(true);
        }

    }

    const schema = Yup.object().shape({
        nome: Yup
            .string()
            .required("O nome é obrigatório")
            .max(200, "O nome dete ter no máximo 200 caracteres"),
        cpf: Yup
            .string()
            .required("O CPF é obrigatório"),
        email: Yup
            .string()
            .max(198, "O e-mail deve ter no máximo 198 caracteres")
            .required("O e-mail é obrigatório")
            .email("O e-mail inserido é inválido"),
        dataNascimento: Yup
            .date()
            .max(new Date(), "Data de nascimento inválida")
            .typeError("Uma data deve ser inserida")
            .required("A data de nascimento é obrigatória"),
        celular: Yup
            .string()
            .required("O celular é obrigatório"),
        lgpdAceite: Yup
            .boolean()
            .required("É obrigatório aceitar os termos para prosseguir")
            .oneOf([true], "É obrigatório aceitar os termos para prosseguir"),
        instrutor: Yup
            .boolean(),

    })

    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)

            try {
                if (!isEditing) {
                    await api.post("perfil", data)

                    if (data.tipo === 'ADMIN' || data.tipo === 'INSTRUTOR') {
                        await signUp(data.email, 'conservatorio123', data.nome);
                    }

                    formik.resetForm()
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `${data.nome} adicionado(a) com sucesso` });
                    setTimeout(() => {
                        history.push('/perfis')

                    }, 2000)

                }
                else {

                    await api.put("perfil", data)

                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `${data.nome} atualizado(a) com sucesso` });
                    setTimeout(() => {

                        history.push('/perfis')

                    }, 2000)
                }
            }
            catch (error) {
                if (!isEditing)
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao cadastrar perfil!', detail: error.toString() });
                else
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar perfil!', detail: error.toString() });

            }

            setIsSubmiting(false)
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const monName = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];
    const now = new Date();
    let day = now.getDate();
    let month = now.getMonth();
    let year = now.getFullYear();

    return (
        <>
            <Toast ref={toast} position="bottom-right" />
            <Dialog
                visible={showLGPD}
                onHide={() => setShowLGPD(false)} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '50vw', heigth: '5vw' }}>

                <div className="termo-container">
                    <ScrollPanel className="termo-content">
                        <h1>
                            TERMO DE CONSENTIMENTO PARA TRATAMENTO DE DADOS PESSOAIS LEI GERAL DE PROTEÇÃO DE DADOS PESSOAIS – LGPD
                        </h1>
                        <h4>CLÁUSULA PRIMEIRA</h4>
                        <p>
                            Ao utilizar os serviços, você declara o seu <b>EXPRESSO CONSENTIMENTO</b> para coletarmos, tratarmos e
                            armazenarmos dados sobre você e dependentes com a finalidade de <b>cadastramento escolar</b> com objetivo de
                            mapeamento de demanda de vagas  em nossos cursos e uma melhor alocação/distribuição de alunos e
                            professores.
                        </p>
                        <p>
                            Fica definido que o Conservatório será a <b>CONTROLADORA</b> e o usuário dos serviços será
                            o <b>TITULAR</b> dos dados coletados.
                        </p>
                        <h5>Dados Pessoais</h5>
                        <p>
                            O Titular autoriza a Controladora a realizar o tratamento, ou seja, a utilizar os seguintes dados pessoais
                            e também de seus dependentes, para os fins que serão relacionados na cláusula segunda:
                        </p>
                        <ul>
                            <li>Nome completo;</li>
                            <li>Data de nascimento;</li>
                            <li>Número Cadastro de Pessoas Físicas (CPF);</li>
                            <li>Endereço completo;</li>
                            <li>Números de telefone e endereço de e-mail.</li>
                        </ul>
                        <h4>CLÁUSULA SEGUNDA</h4>
                        <h5>Finalidade do Tratamento dos Dados</h5>
                        <p>O Titular autoriza a Controladora a utilizar os dados pessoais e dados pessoais sensíveis listados neste termo para as seguintes finalidades:</p>
                        <ul>
                            <li>Permitir que a Controladora identifique e entre em contato com o titular, em razão do cadastramento escolar;</li>
                            <li>Para cumprimento, pela Controladora, de obrigações impostas por órgãos de fiscalização;</li>
                            <li>Para a proteção da vida ou da incolumidade física do titular e seus dependentes;</li>
                            <li>Quando necessário para atender aos interesses legítimos da controladora, exceto no caso de prevalecerem direitos e liberdades fundamentais do titular que exijam a proteção dos dados pessoais.</li>
                        </ul>
                        <p>Parágrafo Primeiro: Caso seja necessário o compartilhamento de dados com terceiros que não tenham sido
                            relacionados nesse termo ou qualquer alteração contratual posterior, será ajustado novo termo de
                            consentimento para este fim (§ 6° do artigo 8° e § 2° do artigo 9° da Lei n° 13.709/2018).
                        </p>
                        <p>
                            Parágrafo Segundo: Em caso de alteração na finalidade, que esteja em desacordo com o consentimento original,
                            a Controladora deverá comunicar ao Titular, que poderá revogar o consentimento, conforme previsto na cláusula
                            sexta.
                        </p>
                        <h4>CLÁUSULA TERCEIRA</h4>
                        <h5>Compartilhamento de Dados</h5>
                        <p>
                            A Controladora fica autorizada a compartilhar os dados pessoais do Titular com outros agentes de tratamento
                            de dados, caso seja necessário para as finalidades listadas neste instrumento, desde que, sejam respeitados
                            os princípios da boa-fé, finalidade, adequação, necessidade, livre acesso, qualidade dos dados, transparência,
                            segurança, prevenção, não discriminação e responsabilização e prestação de contas.
                        </p>
                        <h4>CLÁUSULA QUARTA</h4>
                        <h5>Responsabilidade pela Segurança dos Dados</h5>
                        <p>
                            A Controladora se responsabiliza por manter medidas de segurança, técnicas e administrativas suficientes a
                            proteger os dados pessoais do Titular e à Autoridade Nacional de Proteção de Dados (ANPD), comunicando ao
                            Titular, caso ocorra algum incidente de segurança que possa acarretar risco ou dano relevante, conforme
                            artigo 48 da Lei n° 13.709/2018.
                        </p>
                        <h4>CLÁUSULA QUINTA</h4>
                        <h5>Término do Tratamento dos Dados</h5>
                        <p>
                            À Controladora, é permitido manter e utilizar os dados pessoais do Titular durante todo o período
                            contratualmente firmado para as finalidades relacionadas nesse termo e ainda após o término da contratação
                            para cumprimento de obrigação legal ou impostas por órgãos de fiscalização, nos termos do artigo 16 da Lei
                            n° 13.709/2018.
                        </p>
                        <h4>CLÁUSULA SEXTA</h4>
                        <h5>Direito de Revogação do Consentimento</h5>
                        <p>
                            O Titular poderá revogar seu consentimento, a qualquer tempo, por e-mail ou por carta escrita, conforme o
                            artigo 8°, § 5°, da Lei n° 13.709/2018.
                        </p>
                        <p>
                            O Titular fica ciente de que a Controladora poderá permanecer utilizando os dados para as seguintes finalidades:
                        </p>

                        <h4>CLÁUSULA SÉTIMA</h4>
                        <h5>Tempo de Permanência dos Dados Recolhidos</h5>
                        <p>
                            O titular fica ciente de que a Controladora deverá permanecer com os seus dados pelo período mínimo de
                            guarda de documentos que for necessário para as <b>campanhas de cadastramento do Conservatório</b>.
                        </p>
                        <h4>CLÁUSULA OITAVA</h4>
                        <h5>Vazamento de Dados ou Acessos Não Autorizados – Penalidades</h5>
                        <p>
                            As partes poderão entrar em acordo, quanto aos eventuais danos causados, caso exista o vazamento de dados
                            pessoais ou acessos não autorizados ocorridos comprovadamente por falha de segurança da Controladora, e
                            caso não haja acordo, a Controladora tem ciência que estará sujeita às penalidades previstas no artigo 52
                            da Lei n° 13.709/2018.
                        </p>
                        <br></br>
                        <span>Patos de Minas, {day} de {monName[month]} de {year}.</span>
                    </ScrollPanel>
                </div>
            </Dialog>

            <Dialog
                visible={showDocs}
                onHide={() => setShowDocs(false)}
                className="p-fluid"
                style={{ width: '60vw', maxWidth: '1650px' }}>

                <div style={{ marginBottom: '2rem' }}>
                    <div>
                        <label
                            htmlFor="tipo"
                            className={classNames({ 'p-error': isFormFieldValid('tipo') })}
                        >
                            Documentos Anexados
                        </label>
                        {
                            !isLoading ?
                            <div className="p-grid" style={{ marginTop: '2vw' }}>
                            {docsAnexo.map(item => (
                                <div className="p-col-6">
                                    {(item.type === 'image/png' || item.type === 'image/jpeg') ? (
                                    <img src={item.src} alt="" className="w-full h-auto"
                                    style={{ maxWidth: '600px', maxHeight: '800px' }} />
                                    ) : (
                                    <iframe src={item.src} frameborder="0" title="doc" style={{ width: '100%', height: '300px' }}></iframe>
                                    )}
                                </div>
                            ))}
                            </div>
                                :
                                <Skeleton height="35px" />
                        }

                        {formik.errors.tipo && formik.touched.tipo &&
                            <ErrorValidationMessage message={formik.errors.tipo} />
                        }
                    </div>
                </div>
            </Dialog>
            <Dialog
                visible={showSemDocs}
                onHide={() => setShowSemDocs(false)} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '50vw', heigth: '5vw' }}>

                <div style={{ display: 'flex', justifyContent: 'center' }} className="p-field">
                    <label
                        style={{ fontWeight: 'bold', fontSize: '20px' }}
                    >

                        Não há documentos Anexados!
                    </label>

                    {formik.errors.tipo && formik.touched.tipo &&
                        <ErrorValidationMessage message={formik.errors.tipo} />
                    }
                </div>
            </Dialog>
            <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
                <Toast ref={toast} position="bottom-right" />
                <div className="p-col-12">
                    <h2 className="p-mt-0">Perfis</h2>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-formgrid p-grid p-fluid p-mx-0">
                            <div className="p-field p-col-12 p-sm-4">
                                <label
                                    htmlFor="nome"
                                    className={classNames({ 'p-error': isFormFieldValid('nome') })}
                                >
                                    Nome
                                </label>
                                {
                                    !isLoading ?
                                        <InputText
                                            id="nome"
                                            name="nome"
                                            className={classNames({ 'p-invalid': isFormFieldValid('nome') })}
                                            value={formik.values.nome}
                                            onChange={formik.handleChange}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.nome && formik.touched.nome &&
                                    <ErrorValidationMessage message={formik.errors.nome} />
                                }
                            </div>
                            <div className="p-field p-col-12 p-sm-4">
                                <label
                                    htmlFor="cpf"
                                    className={classNames({ 'p-error': isFormFieldValid('cpf') })}
                                >
                                    CPF
                                </label>
                                {
                                    !isLoading ?
                                        <InputMask
                                            id="cpf"
                                            name="cpf"
                                            mask="999.999.999-99"
                                            unmask={true}
                                            value={formik.values.cpf}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('cpf') })}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.cpf && formik.touched.cpf &&
                                    <ErrorValidationMessage message={formik.errors.cpf} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-4">
                                <label
                                    htmlFor="email"
                                    className={classNames({ 'p-error': isFormFieldValid('email') })}
                                >
                                    E-mail
                                </label>
                                {
                                    !isLoading ?
                                        <InputText
                                            id="email"
                                            name="email"
                                            className={classNames({ 'p-invalid': isFormFieldValid('email') })}
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.email && formik.touched.email &&
                                    <ErrorValidationMessage message={formik.errors.email} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-4 p-md-4">
                                <label
                                    htmlFor="dataNascimento"
                                    className={classNames({ 'p-error': isFormFieldValid('dataNascimento') })}
                                >
                                    Data de nascimento
                                </label>
                                <BrCalendar
                                    id="dataNascimento"
                                    name="dataNascimento"
                                    value={formik.values.dataNascimento}
                                    onChange={formik.handleChange}
                                    placeholder={isEditing ? new Date(formik.values.dataNascimento).toLocaleDateString('pt-BR', { timeZone: 'UTC' }) : ""}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dataNascimento') })}
                                    yearRange={`1922:${new Date().getFullYear()}`}
                                />
                                {formik.errors.dataNascimento && formik.touched.dataNascimento &&
                                    <ErrorValidationMessage message={formik.errors.dataNascimento} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-4">
                                <label
                                    htmlFor="responsavel"
                                    className={classNames({ 'p-error': isFormFieldValid('responsavel') })}
                                >
                                    Nome do Resposável (Caso menor de idade)
                                </label>
                                {
                                    !isLoading ?
                                        <InputText
                                            id="responsavel"
                                            name="responsavel"
                                            className={classNames({ 'p-invalid': isFormFieldValid('responsavel') })}
                                            value={formik.values.responsavel}
                                            onChange={formik.handleChange}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.responsavel && formik.touched.responsavel &&
                                    <ErrorValidationMessage message={formik.errors.responsavel} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-4">
                                <label
                                    htmlFor="celular"
                                    className={classNames({ 'p-error': isFormFieldValid('celular') })}
                                >
                                    Telefone Celular
                                </label>
                                {
                                    !isLoading ?
                                        <InputMask
                                            id="celular"
                                            name="celular"
                                            unmask={true}
                                            value={formik.values.celular}
                                            mask={formik.values.celular !== undefined && formik.values.celular.length <= 10 ? "(99) 9999-9999?9" : "(99) 99999-9999"}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('celular') })}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.celular && formik.touched.celular &&
                                    <ErrorValidationMessage message={formik.errors.celular} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-4">
                                <label
                                    htmlFor="telefone"
                                    className={classNames({ 'p-error': isFormFieldValid('telefone') })}
                                >
                                    Telefone Fixo
                                </label>
                                {
                                    !isLoading ?
                                        <InputMask
                                            id="telefone"
                                            name="telefone"
                                            mask={formik.values.telefone !== undefined && formik.values.telefone !== null && formik.values.telefone.length <= 10 ? "(99) 9999-9999?9" : "(99) 99999-9999"}
                                            unmask={true}
                                            value={formik.values.telefone}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('telefone') })}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.telefone && formik.touched.telefone &&
                                    <ErrorValidationMessage message={formik.errors.telefone} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-4">
                                <label
                                    htmlFor="tipo"
                                    className={classNames({ 'p-error': isFormFieldValid('tipo') })}
                                    style={{ textAlign: 'justify', textJustify: 'distribute' }}
                                >
                                    Tipo de Perfil
                                </label>
                                {
                                    !isLoading ?
                                        <Dropdown
                                            id="tipo"
                                            name="tipo"
                                            value={formik.values.tipo}
                                            options={formik.values.tipoPerfis}
                                            emptyMessage="Nenhum tipo encontrado"
                                            emptyFilterMessage={"Nenhum tipo encontrado"}
                                            filter={true}
                                            onChange={(e) => formik.setFieldValue("tipo", e.value)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('tipo') })}
                                            placeholder="Selecione o tipo de perfil"
                                            showClear={true}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }

                                {formik.errors.tipo && formik.touched.tipo &&
                                    <ErrorValidationMessage message={formik.errors.tipo} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-4">
                                <label
                                    htmlFor="deficiencia"
                                    className={classNames({ 'p-error': isFormFieldValid('deficiencia') })}
                                    style={{ textAlign: 'justify', textJustify: 'distribute' }}
                                >
                                    Tipo de deficiência
                                </label>
                                {
                                    !isLoading ?
                                        <Dropdown
                                            id="deficiencia"
                                            name="deficiencia"
                                            value={formik.values.deficiencia === "deficienciaAuditiva" ? "DEFICIÊNCIA AUDITIVA"
                                                : formik.values.deficiencia === "deficienciaVisual" ? "DEFICIÊNCIA VISUAL"
                                                    : formik.values.deficiencia === "deficienciaIntelectual" ? "DEFICIÊNCIA INTELECTUAL"
                                                        : formik.values.deficiencia === "deficienciaMotora" ? "DEFICIÊNCIA MOTORA"
                                                            : formik.values.deficiencia === "sindromeDeDown" ? "SINDROME DE DOWN"
                                                                : formik.values.deficiencia}
                                            options={formik.values.deficienciaPerfis}
                                            emptyMessage="Nenhuma deficiência encontrada"
                                            emptyFilterMessage={"Nenhuma deficiência encontrada"}
                                            filter={true}
                                            onChange={(e) => formik.setFieldValue("deficiencia",
                                                e.value === "DEFICIÊNCIA AUDITIVA" ? "deficienciaAuditiva"
                                                    : e.value === "DEFICIÊNCIA VISUAL" ? "deficienciaVisual"
                                                        : e.value === "DEFICIÊNCIA INTELECTUAL" ? "deficienciaIntelectual"
                                                            : e.value === "DEFICIÊNCIA MOTORA" ? "deficienciaMotora"
                                                                : e.value === "SINDROME DE DOWN" ? "sindromeDeDown"
                                                                    : e.value)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('deficiencia') })}
                                            placeholder="Selecione a deficiência"
                                            showClear={true}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }

                                {formik.errors.deficiencia && formik.touched.deficiencia &&
                                    <ErrorValidationMessage message={formik.errors.deficiencia} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-4">
                                <label
                                    htmlFor="tipo"
                                    className={classNames({ 'p-error': isFormFieldValid('tipo') })}
                                    style={{ textAlign: 'justify', textJustify: 'distribute' }}
                                >
                                    Ver Docs Anexos
                                </label>


                                <Button
                                    label="Arquivos"
                                    type='button'
                                    onClick={getDocs}
                                    iconPos="right"
                                    disabled={formik.values.cpf === "" ? true : false}
                                />

                                {formik.errors.tipo && formik.touched.tipo &&
                                    <ErrorValidationMessage message={formik.errors.tipo} />
                                }
                            </div>

                            <div className="p-field p-col-12">
                                <label
                                    htmlFor="lgpdAceite"
                                    className={classNames({ 'p-error': isFormFieldValid('lgpdAceite') })}
                                    style={{ textAlign: 'justify', textJustify: 'distribute' }}
                                >
                                    <Checkbox
                                        id="lgpdAceite"
                                        name="lgpdAceite"
                                        checked={formik.values.lgpdAceite}
                                        onChange={() => formik.setFieldValue('lgpdAceite', !formik.values.lgpdAceite)}
                                        className={classNames({ 'p-invalid': isFormFieldValid('lgpdAceite') })}
                                        style={{ marginRight: '5px' }}
                                    />
                                    Estou ciente e de acordo com os termos <span style={{ color: "#2196f3" }} onClick={() => setShowLGPD(true)}>(LGPD)</span>.
                                </label>
                                {formik.errors.lgpdAceite && formik.touched.lgpdAceite &&
                                    <ErrorValidationMessage message={formik.errors.lgpdAceite} />
                                }
                            </div>

                            <p><b>ATENÇÃO:</b> A senha padrão para instrutores e administratores é conservatorio123. É recomendado alterar a mesma no primeiro acesso.</p>

                        </div>
                        <div className="p-d-flex p-jc-end p-mr-1">
                            {
                                !isLoading ?
                                    <Button
                                        label={isEditing ? 'Atualizar' : 'Salvar'}
                                        type="submit"
                                        iconPos="right"
                                        loading={isSubmiting}
                                    />
                                    :
                                    <Skeleton width="82.16px" height="35px" />

                            }
                        </div>
                    </form>
                </div>
                <div className="p-col-12">
                    {
                        isEditing ?
                            <TabelaMatriculasPerfil
                                matriculasPerfil={defaultValues.id}
                            />
                            :
                            <></>
                    }

                </div>
            </div>
        </>
    );
}