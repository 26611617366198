import React from 'react';
import MenuMain from '../../MenuMain';
import { FormFalta } from '../../components/FormFalta';
import { AppFooter } from '../../AppFooter';


export default function CadastrarFaltaPage() {

  const defaultValues = {
    'matriculaId': 0,
    'turmaId': 0,
    'obs': '',
    'alunoNome': '',
    'dataOcorrencia': new Date()
  }

  return (
    <div>
      <MenuMain>
         <FormFalta
          defaultValues={defaultValues}
        />
      </MenuMain>
      <AppFooter />
    </div>
  );
}
