import React from 'react';
import { TabelaPerfis } from '../../components/TabelaPerfis';
import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';


export default function PerfisPage() {

  return (
    <div className="p-pt-0">
      <MenuMain >
        <TabelaPerfis />
      </MenuMain>
      <AppFooter />
    </div>
  );
}