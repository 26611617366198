import React, { useCallback, useEffect, useState } from 'react';

import { FormPeriodoMatricula } from '../../components/FormPeriodoMatricula';
import { ErrorRetrievingData } from '../../components/ErrorRetrievingData';
import MenuMain from '../../MenuMain';

import { AppFooter } from '../../AppFooter';

import api from '../../api/api';

export default function EditarPeriodoMatriculaPage(props) {

    const [periodoMatricula, setPeriodoMatricula] = useState({});
    const [titulo, setTitulo] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorGettingPeriodoMatricula, setErrorGettingPeriodoMatricula] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [idPeriodoMatricula] = useState(props.match.params.id)


    const getPeriodoMatricula = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await api.get(`periodoMatricula/${idPeriodoMatricula}`)
            
            setPeriodoMatricula({
                'id': response.data.id,
                'dataInicial': response.data.dataInicial,
                'dataFinal': response.data.dataFinal,
                'tipo': response.data.tipo,
            })
            if (response.data.tipo === "REMATRICULA")
            {
                setTitulo("Periodo da Rematricula")
            }
            else
            {
                setTitulo("Periodo da Matricula")
            }
            setErrorGettingPeriodoMatricula(false)
        }
        catch (error) {
            setErrorMessage(error.toString())
            setErrorGettingPeriodoMatricula(true)
        }

        setIsLoading(false);
    }, [idPeriodoMatricula]);

    useEffect(() => {
        async function periodo() {
            await getPeriodoMatricula();
        };
        periodo();
    }, [getPeriodoMatricula])

    return (
        <>
            <MenuMain >
                {
                    errorGettingPeriodoMatricula ?
                        <ErrorRetrievingData
                            errorMessage={errorMessage}
                            retrieveFunction={() => getPeriodoMatricula(idPeriodoMatricula)}
                            isLoading={isLoading}
                        />
                        :
                        <FormPeriodoMatricula
                            defaultValues={periodoMatricula}
                            isEditing={true}
                            isLoading={isLoading}
                            titulo={titulo}
                        />
                }
            </MenuMain>
            <AppFooter />
        </>
    );
}