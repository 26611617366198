import React, { useCallback, useEffect, useState } from 'react';

import { FormPerfis } from '../../components/FormPerfis';
import { ErrorRetrievingData } from '../../components/ErrorRetrievingData';
import MenuMain from '../../MenuMain';

import { AppFooter } from '../../AppFooter';

import api from '../../api/api';

export default function EditarPerfisPage(props) {

    const [perfis, setPerfis] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorGettingPerfis, setErrorGettingPerfis] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [idPerfis] = useState(props.match.params.id)


    const getPerfis = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await api.get(`perfil/${idPerfis}`)
            
            setPerfis({
                'id': response.data.id,
                'nome': response.data.nome,
                'cpf': response.data.cpf,
                'email': response.data.email,
                'dataNascimento': response.data.dataNascimento,
                'responsavel': response.data.responsavel,
                'celular': response.data.celular, 
                'telefone': response.data.telefone,
                'lgpdAceite': response.data.lgpdAceite,
                'tipo': response.data.tipo,
                'tipoPerfis': ['INSCRITO', 'ALUNO', 'INSTRUTOR', 'ADMIN'],
                'deficiencia': response.data.deficiencia,
                'deficienciaPerfis': ['NENHUM', 'DEFICIÊNCIA AUDITIVA', 'DEFICIÊNCIA VISUAL', 'DEFICIÊNCIA INTELECTUAL', 'DEFICIÊNCIA MOTORA', 'AUTISMO', 'TDAH', 'SINDROME DE DOWN', 'OUTROS'],

            })

            setErrorGettingPerfis(false)
        }
        catch (error) {
            setErrorMessage(error.toString())
            setErrorGettingPerfis(true)
        }

        setIsLoading(false);
    }, [idPerfis]);

    useEffect(() => {

        async function perfis() {
            await getPerfis();
        };
        perfis();

    }, [getPerfis])

    return (
        <>
            <MenuMain >
                {
                    errorGettingPerfis ?
                        <ErrorRetrievingData
                            errorMessage={errorMessage}
                            retrieveFunction={() => getPerfis(idPerfis)}
                            isLoading={isLoading}
                        />
                        :
                        <FormPerfis
                            defaultValues={perfis}
                            isEditing={true}
                            isLoading={isLoading}
                        />
                }
            </MenuMain>
            <AppFooter />
        </>
    );
}