import React from 'react';

import { FormHistoricoPerfil } from '../../components/FormHistoricoPerfil';
import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';

export default function InscricoesPage() {

  return (
    <>
      <MenuMain >
        <FormHistoricoPerfil />
      </MenuMain>
      <AppFooter />
    </>
  );
}
