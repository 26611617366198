import React, { useContext } from 'react';
import { TabelaFalta } from '../../components/TabelaFalta';
import { TabelaFaltaInstrutor } from '../../components/TabelaFaltaInstrutor';
import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';

import { AuthContext } from '../../contexts/AuthContext';

export default function FaltaPage() {

  const { user } = useContext(AuthContext);

  return (
    <div className="p-pt-0">
      <MenuMain>
      {user.perfilUser[0] !== 'user-instrutor' ?
        <TabelaFalta />
        :
        <TabelaFaltaInstrutor />
      }
      </MenuMain>
      <AppFooter />
    </div>
  );
}