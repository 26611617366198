import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Printd } from 'printd';

import { TabView, TabPanel } from 'primereact/tabview';

import logo2 from '../assets/logo_conservatorio.jpg';

export const DialogAcessoAlunos = ({ visible, setVisible, nota, falta }) => {

    const [activeIndex, setActiveIndex] = useState(0);

   
    
    const listaNotas = nota.map((item) => {
        
        return (
                <tr key={item.id}>
                    <td align='center'> <span>{item.id}</span></td>
                    <td> <span>{item.matricula.aluno.nome}</span></td>
                    <td align='center'> <span>{item.notaAluno}</span></td>
                    <td> <span>{item.matricula.turma.perfil.nome}</span></td>
                    <td> <span>{item.matricula.turma.curso.nome}</span></td>
                    <td align='center'> <span>{item.avaliacao.notaTotal}</span></td>
                    <td> <span>{item.avaliacao.tipo}</span></td>
                </tr>
        )
    })


    const listaFaltas = falta.map((item) => {
        return (
                <tr key={item.id} style={{ textTransform: 'uppercase'}}>
                    <td align='center'> <span>{item.id}</span></td>
                    <td> <span>{item.matricula.aluno.nome}</span></td>
                    <td> <span>{item.obs}</span></td>
                    <td> <span>{new Date(item.dataOcorrencia + ' 03:00:00.000').toLocaleDateString()}</span></td>
                </tr>
        )
    })

    

    

    return (
        <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            className='p-scrollpanel-bar-y'
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            style={{ width: '60vw', heigth: '5vw' }}
        >
            {/* {nota.length > 0 ? */}
                <div id="print-content">                    
                    <div className="p-d-flex p-ai-center p-flex-column header">
                        <h2>Área do Aluno</h2>

                    </div>


                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Avaliações">
                        <table width="100%" border="1" style={{'borderCollapse': 'collapse'}} cellPadding={3} cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th><i>Código</i></th>
                                    <th><i>Nome do aluno</i></th>
                                    <th><i>Nota do aluno</i></th>
                                    <th><i>Nome do Instrutor</i></th>
                                    <th><i>Turma</i></th>
                                    <th><i>Nota Total</i></th>
                                    <th><i>Tipo de Avaliação</i></th>
                                </tr>
                            </thead>

                            <tbody>
                                {listaNotas}
                            </tbody>
                            
                            
                        </table>
                    </TabPanel>
                    <TabPanel header="Faltas">
                        <table width="100%" border="1" style={{'borderCollapse': 'collapse'}} cellPadding={3} cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th><i>Código</i></th>
                                    <th><i>Nome</i></th>
                                    <th><i>Observação</i></th>
                                    <th><i>Data Ocorrencia</i></th>
                                </tr>
                            </thead>

                            <tbody>
                                {listaFaltas}
                            </tbody>
                            
                            
                        </table>
                    </TabPanel>
                   
                </TabView>



                    

                </div>
                {/* :

                null
            } */}


        </Dialog>
    );
}