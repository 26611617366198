import React, { useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorValidationMessage } from './ErrorValidationMessage';

import api from '../api/api';

import '../layout/sass/_form.scss';
import { Dropdown } from 'primereact/dropdown';
import { TabelaTurmasCurso } from './TabelaTurmasCurso';

export const FormCursos = ({ isEditing, isLoading, defaultValues }) => {

    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)
    
    const schema = Yup.object().shape({
        nome: Yup
            .string()
            .required("O nome é obrigatório")
            .max(200, "O nome dete ter no máximo 200 caracteres"),        
        obs: Yup
            .string(),
        sigla: Yup
            .string()
            .required("O nome é obrigatório")
            .max(200, "O nome dete ter no máximo 200 caracteres"),     
        tipo: Yup
            .string()
            .required("O tipo é obrigatório")
    })

    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)                
          
            try {
                if (!isEditing) {
                    await api.post("curso", data)

                    formik.resetForm()
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `${data.nome} adicionado(a) com sucesso` });
                    setTimeout(() => {
                        history.push('/cursos')

                    }, 2000)

                }
                else {
                    await api.put("curso", data)
                    
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `${data.nome} atualizado(a) com sucesso` });
                    setTimeout(() => {
                        
                        history.push('/cursos')

                    }, 2000)
                }
            }
            catch (error) {
                if (!isEditing)
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao cadastrar curso!', detail: error.toString() });
                else
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar curso!', detail: error.toString() });

            }

            setIsSubmiting(false)
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    
    return (
        <>
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <div className="p-col-12">
                <h2 className="p-mt-0">Cursos</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-fluid p-mx-0">
                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="nome"
                                className={classNames({ 'p-error': isFormFieldValid('nome') })}
                            >
                                Nome
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="nome"
                                        name="nome"
                                        className={classNames({ 'p-invalid': isFormFieldValid('nome') })}
                                        value={formik.values.nome}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.nome && formik.touched.nome &&
                                <ErrorValidationMessage message={formik.errors.nome} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="obs"
                                className={classNames({ 'p-error': isFormFieldValid('obs') })}
                            >
                                Observação
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="obs"
                                        name="obs"
                                        className={classNames({ 'p-invalid': isFormFieldValid('obs') })}
                                        value={formik.values.obs}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.obs && formik.touched.obs &&
                                <ErrorValidationMessage message={formik.errors.obs} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="sigla"
                                className={classNames({ 'p-error': isFormFieldValid('sigla') })}
                            >
                                Sigla
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="sigla"
                                        name="sigla"
                                        className={classNames({ 'p-invalid': isFormFieldValid('sigla') })}
                                        value={formik.values.sigla}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.sigla && formik.touched.sigla &&
                                <ErrorValidationMessage message={formik.errors.sigla} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="tipo"
                                className={classNames({ 'p-error': isFormFieldValid('tipo') })}
                            >
                                Tipo
                            </label>
                            {
                                !isLoading ?
                                    <Dropdown
                                        id="tipo"
                                        name="tipo"
                                        value={formik.values.tipo}
                                        options={formik.values.tipos}
                                        className={classNames({ 'p-invalid': isFormFieldValid('tipo') })}
                                        onChange={formik.handleChange}
                                    /> 
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.tipo && formik.touched.tipo &&
                                <ErrorValidationMessage message={formik.errors.tipo} />
                            }
                        </div>

                    </div>
                    <div className="p-d-flex p-jc-end p-mr-1">
                        {
                            !isLoading ?
                                <Button
                                    label={isEditing ? 'Atualizar' : 'Salvar'}
                                    type="submit"
                                    iconPos="right"
                                    loading={isSubmiting}
                                />
                                :
                                <Skeleton width="82.16px" height="35px" />

                        }
                    </div>
                </form>
            </div>
            <div className="p-col-12">
                { 
                    isEditing ?
                        <TabelaTurmasCurso
                            turmasCurso={defaultValues.id}
                        />
                    :
                        <></>
                }
                
            </div>
        </div>
        
    </>
    );
}