import React, { useContext } from 'react';
import { TabelaAvaliacao } from '../../components/TabelaAvaliacao';
import { TabelaAvaliacaoInstrutor } from '../../components/TabelaAvaliacaoInstrutor';
import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';

import { AuthContext } from '../../contexts/AuthContext';

export default function AvaliacaoPage() {

  const { user } = useContext(AuthContext);

  return (
    <div className="p-pt-0">
      <MenuMain >
      {user.perfilUser[0] !== 'user-instrutor' ?
        <TabelaAvaliacao />
        :
        <TabelaAvaliacaoInstrutor />
      }
      </MenuMain>
      <AppFooter />
    </div>
  );
}