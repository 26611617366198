import React from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';

import { localeBr } from '../utils/prime_locales';

export const BrCalendar = ({ id, name, value, onChange, className, yearRange, readonly, placeholder, onSelect, disabled, onClearButtonClick, onInput }) => {

    addLocale(localeBr.name, localeBr.options);

    const monthNavigatorTemplate = (e) => {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />;
    }

    const yearNavigatorTemplate = (e) => {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="p-ml-2" style={{ lineHeight: 1 }} />;
    }

    return (
        <Calendar
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className={className}
        monthNavigator
        yearNavigator
        yearRange={yearRange}
        monthNavigatorTemplate={monthNavigatorTemplate}
        yearNavigatorTemplate={yearNavigatorTemplate}
        dateFormat="dd/mm/yy"
        locale="br"
        readOnlyInput={readonly}
        placeholder={placeholder}
        showButtonBar="true"
        mask="99/99/9999"
        onSelect={onSelect}
        onInput={onInput}
        disabled={disabled}
        onClearButtonClick={onClearButtonClick}
        // timeOnly
        // showTime
        // hourFormat="24"
    />
    );
}

