import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Logo } from '../../components/Logo';
import { ErrorValidationMessage } from '../../components/ErrorValidationMessage';
import { Toast } from 'primereact/toast';
// import '../../layout/sass/_login.scss';
import { useContext, useRef } from 'react';
import { AuthContext } from '../../contexts/AuthContext';

function ResetPassword() {

  const myToast = useRef();

  const showToast = (severityValue, summaryValue, detailValue) => {
    myToast.current.show({ severity: severityValue, summary: summaryValue, detail: detailValue });
  }


  const { loadingAuth, resetPassword } = useContext(AuthContext);


  const schema = Yup.object().shape({
    username: Yup
      .string()
      .email("O e-mail inserido não é inválido")
      .required("O e-mail é obrigatório")
  })

  const formik = useFormik({
    
    initialValues: {
      username: ''
    },

    validationSchema: schema,

    onSubmit: async (data) => {
                   
      if (data.username !== '') {
        
        const retorno = await resetPassword(data.username)             
        if (retorno.status === 204) {
          console.log('email enviado')
          showToast('success', 'Email', 'Email enviado com sucesso')                   
        } else {
          console.log('email falhado')
          showToast('error', 'Email', 'Email inexistente...')
        }       
      }
       
    }
  })

  return (
    <Dialog
      visible={true}
      maximized={true}
      closable={false}
      showHeader={false}
      onHide={() => null}
    >
      <Toast ref={myToast} />

      <div id="login-container" className="p-d-flex p-jc-center p-ai-center p-flex-column">
        <Logo /> 
        <form className="p-fluid p-grid p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-3 p-justify-center p-mx-0 p-px-0" onSubmit={formik.handleSubmit}>
          <div className="p-formgrid p-grid p-col-12 p-mt-5 p-mb-4 p-justify-center p-mx-0 p-px-0">
            <div className="p-field p-col-12">
              <label htmlFor="username">E-mail</label>
              <InputText
                id="username"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
              />
              {formik.errors.username && formik.touched.username &&
                <ErrorValidationMessage message={formik.errors.username} />
              }
            </div>            
          </div>
          <div className="p-d-flex p-jc-center p-ai-center p-p-2">
            <Button type="submit" id="login-btn" label={loadingAuth ? 'Enviando...' : 'Enviar'} />
          </div>
        </form>

        <div className="p-mb-3 p-mt-5">
          <span className="text">Já tem uma conta?</span> <Link to="/login">Entrar</Link>
        </div>

        {/* <div>
          <span className="text">Não possui conta?</span> <Link to="/Register">Criar conta</Link>
        </div> */}
      </div>
    </Dialog>
  );
}
export default ResetPassword