import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Printd } from 'printd';

import logo2 from '../assets/logo_conservatorio.jpg';
export const DialogTurmas = ({ visible, setVisible, turma }) => {
    const d = new Printd();
    const cssText = `
    #print-content {
        font-family: 'Open Sans', sans-serif;
        width: 100%;
        text-align: left;
    }
    
    img {
            margin-top: 25px;
            width: 100%;
            max-width: 250px;
            height: auto;
            display: block;
        }
        
        .header-img {
            display: flex;
            justify-content: center;
        }

        .header {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        .header h2 {
            margin-bottom: 0px;
        }

        .header h4 {
            margin-top: 7px;
        }
    `

    const listaTurmas = turma.map((item) => {
        return (
                <tr key={item.id} style={{ textTransform: 'uppercase'}}>
                    <td align='center'> <span>{item.id}</span></td>
                    <td> <span>{item.perfilNome}</span></td>
                    <td> <span>{item.cursoNome}</span></td>
                    <td> <span>{item.ano}</span></td>
                    <td> <span>{item.diaSemana}</span></td>
                    <td> <span>{item.hora}</span></td>
                    <td> <span>{item.qtdeVagas}</span></td>
                    <td> <span>{item.sala}</span></td>
                </tr>
        )
    })

    const handlePrintClick = () => {
        d.print(document.getElementById("print-content"), [cssText])
    }

    const header = (
        <div>
            <Button label="Imprimir" className="p-button-secondary" icon="pi pi-print" onClick={handlePrintClick} />
        </div>
    );

    return (
        <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            header={header}
            className='p-scrollpanel-bar-y'
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            style={{ width: '50vw', heigth: '5vw' }}
        >
            {/* {turma.length > 0 ? */}
                <div id="print-content">
                    <div className="p-d-flex p-jc-center header-img">
                    <img 
                            id="logo"
                            alt="Logo"
                            src={logo2}
                        />
                    </div>
                    <div className="p-d-flex p-ai-center p-flex-column header">
                        <h2>Turmas</h2>

                    </div>
                    <table width="100%" border="1" style={{'borderCollapse': 'collapse'}} cellpadding={3} cellspacing={0}>
                        <thead>
                            <tr>
                                <th><i>Código</i></th>
                                <th><i>Instrutor</i></th>
                                <th><i>Curso</i></th>
                                <th><i>Ano</i></th>
                                <th><i>Dia da Semana</i></th>
                                <th><i>Hora</i></th>
                                <th><i>Vagas</i></th>
                                <th><i>Sala</i></th>
                            </tr>
                        </thead>

                        <tbody>
                            {listaTurmas}
                        </tbody>
                        
                        
                    </table>

                </div>
                {/* :

                null
            } */}


        </Dialog>
    );
}