import React, { useRef, useEffect, useState, useCallback } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';
import { Dialog } from 'primereact/dialog';

export const TabelaFaltasMatricula = ({ faltas,visible,setVisible }) => {
    const toast = useRef(null);
    const confirmationToast = useRef(null)

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Faltas</h2>
            </div>
        </div>

    );

    const codBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const nomeBodyTemplate = (rowData) => {

        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Nome</span>
                    <span className="table-data">{rowData.matricula.aluno.nome}</span>
                </p>
            </React.Fragment>
        );
    }

    const observacaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Observação</span>
                    <span className="table-data">{rowData.obs}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataOcorrenciaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Data Ocorrencia</span>
                    <span className="table-data">{new Date(rowData.dataOcorrencia + ' 03:00:00.000').toLocaleDateString()}</span>
                </p>
            </React.Fragment>
        );
    }

    return (
        <>
            <Dialog
                visible={visible}
                onHide={() => setVisible(false)}
                className='p-scrollpanel-bar-y'
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ width: '60vw', heigth: '5vw' }}
            >
                <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

                    <Toast ref={toast} position="bottom-right" />

                    <Toast ref={confirmationToast} position="bottom-center" />

                    <div className="card p-col-12 datatable-responsive-demo">

                        <div className="p-fluid">

                            <DataTable style={{ width: '100%' }}
                                lazy="true"
                                header={header}
                                value={faltas}
                                filterDisplay="row"
                                className="p-datatable-responsive-demo"
                                emptyMessage="Nenhuma falta encontrada"
                                dataKey="id"
                                rows={50}
                            >

                                <Column
                                    field="id"
                                    header="Código"
                                    body={codBodyTemplate}

                                />

                                <Column
                                    field="matriculaAlunoNome"
                                    header="Nome"
                                    body={nomeBodyTemplate}

                                />


                                <Column
                                    field="obs"
                                    header="Observação"
                                    body={observacaoBodyTemplate}

                                />

                                <Column
                                    field="dataOcorrencia"
                                    header="Data Ocorrencia"
                                    body={dataOcorrenciaBodyTemplate}
                                />

                            </DataTable>

                        </div>
                    </div>
                </div>

            </Dialog>
        </>
    );
}