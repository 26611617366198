import React, { useCallback, useEffect, useState } from 'react';

import { FormMensagens } from '../../components/FormMensagens';
import { ErrorRetrievingData } from '../../components/ErrorRetrievingData';
import MenuMain from '../../MenuMain';

import { AppFooter } from '../../AppFooter';

import api from '../../api/api';

export default function EditarMensagensPage(props) {

    const [mensagens, setMensagens] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorGettingMensagens, setErrorGettingMensagens] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [idMensagens] = useState(props.match.params.id)


    const getMensagens = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await api.get(`mensagens/${idMensagens}`)
            
            setMensagens({
                'id': response.data.id,
                'emissorId': response.data.emissor.id,
                'receptorId': response.data.receptor.id,
                'mensagem': response.data.mensagem,
                'mensagemLida': response.data.mensagemLida,
                'mensagemRespondida': response.data.mensagemRespondida,
                'mensagemAnterior': response.data.mensagemAnterior,
            })

            setErrorGettingMensagens(false)
        }
        catch (error) {
            setErrorMessage(error.toString())
            setErrorGettingMensagens(true)
        }

        setIsLoading(false);
    }, [idMensagens]);

    useEffect(() => {

        async function mensagens() {
            await getMensagens();
        };
        mensagens();

    }, [getMensagens])

    return (
        <>
            <MenuMain >
                {
                    errorGettingMensagens ?
                        <ErrorRetrievingData
                            errorMessage={errorMessage}
                            retrieveFunction={() => getMensagens(idMensagens)}
                            isLoading={isLoading}
                        />
                        :
                        <FormMensagens
                            defaultValues={mensagens}
                            isEditing={true}
                            isLoading={isLoading}
                        />
                }
            </MenuMain>
            <AppFooter />
        </>
    );
}