import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Link, useHistory } from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Logo } from '../../components/Logo';
import { ErrorValidationMessage } from '../../components/ErrorValidationMessage';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';

//import by nixon
import { useContext, useRef } from 'react';
import { AuthContext } from '../../contexts/AuthContext';


function SignUp() {

  const myToast = useRef();

  const showToast = (severityValue, summaryValue, detailValue) => {
    myToast.current.show({ severity: severityValue, summary: summaryValue, detail: detailValue });
  }

  let history = useHistory();


  const { signUp, loadingAuth } = useContext(AuthContext);

  const schema = Yup.object().shape({
    name: Yup
      .string()
      .required("O nome é obrigatório"),
    username: Yup
      .string()
      .email("Um e-mail deve ser inserido")
      .required("O e-mail é obrigatório"),
    password: Yup
      .string()
      .required("A senha é obrigatória"),
    confirmPassword: Yup
      .string()
      .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
  })

  const formik = useFormik({


    initialValues: {
      client_id: 'Prototipo_front',
      name: '',
      username: '',
      password: '',
      grant_type: 'password'
    },

    validationSchema: schema,

    onSubmit: async (data, { setSubmitting }) => {
      if (data.username !== '' && data.password !== '' && data.name !== '') {
        const resposta = await signUp(data.username.trim(), data.password, data.name)

        if (resposta.status === 201) {
          showToast('success', 'Cadastro', 'Conta criada com sucesso')
          history.push("/");
        }
        else if (resposta.status === 409) {
          showToast('error', 'Cadastro', 'Email usado em outra conta')
        } else {
          showToast('error', 'Cadastro', 'Houve um erro de acesso, favor tentar mais tarde')
        }
        setSubmitting(false)
      }
    }
  })

  return (

    <Dialog
      visible={true}
      maximized={true}
      closable={false}
      showHeader={false}
      onHide={() => null}

    >

      <Toast ref={myToast} />

      <div id="login-container" className="p-d-flex p-jc-center p-ai-center p-flex-column">
        <Logo />
        <form className="p-fluid p-grid p-col-12 p-sm-8 p-md-6 p-lg-4 p-xl-3 p-justify-center p-mx-0 p-px-0 p-mt-2" onSubmit={formik.handleSubmit}>
          <div className="p-formgrid p-grid p-col-12 p-mt-5 p-mb-4 p-justify-center p-mx-0 p-px-0">
            <div className="p-field p-col-12">
              <label htmlFor="name">Nome</label>
              <InputText
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name && formik.touched.name &&
                <ErrorValidationMessage message={formik.errors.name} />
              }
            </div>
            <div className="p-field p-col-12">
              <label htmlFor="username">E-mail</label>
              <InputText
                id="username"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
              />
              {formik.errors.username && formik.touched.username &&
                <ErrorValidationMessage message={formik.errors.username} />
              }
            </div>
            <div className="p-field p-col-12">
              <label htmlFor="password">Senha</label>
              <Password
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                feedback={false}
                toggleMask
              />
              {formik.errors.password && formik.touched.password &&
                <ErrorValidationMessage message={formik.errors.password} />
              }
            </div>
            <div className="p-field p-col-12">
              <label htmlFor="password">Confirmar senha</label>
              <Password
                id="confirmPassword"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                feedback={false}
                toggleMask
              />
              {formik.errors.confirmPassword && formik.touched.confirmPassword &&
                <ErrorValidationMessage message={formik.errors.confirmPassword} />
              }
            </div>
          </div>
          <div className="p-d-flex p-jc-center p-ai-center p-p-2">
            <Button type="submit" id="login-btn" label={loadingAuth ? 'Carregando...' : 'Cadastrar'} />

          </div>
        </form>

        <div className="p-mt-5">
          <span className="text">Já têm uma conta?</span> <Link to="/login">Entrar</Link>
        </div>
      </div>
    </Dialog>
  );
}


export default SignUp;