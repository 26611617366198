import React, { useCallback, useEffect, useState } from 'react';

import { FormAvaliacao } from '../../components/FormAvaliacao';
import { ErrorRetrievingData } from '../../components/ErrorRetrievingData';
import MenuMain from '../../MenuMain';

import { AppFooter } from '../../AppFooter';

import api from '../../api/api';

export default function EditarAvaliacaoPage(props) {

    const [avaliacao, setAvaliacao] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorGettingAvaliacao, setErrorGettingAvaliacao] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [idAvaliacao] = useState(props.match.params.id);

    const getAvaliacao = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await api.get(`avaliacao/${idAvaliacao}`)
            
            setAvaliacao({
                'id': response.data.id,
                'data': response.data.data,
                'tipo': response.data.tipo,                
                'tipoAvaliacao': ['PROVA_1_BIMESTRE','PROVA_2_BIMESTRE','PROVA_3_BIMESTRE','PROVA_4_BIMESTRE','EXERCICIO','PROVA_PRATICA','OUTROS'],
                'notaTotal': response.data.notaTotal,
            })

            setErrorGettingAvaliacao(false)
        }
        catch (error) {
            setErrorMessage(error.toString())
            setErrorGettingAvaliacao(true)
        }

        setIsLoading(false);
    }, [idAvaliacao]);

    useEffect(() => {

        async function avaliacao() {
            await getAvaliacao();
        };
        avaliacao();

    }, [getAvaliacao])

    return (
        <>
            <MenuMain >
                {
                    errorGettingAvaliacao ?
                        <ErrorRetrievingData
                            errorMessage={errorMessage}
                            retrieveFunction={() => getAvaliacao(idAvaliacao)}
                            isLoading={isLoading}
                        />
                        :
                        <FormAvaliacao
                            defaultValues={avaliacao}
                            isEditing={true}
                            isLoading={isLoading}
                        />
                }
            </MenuMain>
            <AppFooter />
        </>
    );
}