import React, { useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';

export const DialogCsv = ({ visible, setVisible, inscricao }) => {
    const [selectedInscricoes, setSelectedInscricoes] = useState([]);
    const dt = useRef(null);

    const cols = [
        { field: 'id', header: 'Código' },
        { field: 'perfilNome', header: 'Nome' },
        { field: 'dataNascimento', header: 'Nascimento' },
        { field: 'perfilEmail', header: 'Email' },
        { field: 'perfilCelular', header: 'Celular' },
        { field: 'campanhaNome', header: 'Campanha' },
        { field: 'dataInscricao', header: 'Data' },        
    ];    

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    }

    const onSelectionChange = (e) => {
        setSelectedInscricoes(e.value);
    }

    const header = (
        <div className="flex align-items-center export-buttons">
            <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="mr-2" data-pr-tooltip="CSV" />
        </div>
    );

    return (
        <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            // header={header}
            className='p-scrollpanel-bar-y'
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            style={{ width: '50vw', heigth: '5vw' }}
        >
            <div className="card">
                <h5>Export</h5>

                <Tooltip target=".export-buttons>button" position="bottom" />

                <DataTable style={{ width: '100%' }} className="p-datatable-responsive-demo" ref={dt} value={inscricao} header={header} dataKey="id" responsiveLayout="scroll"
                    selectionMode="multiple" selection={selectedInscricoes} onSelectionChange={onSelectionChange}>
                    {
                        cols.map((col, index) => 
                        { return col.field === 'id' 
                        ? <Column style={{ width: '8%' }} key={index} field={col.field} header={col.header} /> 
                        : col.field === 'perfilNome'
                        ? <Column style={{ width: '8%' }} key={index} field={col.field} header={col.header} /> 
                        : col.field === 'dataNascimento'
                        ? <Column style={{ width: '15%' }} key={index} field={col.field} header={col.header} />
                        : col.field === 'perfilEmail'
                        ? <Column style={{ width: '35%' }} key={index} field={col.field} header={col.header} />
                        : col.field === 'perfilCelular'
                        ? <Column style={{ width: '15%' }} key={index} field={col.field} header={col.header} />
                        : col.field === 'campanhaNome'
                        ? <Column style={{ width: '12%' }} key={index} field={col.field} header={col.header} />
                        : col.field === 'dataInscricao'
                        ? <Column style={{ width: '15%' }} key={index} field={col.field} header={col.header} />
                        : null
                    })                  
                    }
                </DataTable>
                
            </div>
        </Dialog>
    );
}