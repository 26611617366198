import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Printd } from 'printd';

import logo2 from '../assets/logo_conservatorio.jpg';

export const DialogCertificadoPerfil = ({ visible, setVisible, perfil }) => {
    const d = new Printd();
    const cssText = `
    #print-content {
        font-family: 'Open Sans', sans-serif;
        width: 100%;
        text-align: left;
    }
    
    #logo {
            position: absolute;
            top: 0;
            right: 0;
            margin-top: 25px;
            width: 100%;
            max-width: 250px;
            height: auto;
            display: block;
        }

    .header-img {
        display: flex;
        justify-content: right;
    }

    .header {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .header h2 {
        border-bottom: 4px solid grey;
    }

    .header h1 {
        text-align: center;
    }

    .header h3 {
        text-align: center;
    }

    #container {
        width: 80%;
        margin: 0 auto;
        display: inline-block;
    }

    #barra {
        border-bottom: 4px solid grey;
        margin-bottom: 10px;
        width: 30%;
    }

    #barraLeft {
        width: 30%; 
        border-bottom: 4px solid grey;
        margin-bottom: 15px; 
        margin-left: 0; 
        float: left; 
    }

    #textoLeft {
        text-align: left;
    }

    #barraRight {
        width: 30%; 
        border-bottom: 4px solid grey; 
        margin-bottom: 15px; 
        margin-right: 0;
        float: right; 
    }

    #espaco1 {
        word-spacing: 30px;
    }

    #espaco2 {
        word-spacing: 80px;
    }

    #espaco3 {
        word-spacing: 50px;
    }

    #textoRight {
        margin-top: -40px;
        text-align: right;
    }

    #centralizado {
        text-align: center; 
    }
    `
    const listaPerfil = perfil.map((item) => {
        // console.log(item);
        return (
            item
        )
    })

    const handlePrintClick = () => {
        d.print(document.getElementById("print-content"), [cssText])
    }

    const header = (
        <div>
            <Button label="Imprimir" className="p-button-secondary" icon="pi pi-print" onClick={handlePrintClick} />
        </div>
    );

    return (
        <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            header={header}
            className='p-scrollpanel-bar-y'
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            style={{ width: '50vw', heigth: '5vw' }}
        >
            {/* {perfil.length > 0 ? */}
                <div id="print-content">
                    <br/>
                    <div className="p-d-flex p-ai-center p-flex-column header">
                        <img id="logo" alt="Logo" src={logo2} style={{position:'absolute',top:'0',right:'0',marginTop:'50px', width:'100%',maxWidth:'200px',height:'auto',display:'block'}}/>
                        <h1>Certificado</h1>

                    </div>
                    <div className="p-d-flex p-ai-center p-flex-column header">
                        <h3>Certificamos que</h3>

                    </div>
                    <div className="p-d-flex p-ai-center p-flex-column header">
                        <h2>{listaPerfil[0].nome}</h2>

                    </div>
                    <div id="centralizado" className="p-d-flex p-ai-center p-flex-column header">
                        <h3>concluiu o curso de {listaPerfil[0].turmaNome} no</h3>
                        <h3 style={{ marginTop: '-4px' }}>Conservatório Municipal "Galdina Corrêa da Costa Rodrigues".</h3>

                    </div>
                    <br/>
                    <div className="p-d-flex p-ai-center p-flex-column header">
                        <p id="barra" style={{borderBottom:'4px solid grey',marginBottom:'10px',width:'30%'}}></p>
                        <h3 style={{ marginTop: '-2px' }}>Assinatura do aluno</h3>
                    </div>
                    <div className="p-d-flex p-ai-center p-flex-column header">
                        <div id="container">
                            <p id="barraRight" style={{width:'30%',borderBottom:'4px solid grey',marginBottom:'15px',marginRight:'0',float:'right'}}></p>
                            <p id="barraLeft" style={{width:'30%',borderBottom:'4px solid grey',marginBottom:'15px',marginLeft:'0',float:'left'}}></p>
                            <br/>
                            <h3 id="textoLeft" style={{textAlign: 'left'}}>Diretor do Conservatório</h3>
                            <h3 id="textoRight" style={{textAlign: 'right'}}>Coordenador pedagógico</h3>
                            
                        </div>
                    </div>
                    <br/>
                    <div className="p-d-flex p-ai-center p-flex-column header">
                        <h3>Patos de Minas<span id="espaco1" style={{wordSpacing: '30px'}}>, </span> <span id="espaco2" style={{wordSpacing: '80px'}}> de  </span>  <span id="espaco3" style={{wordSpacing: '50px'}}> de  </span> .</h3>

                    </div>

                </div>
                {/* :

                null
            } */}


        </Dialog>
    );
}