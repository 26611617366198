import React, { useRef, useEffect, useState, useContext, useCallback } from 'react';

import { useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { DialogLicenca } from './DialogLicenca';
import '../layout/sass/_pickListSugestoes.scss'

import '../layout/sass/_responsiveTable.scss'

import api from '../api/api';

import {FilterContext} from '../contexts/FilterContext';
import {AuthContext} from '../contexts/AuthContext';
import { BrCalendar } from './BrCalendar';

export const TabelaLicenca = () => {
    const toast = useRef(null);
    const [licencasTotal, setLicencasTotal] = useState([]);
    const [licencas, setLicencas] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const confirmationToast = useRef(null)
    const [dt, setDt] = useState(null);
    const history = useHistory();
    const [showListaLicencas, setShowListaLicencas] = useState(false);        
    const { user } = useContext(AuthContext);
    const [sortable, setSortable] = useState('ASC');
    const [sortableCampo, setSortableCampo] = useState('id');
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [tipos, setTipos] = useState([]);
    const filterStatus = renderFilterStatus();
    const { filter, setFilter } = useContext(FilterContext);
    const [selectedDataInicio, setSelectedDataInicio] = useState(null);
    const [selectedDataFim, setSelectedDataFim] = useState(null);

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Licenças</h2>
            </div>
            <div className="p-my-0 p-grid p-dir-rev p-col">
                <div className="p-m-2">
                <Button
                    label="Adicionar"
                    className="p-mb-2 p-button-primary"
                    icon="pi pi-plus"
                    iconPos="left"
                    type="button"

                    onClick={() => history.push('/licencacreate')}

                />
                </div>
                <div className="p-m-2">
                    <Button
                        label="Limpar Filtros"
                        className="p-mb-2 p-button-primary"
                        iconPos="left"
                        type="button"
                        onClick={() => handleLimparClick()}
                    />
                </div>
            </div>
        </div>
        
    );

    const handleLimparClick = () => {
        setFilter({
            first: 0,
            rows: 20,
            sortField: null,
            sortOrder: null,
            filters: {
            },
            
        });
        setSelectedStatus(null);
        setSelectedDataInicio(null);
        setSelectedDataFim(null);
    }

    const getLicencas = useCallback(async (event) => {
        setIsLoading(true)
        setLicencas([])

        // console.log(event)   

        if(event !== undefined) {
            setTimeout(() => {
                const startIndex = event.first;

                setFirst(startIndex);
                // setLicencas(dataSource.slice(startIndex, endIndex));            
            }, 250);
        }
       
        try {

            if(filter.filters === undefined) 
            {

                if(event === undefined) {
                    const response = await api.get(`licenca?sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(licenca => {
                        setLicencas(licencas => [...licencas, licenca])
                    })                
                } else {
                    const response = await api.get(`licenca?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(licenca => {
                        setLicencas(licencas => [...licencas, licenca])
                    })  
                    
                }

                const respElements = await api.get(`licenca?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);

            } 
            else 
            {     
                const pesquisa = {
                    "id": filter.filters['id'] !== undefined ? filter.filters['id'].value : null,
                    "nome": filter.filters['matriculaAlunoNome'] !== undefined ? filter.filters['matriculaAlunoNome'].value : "",
                    "obs": filter.filters['obs'] !== undefined ? filter.filters['obs'].value : "",
                    "tipo": filter.filters['tipo'] !== undefined ? filter.filters['tipo'].value : null,
                    "dataInicio": filter.filters['dataInicio'] !== undefined ? filter.filters['dataInicio'].value : null,
                    "dataFim": filter.filters['dataFim'] !== undefined ? filter.filters['dataFim'].value : null
                }

                if (event === undefined) {   
                    const respElements = await api.post(`licenca/searchLicenca/${sortable}/${sortableCampo}?`, pesquisa);
                    respElements.data.content.forEach(licenca => {
                        setLicencas(licencas => [...licencas, licenca])
                    })
                    setTotalRecords(respElements.data.totalElements);
                } else {
                    const respElements = await api.post(`licenca/searchLicenca/${sortable}/${sortableCampo}?page=${event.page}`, pesquisa);
                    respElements.data.content.forEach(licenca => {
                        setLicencas(licencas => [...licencas, licenca])
                    })
                    setTotalRecords(respElements.data.totalElements);
                }
            }
            
        }
        catch (error) {  

            if(filter !== undefined){
                if(event === undefined) {
                    const response = await api.get(`licenca?sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(licenca => {
                        setLicencas(licencas => [...licencas, licenca])
                    })                  
                } else {
                    const response = await api.get(`licenca?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(licenca => {
                        setLicencas(licencas => [...licencas, licenca])
                    }) 
                    
                }

                const respElements = await api.get(`licenca?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);
            } else {
                toast.current.show({ severity: 'error', summary: 'Erro ao obter licença', detail: error.toString() });    
            }
              
        }

        setIsLoading(false)
    }, [filter,sortable,sortableCampo]);

    useEffect(() => {
        const fetchLicencas = async () => {
            try {
                await getLicencas();

            }
            catch (error){
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchLicencas();

    }, [getLicencas])

    function renderFilterStatus() {
        return (
            <Dropdown
                value={selectedStatus}
                options={tipos}
                onChange={(e) => onStatusSearchChange(e)}
                placeholder="Selecione um tipo"
                className="p-column-filter"
                showClear={true} />
        );
    }

    const onStatusSearchChange = (event) => {
        if (event.value !== null) {
            dt.filter(event.value, 'tipo', 'equals');
            setSelectedStatus(event.value);
        }
        else
            dt.filter(null, 'tipo', 'equals');
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2 edit-btn" onClick={() => handleEditClick(rowData)}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-mr-2 p-button-danger" loading={isDeleting} onClick={() => handleDeleteClick(rowData)}/>
                
            
            </div>
        );
    }

    const codBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const nomeBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Nome</span>
                    <span className="table-data">{rowData.matricula.aluno.nome}</span>
                </p>
            </React.Fragment>
        );
    }

    const observacaoBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Observação</span>
                    <span className="table-data">{rowData.obs}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataInicioBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Data Inicio</span>
                    <span className="table-data">{new Date(rowData.dataInicio).toLocaleDateString('pt-BR')}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataFimBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Data Fim</span>
                    <span className="table-data">{new Date(rowData.dataFim).toLocaleDateString('pt-BR')}</span>
                </p>
            </React.Fragment>
        );
    }

    const tipoBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Tipo</span>
                    <span className="table-data">{rowData.tipo}</span>
                </p>
            </React.Fragment>
        );
    }

    const onSort = (event) => {
        setSortableCampo(event.sortField === "matriculaAlunoNome" ? "matricula.aluno.nome" : event.sortField);
        if(sortable === 'ASC'){
            setSortable('DESC')
        } else if (sortable === 'DESC') {
            setSortable('ASC')
        }
    }

    const onFilter = (event) => {
        event['first'] = 0;
        // setLazyParams(event);
        setFilter(event);   
    }

  
    const handleEditClick = (data) => {

        history.push(`/licenca/edit/${data.id}`);
    }
    
    const handleDeleteClick = (data) => {

        data['userEmail'] = user.email

        confirmDialog({
            message: `Você tem certeza que quer remover a licença de ${data.matricula.aluno.nome}?`,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: async () => {
                setIsDeleting(true);

                try {

                    await api.delete(`licenca/${data.id}`);       
                    toast.current.show({ life: 5000, severity: 'success', summary: 'Remoção concluída!', detail: `Licença ${data.matricula.aluno.nome} removido(a) com sucesso` });
                    getLicencas()
                }
                catch (error) {
                    toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover o licença', detail: error.response.data });
                }

                setIsDeleting(false);
            },
            reject: () => { return }
        });
    }

    const handlePrintClick = () => {
        setShowListaLicencas(true);
    }

    const getLicencasAll = useCallback(async () => {
        try {
            const response = await api.get(`licenca/licencaAll`);
            setLicencasTotal(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter licenças', detail: error.toString() });
        }
    }, []);

    const getTipos = useCallback(async () => {
        const response = await api.get(`tipoLicenca`);
        setTipos(response.data.map(tipos => {
            var Tipos = { label: '', value: '' };
            Tipos.label = tipos
            Tipos.value = tipos

            return Tipos
        }))
        filter.filters !== undefined && (filter.filters.tipo !== undefined && filter.filters.tipo.value != null) ? setSelectedStatus(filter.filters.tipo.value) : setSelectedStatus(null)    
    }, [filter]);

    useEffect(() => {

        async function licenca() {
            await getLicencasAll();
            await getTipos();
        };
        licenca();
    }, [getLicencasAll,getTipos])

    const onDataInicioSearchChange = (event) => {
        if (event.value !== null) {
            let data = event.value.toISOString();
            dt.filter(data, 'dataInicio', 'equals');
            setSelectedDataInicio(event.value);
        }
        else
            dt.filter(null, 'dataInicio', 'equals');
    }

    const onDataInicioInputChange = (event) => {
        if (event.target.value !== null) {
            let data = new Date(event.target.value.split('/').reverse().join('/')).toISOString();
            dt.filter(data, 'dataInicio', 'equals');
            setSelectedDataInicio(new Date(event.target.value.split('/').reverse().join('/')));
        }
        else
            dt.filter(null, 'dataInicio', 'equals');
    }

    const onDataFimSearchChange = (event) => {
        if (event.value !== null) {
            let data = event.value.toISOString();
            dt.filter(data, 'dataFim', 'equals');
            setSelectedDataFim(event.value);
        }
        else
            dt.filter(null, 'dataFim', 'equals');
    }

    const onDataFimInputChange = (event) => {
        if (event.target.value !== null) {
            let data = new Date(event.target.value.split('/').reverse().join('/')).toISOString();
            dt.filter(data, 'dataFim', 'equals');
            setSelectedDataFim(new Date(event.target.value.split('/').reverse().join('/')));
        }
        else
            dt.filter(null, 'dataFim', 'equals');
    }


    return (
        <>
        
        <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

         <DialogLicenca
                visible={showListaLicencas}
                setVisible={setShowListaLicencas}
                licenca={licencasTotal}
            />
            <div className="p-my-0 p-dir-rev p-col">
                <div className="p-my-0">
                <Button 
                    textalign="center"
                    icon="pi pi-print"
                    className="p-button-secondary"
                    style={{ width: '35px' }}
                    onClick={handlePrintClick}
                    tooltip="Gerar relatório"
                    tooltipOptions={{ position: 'top' }}
    /> 
                </div>
            </div>
      
            <Toast ref={toast} position="bottom-right" />
            
            <Toast ref={confirmationToast} position="bottom-center" />
            
            <div className="card p-col-12 datatable-responsive-demo">
                
                <div className="p-fluid">
                    
                    <DataTable      
                        totalRecords={totalRecords}  
                        lazy="true"
                        first={first} 
                        onPage={getLicencas}       
                        ref={(element) => setDt(element)}
                        header={header}
                        value={licencas}
                        filterDisplay="row"
                        className="p-datatable-responsive-demo"
                        emptyMessage="Nenhum licença encontrado"
                        loading={isLoading}
                        dataKey="id"
                        paginator
                        rows={20}
                        currentPageReportTemplate={`Mostrando de {first} a {last} de ${totalRecords} licenças`}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                        // rowsPerPageOptions={[10, 20, 30]} 
                        onSort={onSort}                       
                        onFilter={onFilter}
                       filters={filter.filters}
                    >

                        <Column
                            field="id"
                            header="Código"
                            sortable={sortable}
                            body={codBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.id !== undefined && filter.filters.id.value != null) ? filter.filters.id.value : "Pesquisar pelo Código"}
                            
                        />
                        
                        <Column
                            field="matriculaAlunoNome"
                            header="Nome"
                            sortable={sortable}
                            body={nomeBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.matriculaAlunoNome !== undefined && filter.filters.matriculaAlunoNome.value !== "") ? filter.filters.matriculaAlunoNome.value : "Pesquisar pelo nome"}
                            
                        />


                        <Column
                            field="obs"
                            header="Observação"
                            sortable={sortable}
                            body={observacaoBodyTemplate}                            
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder={filter.filters !== undefined && (filter.filters.obs !== undefined && filter.filters.obs.value !== "") ? filter.filters.obs.value : "Pesquisar pela observação"}
                            
                        />

                        <Column
                            field="dataInicio"
                            header="Data Inicio"
                            sortable={sortable}
                            body={dataInicioBodyTemplate}                            
                            filter
                            filterElement={
                                <BrCalendar
                                    key={selectedDataInicio}
                                    id="data"
                                    name="data"
                                    value={selectedDataInicio}
                                    onSelect={(e) => onDataInicioSearchChange(e)}
                                    onInput={(e) => onDataInicioInputChange(e)}
                                    yearRange={`2000:${new Date().getFullYear()}`}
                                    placeholder="Selecione uma data"
                                    onClearButtonClick={() => handleLimparClick()}
                                />
                            }
                            
                        />

                        <Column
                            field="dataFim"
                            header="Data Fim"
                            sortable={sortable}
                            body={dataFimBodyTemplate}                            
                            filter
                            filterElement={
                                <BrCalendar
                                    key={selectedDataFim}
                                    id="data"
                                    name="data"
                                    value={selectedDataFim}
                                    onSelect={(e) => onDataFimSearchChange(e)}
                                    onInput={(e) => onDataFimInputChange(e)}
                                    yearRange={`2000:${new Date().getFullYear()}`}
                                    placeholder="Selecione uma data"
                                    onClearButtonClick={() => handleLimparClick()}
                                />
                            }
                            
                        />
                        
                        <Column
                            field="tipo"
                            header="Tipo"
                            sortable={sortable}
                            body={tipoBodyTemplate}                         
                            filter
                            filterMatchMode="custom"
                            filterElement={filterStatus}
                            
                        />

                        <Column
                            header="Ações"
                            body={actionBodyTemplate}
                            
                        />
    
                    </DataTable>
                    
                </div>
            </div>
        </div>
       
        </>
    );
}