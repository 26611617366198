import React from 'react';
import MenuMain from '../../MenuMain';
import { FormTurmas } from '../../components/FormTurmas';
import { AppFooter } from '../../AppFooter';


export default function CadastrarTurmasPage() {

  const defaultValues = {
    'ano': '',
    'diaSemana': '',
    'diaSemanas': ['DOMINGO', 'SEGUNDA', 'TERÇA', 'QUARTA', 'QUINTA', 'SEXTA', 'SÁBADO'],
    'hora': '',
    'qtdeVagas': '',
    'sala': '',
    'cursoId': '',
    'perfilId': '',
  }

  return (
    <div>
      <MenuMain>
         <FormTurmas
          defaultValues={defaultValues}
        />
      </MenuMain>
      <AppFooter />
    </div>
  );
}
