import React, { useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import { Checkbox } from 'primereact/checkbox';

export const TabelaAlunosFalta = ({ alunos, setAlunos }) => {
    const toast = useRef(null);
    const confirmationToast = useRef(null)

    const codigoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const matriculaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Nome do Aluno</span>
                    <span className="table-data">{rowData.matriculaNome}</span>
                </p>
            </React.Fragment>
        );
    }

    const presenteBodyTemplate = (rowData) => {
        const handleChange = () => {
            rowData.presente = !rowData.presente;
            onRowEditComplete1(alunos)
        }
        return (
            <React.Fragment>
                <Checkbox
                    checked={rowData.presente}
                    onChange={() => handleChange()}
                    style={{ marginRight: '5px' }}
                />
            </React.Fragment>
        );
    }

    useEffect(() => {

    }, [alunos]);

    const onRowEditComplete1 = (e) => {
        let alunos2 = [...alunos];
        let { newData, index } = e;

        alunos2[index] = newData;

        setAlunos(alunos2);
    }

    return (

        <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />

            <Toast ref={confirmationToast} position="bottom-center" />

            <div className="card p-col-12 datatable-responsive-demo">

                <div className="p-fluid">

                    <DataTable
                        editMode="row"
                        onRowEditComplete={onRowEditComplete1}
                        responsiveLayout="scroll"
                        value={alunos}
                        className="p-datatable-responsive-demo"
                        emptyMessage="Nenhuma nota encontrada"
                        dataKey="id"
                        paginator
                        rows={20}
                        currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} nota"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[20,30,40]}
                        sortField="nome"
                        sortOrder={1}
                        removableSort
                    >

                        <Column style={{ width: '12%' }}
                            field="matriculaId"
                            header="Matrícula"
                            sortable
                            body={codigoBodyTemplate}

                        />

                        <Column
                            field="matriculaNome"
                            header="Nome do Aluno"
                            sortable
                            body={matriculaBodyTemplate}

                        />

                        <Column
                            header="Presente"
                            sortable
                            body={presenteBodyTemplate}
                        />

                    </DataTable>
                </div>
            </div>
        </div>
    );
}
