import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DialogCampanhas } from './DialogCampanhas';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';

export const TabelaCampanhas = () => {
    const toast = useRef(null);
    const [campanhas, setCampanhas] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const confirmationToast = useRef(null)
    const history = useHistory();
    const [showListaCampanhas, setShowListaCampanhas] = useState(false);
    const [sortable, setSortable] = useState('asc');
    const [sortableCampo, setSortableCampo] = useState('id');
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 20,
        sortField: null,
        sortOrder: null,
        filters: {
            'nome': { value: '', matchMode: 'contains' },
        },

    });

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Campanhas</h2>
            </div>
            <div className="p-my-0 p-grid p-dir-rev p-col">
                <div className="">
                    <Button
                        label="Adicionar"
                        className="p-mb-2 p-button-primary"
                        icon="pi pi-plus"
                        iconPos="left"
                        type="button"

                        onClick={() => history.push('/campanhascreate')}

                    />
                </div>
            </div>
        </div>

    );

    const getCampanhas = useCallback(async (event) => {
        setIsLoading(true)
        setCampanhas([])

        // console.log(event)   

        if (event !== undefined) {
            setTimeout(() => {
                const startIndex = event.first;

                setFirst(startIndex);
            }, 250);
        }

        try {

            // console.log(lazyParams.filters);
            if (lazyParams.filters === undefined) {

                if (event === undefined) {
                    const response = await api.get(`campanha?sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(campanha => {
                        setCampanhas(campanhas => [...campanhas, campanha])
                    })
                } else {
                    const response = await api.get(`campanha?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(campanha => {
                        setCampanhas(campanhas => [...campanhas, campanha])
                    })

                }

                const respElements = await api.get(`campanha?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);

            } else if (lazyParams.filters.nome.value !== '' || lazyParams.filters.nome !== undefined) {

                if (event === undefined) {
                    const response = await api.get(`campanha/search?searchTerm=${lazyParams.filters.nome.value}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(campanha => {
                        setCampanhas(campanhas => [...campanhas, campanha])
                    })
                } else {
                    const response = await api.get(`campanha/search?searchTerm=${lazyParams.filters.nome.value}&page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(campanha => {
                        setCampanhas(campanhas => [...campanhas, campanha])
                    })
                }

                const respElements = await api.get(`campanha/search?searchTerm=${lazyParams.filters.nome.value}&sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);

            }

        }
        catch (error) {

            if (lazyParams !== undefined) {
                if (event === undefined) {
                    const response = await api.get(`campanha?sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(campanha => {
                        setCampanhas(campanhas => [...campanhas, campanha])
                    })
                } else {
                    const response = await api.get(`campanha?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(campanha => {
                        setCampanhas(campanhas => [...campanhas, campanha])
                    })

                }

                const respElements = await api.get(`campanha?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);
            } else {
                toast.current.show({ severity: 'error', summary: 'Erro ao obter campanhas', detail: error.toString() });
            }

        }

        setIsLoading(false)
    }, [lazyParams, sortable, sortableCampo]);

    useEffect(() => {
        const fetchCampanhas = async () => {
            try {
                await getCampanhas();

            }
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchCampanhas();

    }, [getCampanhas])

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2 edit-btn" onClick={() => handleEditClick(rowData)} />

            </div>
        );
    }

    const codBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const nomeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Nome</span>
                    <span className="table-data">{rowData.nome}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataInicioBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Data Inicio</span>
                    <span className="table-data">{new Date(rowData.dataInicio).toLocaleDateString('pt-BR')}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataFimBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Data Fim</span>
                    <span className="table-data">{new Date(rowData.dataFim).toLocaleDateString('pt-BR')}</span>
                </p>
            </React.Fragment>
        );
    }

    const handleEditAtivo = async (data) => {

        const response = await api.get(`/campanha/${data.id}`);

        const tempData = {
            'id': response.data.id,
            'nome': response.data.nome,
            'dataInicio': response.data.dataInicio,
            'dataFim': response.data.dataFim,
            'ativo': !response.data.ativo
        }

        await api.put("campanha", tempData);

        setCampanhas(campanhas.map(item => item.id === data.id ? { ...item, "ativo": tempData.ativo } : item))

    }

    const ativoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Status</span>
                <Button onClick={() => handleEditAtivo(rowData)} className="p-button-rounded" style={{ color: 'white', backgroundColor: rowData.ativo === true ? '#228B22' : '#FFA500' }} label={rowData.ativo === true ? 'Ativo' : 'Não Ativo '} />
            </React.Fragment>
        );
    }

    const onSort = (event) => {
        setSortableCampo(event.sortField);
        setLazyParams(event);
        if (sortable === 'asc') {
            setSortable('desc')
        } else if (sortable === 'desc') {
            setSortable('asc')
        }
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    const handleEditClick = (data) => {

        history.push(`/campanhas/edit/${data.id}`);

    }

    const handlePrintClick = () => {
        setShowListaCampanhas(true);
    }

    return (
        <>

            <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

                <DialogCampanhas
                    visible={showListaCampanhas}
                    setVisible={setShowListaCampanhas}
                    campanha={campanhas}
                />
                <div className="p-my-0 p-dir-rev p-col">
                    <div className="p-my-0">
                        <Button
                            textalign="center"
                            icon="pi pi-print"
                            className="p-button-secondary"
                            style={{ width: '35px' }}
                            onClick={handlePrintClick}
                            tooltip="Gerar relatório"
                            tooltipOptions={{ position: 'top' }}
                        />
                    </div>
                </div>

                <Toast ref={toast} position="bottom-right" />

                <Toast ref={confirmationToast} position="bottom-center" />

                <div className="card p-col-12 datatable-responsive-demo">

                    <div className="p-fluid">

                        <DataTable
                            totalRecords={totalRecords}
                            lazy="true"
                            first={first}
                            onPage={getCampanhas}
                            header={header}
                            value={campanhas}
                            filterDisplay="row"
                            className="p-datatable-responsive-demo"
                            emptyMessage="Nenhuma campanha encontrada"
                            loading={isLoading}
                            dataKey="id"
                            paginator
                            rows={20}
                            currentPageReportTemplate={`Mostrando de {first} a {last} de ${totalRecords} campanhas`}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                            // rowsPerPageOptions={[10, 20, 30]} 
                            onSort={onSort}
                            onFilter={onFilter}
                            filters={lazyParams.filters}
                        >

                            <Column
                                field="id"
                                header="Código"
                                sortable={sortable}
                                body={codBodyTemplate}

                            />

                            <Column
                                field="nome"
                                header="Nome"
                                sortable={sortable}
                                body={nomeBodyTemplate}
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Pesquisar pelo nome"

                            />


                            <Column
                                field="dataInicio"
                                header="Data Inicio"
                                sortable={sortable}
                                body={dataInicioBodyTemplate}

                            />


                            <Column
                                field="dataFim"
                                header="Data Fim"
                                sortable={sortable}
                                body={dataFimBodyTemplate}

                            />

                            <Column
                                field="ativo"
                                header="Status"
                                sortable={sortable}
                                body={ativoBodyTemplate}

                            />

                            <Column
                                header="Ações"
                                body={actionBodyTemplate}

                            />

                        </DataTable>

                    </div>
                </div>
            </div>

        </>
    );
}