import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(async config => {

    const user  = JSON.parse(localStorage.getItem("user"));

    if (user) {
        api.defaults.headers.authorization = `Bearer ${user.tokenInfo.access_token}`;
        config.headers['email'] = user.email;
    }

    return config;
});

export default api;