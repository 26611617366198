import React from 'react';
import MenuMain from '../../MenuMain';
import { FormAvaliacao } from '../../components/FormAvaliacao';
import { AppFooter } from '../../AppFooter';


export default function CadastrarAvaliacaoPage() {

  const defaultValues = {
    'avaliacaoId': 0,
    'perfilId': '',
    'data': new Date(),
    'tipo': '',
    'tipoAvaliacao': ['PROVA_1_BIMESTRE','PROVA_2_BIMESTRE','PROVA_3_BIMESTRE','PROVA_4_BIMESTRE','EXERCICIO','PROVA_PRATICA','OUTROS'],
    'notaTotal': '',
    'nota': []
  }

  return (
    <div>
      <MenuMain>
         <FormAvaliacao
          defaultValues={defaultValues}
        />
      </MenuMain>
      <AppFooter />
    </div>
  );
}
