import React, { useRef, useEffect, useState, useCallback } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';

export const TabelaTurmasCurso = ({ turmasCurso }) => {
    const toast = useRef(null);
    const [turmasTotal, setTurmasTotal] = useState([]);
    const confirmationToast = useRef(null)

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Turmas</h2>
            </div>
        </div>
        
    );

    const codBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const instrutorBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Instrutor</span>
                    <span className="table-data">{rowData.perfilNome}</span>
                </p>
            </React.Fragment>
        );
    }

    const anoBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Ano</span>
                    <span className="table-data">{rowData.ano}</span>
                </p>
            </React.Fragment>
        );
    }

    const semanaBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Dia da Semana</span>
                    <span className="table-data">{rowData.diaSemana}</span>
                </p>
            </React.Fragment>
        );
    }

    const horaBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Hora</span>
                    <span className="table-data">{rowData.hora}</span>
                </p>
            </React.Fragment>
        );
    }

    const vagasBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Quantidade de Vagas</span>
                    <span className="table-data">{rowData.qtdeVagas}</span>
                </p>
            </React.Fragment>
        );
    }

    const salaBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Sala</span>
                    <span className="table-data">{rowData.sala}</span>
                </p>
            </React.Fragment>
        );
    }

    const cursoBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Curso</span>
                    <span className="table-data">{rowData.cursoNome}</span>
                </p>
            </React.Fragment>
        );
    }

    const getTurmasAll = useCallback(async () => {
        try {
            if (turmasCurso !== undefined)
            {
                const response = await api.get(`turma/curso/${turmasCurso}`);
                setTurmasTotal(response.data)
            }
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter turmas', detail: error.toString() });
        }
    }, [turmasCurso]);

    useEffect(() => {

        async function turmas() {
            await getTurmasAll();
        };
        turmas();
    }, [getTurmasAll])

    return (
        <>
        <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

            <Toast ref={toast} position="bottom-right" />
            
            <Toast ref={confirmationToast} position="bottom-center" />
            
            <div className="card p-col-12 datatable-responsive-demo">
                
                <div className="p-fluid">
                    
                    <DataTable      
                        lazy="true"    
                        header={header}
                        value={turmasTotal}
                        filterDisplay="row"
                        className="p-datatable-responsive-demo"
                        emptyMessage="Nenhuma turma encontrada"
                        dataKey="id"
                        rows={20}
                    >

                        <Column
                            field="id"
                            header="Código"
                            body={codBodyTemplate}                           
                            
                        />
                        
                        <Column
                            field="perfil.nome"
                            header="Instrutor"
                            body={instrutorBodyTemplate}                   
                            
                        />

                        <Column
                            field="ano"
                            header="Ano"
                            body={anoBodyTemplate}                     
                            
                        />

                        <Column
                            field="diaSemana"
                            header="Dia da Semana"
                            body={semanaBodyTemplate}                   
                            
                        />
                        
                        <Column
                            field="hora"
                            header="Hora"
                            body={horaBodyTemplate}                        
                            
                        />

                        <Column
                            field="qtdeVagas"
                            header="Quantiade de Vagas"
                            body={vagasBodyTemplate}                         
                            
                        />

                        <Column
                            field="sala"
                            header="Sala"
                            body={salaBodyTemplate}                            
                            
                        />

                        <Column
                            field="cursoId"
                            header="Curso"
                            body={cursoBodyTemplate}            
                        />
    
                    </DataTable>
                    
                </div>
            </div>
        </div>
       
        </>
    );
}