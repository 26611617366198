import React, { useState, useContext, useRef } from 'react';

import { AuthContext } from './contexts/AuthContext';

import { PermissaoUsuario } from './components/PermissaoUsuario';
import { Dialog } from 'primereact/dialog';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { ErrorValidationMessage } from './components/ErrorValidationMessage';
import { Button } from 'primereact/button';

import './layout/layout.scss';

import { useFormik } from 'formik';
import * as Yup from 'yup';

export const AppProfile = () => {

    const { user, signOut } = useContext(AuthContext);

    // const [expanded, setExpanded] = useState(false);
    const toast = useRef(null);
    const [showDialogPermissao, setShowDialogPermissao] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isShowReset, setIsShowReset] = useState(false);
    const [userToUpdate, setUserToUpdate] = useState();

    const { updatePasswordUser } = useContext(AuthContext);

    const onClick = () => {
        if (user.perfilUser.includes('admin') && user.perfilUser.includes('user')) {
           setShowDialogPermissao(true);
        }
    }

    const schema = Yup.object().shape({
        senha: Yup
        .string()
        .required("A senha é obrigatória")
    });
    const formik = useFormik({
        initialValues: {
            senha: ''
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: (data) => {
            handleSubmit(data);
        }
    })
    const handleSubmit = async(data) => {
        setIsUpdating(true)
        const success = updatePasswordUser(userToUpdate.email, data.senha)
       success.then(function(result) {
           setIsUpdating(false)
           formik.resetForm()
            if(!result) {
                toast.current.show({ severity: 'error', summary: 'Erro ao atualizar a senha', detail: 'Erro desconhecido' });
            } else {
                setIsShowReset(false)
                toast.current.show({ life: 2000, severity: 'success', summary: 'Perfil atualizado!', detail: `Senha do usuário ${userToUpdate.nome.toUpperCase()} atualizada com sucesso` });
                setTimeout(() => {
                    signOut()
                }, 2000)
            }
       })
    }
    const handleResetClick = (data) => {
        setUserToUpdate({
            email: data.email,
            nome: data.nome,
        });
        setIsShowReset(true);
    }
    const isFormFieldValid = (senha) => !!(formik.touched[senha] && formik.errors[senha]);

    return (
        <div className="layout-profile">
            <PermissaoUsuario
                visible={showDialogPermissao}
                setVisible={setShowDialogPermissao}
                permissao="Administrador"
            />
            <Toast ref={toast} position="bottom-right" />
            <Dialog className='p-scrollpanel-bar-y' header="Alterar Senha" onHide={() => setIsShowReset(false)} visible={isShowReset} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '20vw', heigth: '5vw' }} >
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="p-formgrid p-grid p-dir-row">
                        <div className="p-field p-col">
                            <label htmlFor="senha">Nova Senha</label>
                            <br />
                            <Password
                                id="senha"
                                name="senha"
                                className={classNames({ 'p-invalid': isFormFieldValid('senha') })}
                                value={formik.values.senha}
                                onChange={formik.handleChange}
                                feedback={false}
                                toggleMask
                            />
                            {formik.errors.senha && formik.touched.senha &&
                                <ErrorValidationMessage message={formik.errors.senha} />
                            }
                            <br />
                            <Button
                                icon="pi pi-pencil"
                                className='form-buttons'
                                type="submit"
                                loading={isUpdating}
                                label='Alterar'
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
            <div>
                <button className="p-link layout-profile-link" onClick={onClick}>
                    <span className="username">{user.nome.toUpperCase()}</span>
                    {/* <i className="pi pi-fw pi-cog" /> */}
                </button>
            </div>
            {/* <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={expanded} unmountOnExit>
                <div className="p-my-2 p-px-2" style={{ textAlign: 'left' }}>
                    <label className="p-ml-1 label-perfil-user">Perfil:</label>
                    <div className="p-d-flex p-jc-center p-px-1 p-mt-1">
                        <Dropdown value={selectedPerfil} options={perfis} optionLabel="label" onChange={handlePermissionSelection} className="perfil-user-dropdown" />
                    </div>
                </div>
            </CSSTransition> */}
            <button type="button" onClick={() => handleResetClick(user)} className="p-link p-mt-3">
                <i className="pi pi-fw pi-key p-mr-2" /><span>Alterar Senha</span>
            </button>
                    <br />
                    <button type="button" onClick={signOut} className="p-link p-mt-3">
                        <i className="pi pi-fw pi-sign-out p-mr-2" /><span>Sair</span>
                    </button>
        </div>
    );

}
