import React, { useCallback, useEffect, useState } from 'react';

import { FormMatriculas } from '../../components/FormMatriculas';
import { ErrorRetrievingData } from '../../components/ErrorRetrievingData';
import MenuMain from '../../MenuMain';

import { AppFooter } from '../../AppFooter';

import api from '../../api/api';

export default function EditarMatriculasPage(props) {

    const [matriculas, setMatriculas] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorGettingMatriculas, setErrorGettingMatriculas] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [idMatriculas] = useState(props.match.params.id);

    const getMatriculas = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await api.get(`matricula/${idMatriculas}`)
            
            setMatriculas({
                'id': response.data.id,
                'dataMatricula': response.data.dataMatricula,
                'conclusao': response.data.conclusao,
                'notaFinal': response.data.notaFinal,
                'status': response.data.status,
                'statusLista': ['MATRICULADO', 'REMATRICULADO', 'TRANCADO', 'DESISTENTE'],
                'alunoId': response.data.alunoId,
                'instrumentoId': response.data.instrumentoId,
                'turmaId': response.data.turmaId,
                'periodoId': response.data.periodoId,
            })

            setErrorGettingMatriculas(false)
        }
        catch (error) {
            setErrorMessage(error.toString())
            setErrorGettingMatriculas(true)
        }

        setIsLoading(false);
    }, [idMatriculas])

    useEffect(() => {

        async function matriculas() {
            await getMatriculas();
        };
        matriculas();

    }, [getMatriculas])

    return (
        <>
            <MenuMain >
                {
                    errorGettingMatriculas ?
                        <ErrorRetrievingData
                            errorMessage={errorMessage}
                            retrieveFunction={() => getMatriculas(idMatriculas)}
                            isLoading={isLoading}
                        />
                        :
                        <FormMatriculas
                            defaultValues={matriculas}
                            isEditing={true}
                            isLoading={isLoading}
                        />
                }
            </MenuMain>
            <AppFooter />
        </>
    );
}