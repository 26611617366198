import React from 'react';

import { TabelaCursos } from '../../components/TabelaCursos';
import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';

export default function CursosPage() {

  return (
    <>
      <MenuMain >
        <TabelaCursos />
      </MenuMain>
      <AppFooter />
    </>
  );
}
