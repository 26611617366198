import { Switch } from 'react-router-dom';
import Route from './Route';
import ScrollToTop from '../ScrollToTop';

import SignIn from '../pages/Login';
import SignUp from '../pages/SignUp';
import ResetPassword from '../pages/ResetPassword';
import HomeAdminPage from '../pages/home-admin';
import HomeUserPage from '../pages/home-user';

import AcessoAlunos from '../pages/AcessoAlunos';

import AvaliacaoPage from '../pages/avaliacao';
import CadastrarAvaliacaoPage from '../pages/avaliacao/create';
import EditarAvaliacaoPage from '../pages/avaliacao/edit';

import FaltaPage from '../pages/falta';
import CadastrarFaltaPage from '../pages/falta/create';
import EditarFaltaPage from '../pages/falta/edit';

import LicencaPage from '../pages/licenca';
import CadastrarLicencaPage from '../pages/licenca/create';
import EditarLicencaPage from '../pages/licenca/edit';

import CampanhasPage from '../pages/campanhas';
import CadastrarCampanhasPage from '../pages/campanhas/create';
import EditarCampanhasPage from '../pages/campanhas/edit';

import PeriodoMatriculaPage from '../pages/periodoMatricula';
import EditarPeriodoMatriculaPage from '../pages/periodoMatricula/edit';

import InscricoesPage from '../pages/inscricao/index';
import CursosPage from '../pages/cursos/index';
import CadastrarCursosPage from '../pages/cursos/create';
import EditarCursosPage from '../pages/cursos/edit';
import TurmasPage from '../pages/turmas/index';
import CadastrarTurmasPage from '../pages/turmas/create';
import EditarTurmasPage from '../pages/turmas/edit'
import PerfisPage from '../pages/perfis';
import CadastrarPerfisPage from '../pages/perfis/create';
import EditarPerfisPage from '../pages/perfis/edit';

import LogsPage from '../pages/logs/index';
import EditarLogsPage from '../pages/logs/edit';

import MatriculasPage from '../pages/matriculas';
import CadastrarMatriculasPage from '../pages/matriculas/create';
import EditarMatriculasPage from '../pages/matriculas/edit';
import LancarNotasPage from '../pages/matriculas/notas';

import MensagensPage from '../pages/mensagens';
import CadastrarMensagensPage from '../pages/mensagens/create';
import EditarMensagensPage from '../pages/mensagens/edit';
import HistoricoPerfil from '../pages/historicoAlunos/index';
import FilaInstrumentos from '../pages/filaInstrumentos/index';

export default function Routes() {
  return (
    <ScrollToTop>
      <Switch>
        <Route exact path="/" component={HomeUserPage} />
        <Route exact path="/register" component={SignUp} />
        <Route exact path="/login" component={SignIn} />
        <Route exact path="/reset" component={ResetPassword} />

        <Route exact path="/acessoalunos" component={AcessoAlunos} />

        <Route exact path="/admin" component={HomeAdminPage} isPrivate />

        <Route exact path="/avaliacao" component={AvaliacaoPage} isPrivate />
        <Route exact path="/avaliacaocreate" component={CadastrarAvaliacaoPage} isPrivate />
        <Route exact path="/avaliacao/edit/:id" component={EditarAvaliacaoPage} isPrivate />

        <Route exact path="/falta" component={FaltaPage} isPrivate />
        <Route exact path="/faltacreate" component={CadastrarFaltaPage} isPrivate />
        <Route exact path="/falta/edit/:id" component={EditarFaltaPage} isPrivate />

        <Route exact path="/licenca" component={LicencaPage} isPrivate />
        <Route exact path="/licencacreate" component={CadastrarLicencaPage} isPrivate />
        <Route exact path="/licenca/edit/:id" component={EditarLicencaPage} isPrivate />

        <Route exact path="/campanhas" component={CampanhasPage} isPrivate />
        <Route exact path="/campanhascreate" component={CadastrarCampanhasPage} isPrivate />
        <Route exact path="/campanhas/edit/:id" component={EditarCampanhasPage} isPrivate />

        <Route exact path="/periodoMatricula" component={PeriodoMatriculaPage} isPrivate />
        <Route exact path="/periodoMatricula/edit/:id" component={EditarPeriodoMatriculaPage} isPrivate />

        <Route exact path="/matriculas" component={MatriculasPage} isPrivate />
        <Route exact path="/matriculascreate" component={CadastrarMatriculasPage} isPrivate />
        <Route exact path="/matriculas/edit/:id" component={EditarMatriculasPage} isPrivate />
        <Route exact path="/matriculas/notas/:id" component={LancarNotasPage} isPrivate />

        <Route exact path="/perfis" component={PerfisPage} isPrivate />
        <Route exact path="/perfiscreate" component={CadastrarPerfisPage} isPrivate />
        <Route exact path="/perfis/edit/:id" component={EditarPerfisPage} isPrivate />

        <Route exact path="/inscricoes" component={InscricoesPage} isPrivate />

        <Route exact path="/cursos" component={CursosPage} isPrivate />
        <Route exact path="/cursoscreate" component={CadastrarCursosPage} isPrivate />
        <Route exact path="/cursos/edit/:id" component={EditarCursosPage} isPrivate />

        <Route exact path="/turmas" component={TurmasPage} isPrivate />
        <Route exact path="/turmascreate" component={CadastrarTurmasPage} isPrivate />
        <Route exact path="/turmas/edit/:id" component={EditarTurmasPage} isPrivate />

        <Route exact path="/logs" component={LogsPage} isPrivate />
        <Route exact path="/logs/edit/:id" component={EditarLogsPage} isPrivate /> 

        <Route exact path="/mensagens" component={MensagensPage} isPrivate />
        <Route exact path="/mensagenscreate" component={CadastrarMensagensPage} isPrivate />
        <Route exact path="/mensagens/edit/:id" component={EditarMensagensPage} isPrivate />
        <Route exact path="/historicoPerfil" component={HistoricoPerfil} isPrivate />
        <Route exact path="/filaInstrumentos" component={FilaInstrumentos} isPrivate />

      </Switch>
    </ScrollToTop>
  )
}