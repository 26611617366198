import React, { useCallback, useRef, useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { BrCalendar } from './BrCalendar';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorValidationMessage } from './ErrorValidationMessage';
import api from '../api/api';
import '../layout/sass/_form.scss';

export const FormCampanhas = ({ isEditing, isLoading, defaultValues }) => {
    
    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [periodoMatriculaOuRematricula, setPeriodoMatriculaOuRematricula] = useState(null);

    const schema = Yup.object().shape({
        nome: Yup
            .string()
            .required("O nome é obrigatório")
            .max(200, "O nome dete ter no máximo 200 caracteres"),        
        dataInicio: Yup
            .date()
            // .max(new Date(), "Data inicio inválida")
            .typeError("Uma data deve ser inserida")
            .required("A data de inicio é obrigatória"),
        dataFim: Yup
            .date()
            // .max(new Date(), "Data fim inválida")
            .typeError("Uma data deve ser inserida")
            .required("A data de fim é obrigatória"),     
    })

    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)                        
           
            try {
                let campanha = await api.get(`campanha/ativo`)
                if (!isEditing) {
                    if(data.ativo === true && campanha.data.length !== 0)
                    {
                        toast.current.show({ life: 3000, severity: 'error', summary: 'Erro!', detail: `Uma campanha já está ativa` });
                    }
                    else
                    {
                        await api.post("campanha", data)
                        formik.resetForm()
                        toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `${data.nome} adicionado(a) com sucesso` });
                        setTimeout(() => {
                            history.push('/campanhas')

                        }, 2000)
                    }

                }
                else {
                    if (data.ativo && campanha.data.length !== 0)
                    {
                        if (campanha.data[0].id === data.id)
                        {
                            await api.put("campanha", data)
                            
                            toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `${data.nome} atualizado(a) com sucesso` });
                            setTimeout(() => {
                                
                                history.push('/campanhas')
        
                            }, 2000)
                        }
                        else
                        {
                            toast.current.show({ life: 5000, severity: 'error', summary: 'Cadastro falhou!', detail: `Uma campanha já está ativa!` });
                        }
                    }
                    else
                    {
                        await api.put("campanha", data)
                            
                        toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `${data.nome} atualizado(a) com sucesso` });
                        setTimeout(() => {
                            
                            history.push('/campanhas')
    
                        }, 2000)
                    }
                }
            }
            catch (error) {
                if (!isEditing)
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao cadastrar campanha!', detail: error.toString() });
                else
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar campanha!', detail: error.toString() });

            }

            setIsSubmiting(false)
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getPeriodo = useCallback(async () => {
        try {
            const response = await api.get(`periodoMatricula/periodo`);
            setPeriodoMatriculaOuRematricula(response.data.periodoMatriculaOuRematricula);
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter periodo da matricula', detail: error.toString() });
        }
    }, []);

    useEffect(() => {
        async function campanhas() {
            await getPeriodo();
        };
        campanhas();

    }, [getPeriodo])

    return (
                
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <div className="p-col-12">
                <h2 className="p-mt-0">Campanhas</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-fluid p-mx-0">
                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="nome"
                                className={classNames({ 'p-error': isFormFieldValid('nome') })}
                            >
                                Nome
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="nome"
                                        name="nome"
                                        className={classNames({ 'p-invalid': isFormFieldValid('nome') })}
                                        value={formik.values.nome}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.nome && formik.touched.nome &&
                                <ErrorValidationMessage message={formik.errors.nome} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4 p-md-4">
                                <label
                                    htmlFor="dataInicio"
                                    className={classNames({ 'p-error': isFormFieldValid('dataInicio') })}
                                >
                                    Data Inicio
                                </label>
                                <BrCalendar
                                    id="dataInicio"
                                    name="dataInicio"
                                    value={formik.values.dataInicio}
                                    onChange={formik.handleChange}
                                    placeholder={ isEditing ? new Date(formik.values.dataInicio).toLocaleDateString('pt-BR') : "" }
                                    className={classNames({ 'p-invalid': isFormFieldValid('dataInicio') })}
                                    yearRange={`2000:${new Date().getFullYear()}`}
                                />
                                {formik.errors.dataInicio && formik.touched.dataInicio &&
                                    <ErrorValidationMessage message={formik.errors.dataInicio} />
                                }
                        </div>

                        <div className="p-field p-col-12 p-sm-4 p-md-4">
                                <label
                                    htmlFor="dataFim"
                                    className={classNames({ 'p-error': isFormFieldValid('dataFim') })}
                                >
                                    Data Fim
                                </label>
                                <BrCalendar
                                    id="dataFim"
                                    name="dataFim"
                                    value={formik.values.dataFim}
                                    onChange={formik.handleChange}
                                    placeholder={ isEditing ? new Date(formik.values.dataFim).toLocaleDateString('pt-BR') : ""}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dataFim') })}
                                    yearRange={`2000:${new Date().getFullYear()}`}
                                />
                                {formik.errors.dataFim && formik.touched.dataFim &&
                                    <ErrorValidationMessage message={formik.errors.dataFim} />
                                }
                        </div>

                        <div className="p-field p-col-12">
                            <label
                                htmlFor="ativo"
                                className={classNames({ 'p-error': isFormFieldValid('ativo') })}
                                style={{ textAlign: 'justify', textJustify: 'distribute' }}
                            >
                                <Checkbox
                                    id="ativo"
                                    name="ativo"
                                    checked={formik.values.ativo}
                                    onChange={() => formik.setFieldValue('ativo', !formik.values.ativo)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('ativo') })}
                                    style={{ marginRight: '5px' }}
                                    tooltip={!formik.values.ativo && periodoMatriculaOuRematricula ? "Não é permitido ativar a campanha se o período de matriculas/rematriculas está ativo!" : ""}
                                    disabled={periodoMatriculaOuRematricula}
                                />
                                Ativar
                            </label>
                            {formik.errors.ativo && formik.touched.ativo &&
                                <ErrorValidationMessage message={formik.errors.ativo} />
                            }
                    </div>

                    </div>
                    <div className="p-d-flex p-jc-end p-mr-1">
                        {
                            !isLoading ?
                                <Button
                                    label={isEditing ? 'Atualizar' : 'Salvar'}
                                    type="submit"
                                    iconPos="right"
                                    loading={isSubmiting}
                                />
                                :
                                <Skeleton width="82.16px" height="35px" />

                        }
                    </div>
                </form>
            </div>
        </div>
    
    );
}